import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Cloud,
  CloudCamera,
  Container,
  GPSTracking,
  Typography,
  LiveView,
  Link,
} from "@thingsw/pitta-design-system";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import WifiIcon from "@material-ui/icons/Wifi";
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined";
import CheckIcon from "@material-ui/icons/Check";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";
import BusinessIcon from "@material-ui/icons/Business";

import Hidden from "@material-ui/core/Hidden";
import Footer from "../components/Footer";
import TryBlackvue from "../components/TryBlackvue";
import { MainHeader } from "../components/MainHeader";
import { NavigationDrawer } from "../components/NavigationDrawer";
import Carousel from "../components/Carousel";
import { useSelector } from "react-redux";
import { USER } from "../features/User/slice";
import { RootState } from "../features/store";
import withSimpleBar from "../hoc/withSimpleBar";
import { MaintenanceHandler } from "../components/MaintenanceHandler";
import { LightColors, Webviewer } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  video: {
    zIndex: 999,
    objectPosition: "center",
    position: "relative",
    height: 488,
    [theme.breakpoints.up("md")]: {
      height: 534,
    },
    [theme.breakpoints.up("lg")]: {
      height: 695,
    },
    [theme.breakpoints.up(1440)]: {
      height: 744,
    },
    [theme.breakpoints.up("xl")]: {
      height: 994,
    },
    overflow: "hidden",
    // marginTop: -55,
    "&>video": {
      dispaly: "block",
      height: "100%",
      [theme.breakpoints.up(890)]: {
        width: "100%",
        height: "auto",
      },
    },
  },
  videoBg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  videoOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: `${LightColors.primary["1"]}74`,
    // padding: "82px 0px",
    // [theme.breakpoints.up("sm")]: {
    //   padding: "120px 0",
    // },
    // [theme.breakpoints.up("md")]: {
    //   padding: "136px 0",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   padding: "186px 0",
    // },
    // [theme.breakpoints.up(1440)]: {
    //   padding: "226px 0",
    // },
    // [theme.breakpoints.up("xl")]: {
    //   padding: "350px 0",
    // },
  },
  ctaDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "114px 16px",
    [theme.breakpoints.up("sm")]: {
      padding: "120px 0",
    },
    [theme.breakpoints.up("md")]: {
      padding: "136px 0",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "186px 0",
    },
    [theme.breakpoints.up(1440)]: {
      padding: "236px 0",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "350px 0",
    },
  },
  bodyDiv1: {
    backgroundColor: LightColors.primary["10"],
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  body1: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bodyIconDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `${theme.spacing(4)}px 0`,
    [theme.breakpoints.up("sm")]: {
      margin: `${theme.spacing(8)}px 0`,
      flexDirection: "row",
      alignItems: "flex-end",
    },
  },
  iconDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      margin: `0 ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.up("md")]: {
      margin: `0 ${theme.spacing(8)}px`,
    },
    "&:first-child": {
      marginLeft: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
    "&>span": {
      textAlign: "center",
    },
  },
  bodyIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    marginBottom: theme.spacing(2),
  },
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  bodyText: {
    maxWidth: 906,
    textAlign: "center",
  },
  ctaText: {
    maxWidth: 906,
    color: LightColors.primary["0"],
    textAlign: "center",
    textShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
  },
  ctaTitle: {
    marginBottom: theme.spacing(3),
  },
  ctaSubtitle: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(8),
    },
  },
  ctaButton: {
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      width: 320,
      // padding: "120px 0",
    },
  },
  plusDiv: {
    width: 32,
    display: "flex",
    justifyContent: "center",
    margin: `${theme.spacing(4)}px 0`,
    [theme.breakpoints.up("sm")]: {
      margin: 0,
    },
  },
  body2: {
    padding: `${theme.spacing(8)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(16)}px ${theme.spacing(4)}px`,
      paddingBottom: theme.spacing(15),
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  body3: {
    padding: `${theme.spacing(8)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(16)}px ${theme.spacing(4)}px`,
    },
  },
  bodyFleet: {
    padding: theme.spacing(8, 2, 4),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(16, 4, 8),
    },
  },
  body4: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(16)}px ${theme.spacing(4)}px`,
    },
  },
  body5: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
      alignItems: "center",
    },
    backgroundColor: LightColors.secondary["18"],
  },
  marginT3: {
    marginTop: theme.spacing(3),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
  marginB7: {
    marginBottom: theme.spacing(7),
  },
  marginB8: {
    marginBottom: theme.spacing(8),
  },
  marginB16: {
    marginBottom: theme.spacing(16),
  },
  featureDiv: {
    display: "flex",
    flexDirection: "column",
    "&:nth-child(even)": {
      flexDirection: "column-reverse",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      "&:nth-child(even)": {
        flexDirection: "row",
      },
    },
    width: "100%",
  },
  paddingL4: {
    paddingLeft: theme.spacing(4),
  },
  paddingR4: {
    paddingRight: theme.spacing(4),
  },
  featureImgRight: {
    justifyContent: "flex-end",
  },
  featureImgLeft: {
    justifyContent: "flex-start",
  },
  descDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "47.66%",
      "&:nth-child(even)": {
        paddingLeft: 117,
      },
      "&:nth-child(odd)": {
        paddingRight: 117,
      },
    },
  },
  featureImgDiv: {
    display: "flex",
    flexGrow: 1,
    marginLeft: -16,
    [theme.breakpoints.up("sm")]: {
      width: "52.33%",
      marginLeft: 0,
      alignItems: "center",
    },
    "&>img": {
      width: "100%",
      height: "100%",
    },
  },
  bgWhite: {
    backgroundColor: LightColors.primary["0"],
  },
  featureDiv2: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    marginBottom: theme.spacing(4),
  },
  feature2: {
    display: "flex",
    marginRight: theme.spacing(4),
    "&:last-child": {
      marginRight: 0,
    },
  },
  checkIcon: {
    fontSize: 24,
    marginRight: theme.spacing(1),
    color: LightColors.secondary["15"],
  },
  fleetTextDiv: {
    maxWidth: 1142,
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  featureDiv3: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    maxWidth: 1142,
    justifyContent: "space-between",
  },
  feature3: {
    backgroundColor: LightColors.primary["10"],
    borderRadius: 4,
    padding: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 16px)",
      maxWidth: 555,
    },
  },
  featureDiv4: {
    width: 928,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  feature4: {
    borderRadius: 4,
    border: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(3),
    cursor: "pointer",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      maxWidth: 320,
      width: "33%",
      "&:first-child": {
        marginLeft: theme.spacing(2),
      },
      "&:last-child": {
        marginRight: theme.spacing(2),
      },
      marginBottom: 0,
    },
  },
  featureActive4: {
    border: undefined,
    backgroundColor: `${LightColors.primary["1"]}07`,
    borderColor: "transparent",
  },
  flex: {
    display: "flex",
  },
  featureImgDiv4: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: "0 46px",
    },
  },
  fontSize64: {
    fontSize: 64,
  },
  selectorDiv: {
    display: "flex",
    justifyContent: "center",
  },
  feature4BtnDiv: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      paddingTop: 0,
      paddingBottom: theme.spacing(16),
      display: "flex",
      justifyContent: "center",
    },
  },
  feature4Selector: {
    width: 24,
    height: 3,
    borderRadius: 1,
    backgroundColor: "#C4C4C4",
    marginRight: 4,
    cursor: "pointer",
    "&:last-child": {
      marginRight: 0,
    },
  },
  feature4SelectorActive: {
    backgroundColor: LightColors.primary["7"],
  },
  featureDiv5: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  feature5: {
    [theme.breakpoints.up("sm")]: {
      width: "33%",
      marginRight: theme.spacing(8),
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  carouselImg: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "95%",
    },
  },
  carouselDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  fleetFeatureIcon: {
    fill: LightColors.primary["7"],
  },
}));

export const LandingScreen = withSimpleBar(() => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();

  const { loginInfo } = useSelector((state: RootState) => state[USER]);

  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const fullBtn = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const mobileScroll = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const category = matches ? "Large" : "Default";
  const thresDivRef = useRef<HTMLDivElement>(null);
  const mainHeaderRef = useRef<HTMLDivElement>(null);
  const cloudDivRef = useRef<HTMLDivElement>(null);
  const fleetDivRef = useRef<HTMLDivElement>(null);
  const [headerColor, setHeaderColor] = useState<"transparent" | "default">(
    "transparent"
  );

  const [value, setValue] = useState<number>(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fleetValue, setFleetValue] = useState<number>(0);
  const [maintenancing, setMaintenancing] = useState(false);
  const [showMaintModal, setShowMaintModal] = useState(false);

  // 배터리 메인 새로고침 관련 로컬스토리지값 초기화
  // 배터리 메인 화면 & 세팅 화면에서 브라우저 정상 종료 후 웹뷰어를 다시 켰을 때
  // 배터리 화면으로 이동을 방지하기 위한 코드
  useEffect(() => {
    if (window) {
      window.localStorage.removeItem("pitta-webviewer:battery-currentCam");
      window.localStorage.removeItem("pitta-webviewer:battery-status");
      window.localStorage.removeItem("pitta-webviewer:battery-setting");
    }
  }, []);

  useEffect(() => {
    const link1 = document.createElement("link");
    link1.rel = "preload";
    link1.as = "image";
    link1.href = "/images/geofence.png";
    link1.imageSrcset = "/images/geofence.png 1x, /images/geofence@2x.png 2x";
    document.head.appendChild(link1);
    const link2 = document.createElement("link");
    link2.rel = "preload";
    link2.as = "image";
    link2.href = "/images/driving-report.png";
    link2.imageSrcset =
      "/images/driving-report.png 1x, /images/driving-report@2x.png 2x";
    document.head.appendChild(link2);
    const link3 = document.createElement("link");
    link3.rel = "preload";
    link3.as = "image";
    link3.href = "/images/multi-live-view.png";
    link3.imageSrcset =
      "/images/multi-live-view.png 1x, /images/multi-live-view@2x.png 2x";
    document.head.appendChild(link3);
  }, []);

  useScrollPosition(
    () => {
      const pos = thresDivRef.current?.getBoundingClientRect();
      if (pos && pos.top < 0) {
        setHeaderColor("default");
      } else {
        setHeaderColor("transparent");
      }

      const cloudPos = cloudDivRef.current?.getBoundingClientRect();
      const fleetPos = fleetDivRef.current?.getBoundingClientRect();
      const header = mainHeaderRef.current?.getBoundingClientRect() || {
        height: 0,
      };
      if (fleetPos && fleetPos.top <= header.height) {
        setValue(2);
      } else if (cloudPos && cloudPos.top <= header.height) {
        setValue(1);
      } else if (cloudPos && cloudPos.top > 0) {
        setValue(0);
      }
    },
    [],
    undefined,
    true
  );

  const handleClickMenu = () => {
    setOpenDrawer((open) => !open);
  };

  const handleClickPricing = () => {
    history.push("/pricing");
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (mobileScroll) {
      if (loginInfo?.user_token === undefined) {
        const body = document.getElementById("body-container");
        body?.setAttribute("style", "overflow:auto;");
      }
    }
  }, [loginInfo?.user_token, mobileScroll]);

  const handleMaintenance = useCallback(() => {
    setMaintenancing(true);
  }, []);

  const maintHandler = useMemo(() => {
    return (
      <MaintenanceHandler
        disableModal
        forceShow={showMaintModal}
        onCloseModal={() => {
          console.log("onCloseModal");
          setShowMaintModal(false);
        }}
        onMaintenance={handleMaintenance}
      />
    );
  }, [handleMaintenance, showMaintModal]);

  const mainHeaderMarkup = useMemo(() => {
    return (
      <MainHeader
        ref={mainHeaderRef}
        value={value}
        color={headerColor}
        onClickMenu={handleClickMenu}
      />
    );
  }, [headerColor, value]);

  return (
    <React.Fragment>
      {mainHeaderMarkup}
      <div className={classes.video}>
        <div className={classes.videoBg}>
          <img
            srcSet="/images/bg-hero.jpg 1x, /images/bg-hero@2x.png 2x"
            alt="hero background"
          />
        </div>
        <video autoPlay muted loop playsInline webkit-playsinline>
          <source
            src="/video/blackvuecloud-looping-video.mp4"
            type="video/mp4"
          />
        </video>
        <div className={classes.videoOverlay}>
          <Container className={classes.ctaDiv}>
            <Typography
              category={category}
              variant="H1"
              className={clsx(classes.ctaText, classes.ctaTitle)}
            >
              {t("Me and my_")}.
            </Typography>
            <Typography
              category={category}
              variant="H4"
              className={clsx(classes.ctaText, classes.ctaSubtitle)}
            >
              {t("add-on service for_")}.
            </Typography>
            <div className={classes.ctaButton}>
              <Button
                color="primary"
                size="large"
                fullWidth
                style={{ marginBottom: theme.spacing(2) }}
                onClick={() => {
                  history.push("/pricing");
                }}
              >
                {t("Pick a plan")}
              </Button>
              <div>
                <Typography
                  category="Default"
                  variant="Caption"
                  className={clsx(classes.ctaText)}
                >
                  {t("Free plan included_")}
                </Typography>
              </div>
            </div>
          </Container>
        </div>
      </div>
      {/* How it works */}
      <div className={classes.bodyDiv1} ref={thresDivRef}>
        <Container className={classes.body1}>
          <Typography
            category={category}
            variant="H2"
            className={classes.bodyText}
          >
            {t("How it works")}
          </Typography>
          <div className={classes.bodyIconDiv}>
            <div className={classes.iconDiv}>
              <CloudCamera className={classes.bodyIcon} />
              <Typography category={category} variant="H5">
                {t("Cloud Camera")}
              </Typography>
            </div>
            <div className={classes.plusDiv}>
              <Typography category={category} variant="H5">
                +
              </Typography>
            </div>
            <div className={classes.iconDiv}>
              <div className={classes.vcenter}>
                <WifiIcon className={classes.bodyIcon} color="primary" />
                <Typography
                  category={category}
                  variant="H4"
                  htmlColor={LightColors.primary["7"]}
                >
                  / LTE
                </Typography>
              </div>
              <Typography category={category} variant="H5">
                {t("Internet Connection")}
              </Typography>
            </div>
            <div className={classes.plusDiv}>
              <Typography category={category} variant="H5">
                +
              </Typography>
            </div>

            <div className={classes.iconDiv}>
              <TouchAppOutlinedIcon
                className={classes.bodyIcon}
                color="primary"
              />
              <Typography category={category} variant="H5">
                {t("BlackVue App/Viewer")}
              </Typography>
            </div>
          </div>
          <Typography
            category="Default"
            variant="Body"
            className={classes.bodyText}
          >
            {t("All you need_")}{" "}
            <Link
              category="Default"
              variant="Body"
              color="primary"
              mode="router"
              to="/compatible-dashcams"
            >
              {t("Cloud-compatible BlackVue")}
            </Link>{" "}
            {t("and a cellular_")}
          </Typography>
        </Container>
      </div>
      {/* Cloud */}
      <div className={classes.bgWhite} ref={cloudDivRef} id="cloud">
        <Container className={classes.body2}>
          <Typography
            category={category}
            variant="H2"
            className={classes.bodyText}
          >
            {t("Cloud features at_")}
          </Typography>

          <Typography
            category="Default"
            variant="Body"
            className={clsx(classes.bodyText, classes.marginT3)}
            style={{ marginBottom: 58 }}
          >
            {t("Enjoy peace of_")}
          </Typography>
          <div
            className={clsx(classes.featureDiv, matches && classes.paddingL4)}
            style={{ marginBottom: 50 }}
          >
            <div className={classes.descDiv}>
              <Typography
                category={category}
                variant="H4"
                className={classes.marginB3}
              >
                {t("Push Notifications")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("Let your BlackVue_")}
              </Typography>
            </div>
            <div
              className={clsx(classes.featureImgDiv, classes.featureImgRight)}
              style={{ marginTop: 4, marginRight: 2 }}
            >
              <img
                srcSet="/images/ealarm.png 1x, /images/ealarm@2x.png 2x"
                alt="emergency alarm"
              />
            </div>
          </div>

          <div
            className={clsx(classes.featureDiv, matches && classes.paddingR4)}
            style={{ marginBottom: 40 }}
          >
            <div
              className={clsx(classes.featureImgDiv, classes.featureImgLeft)}
            >
              <img
                srcSet="/images/remote-live-view.png 1x, /images/remote-live-view@2x.png 2x"
                alt="remote live view"
              />
            </div>

            <div className={classes.descDiv}>
              <Typography
                category={category}
                variant="H4"
                className={classes.marginB3}
              >
                {t("Remote Live View")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("Check on your_")}
              </Typography>
            </div>
          </div>

          <div
            className={clsx(classes.featureDiv, matches && classes.paddingL4)}
            style={{ marginBottom: 50 }}
          >
            <div className={classes.descDiv}>
              <Typography
                category={category}
                variant="H4"
                className={classes.marginB3}
              >
                {t("Remote Video Playback")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("Your BlackVue may_")}
                <br />
                <br />
                {t("Worried about eating_")}
              </Typography>
            </div>
            <div
              className={clsx(classes.featureImgDiv, classes.featureImgRight)}
              style={{ marginTop: 4, marginRight: 2 }}
            >
              <img
                srcSet="/images/remote-video-playback.png 1x, /images/remote-video-playback@2x.png 2x"
                alt="remote video playback"
              />
            </div>
          </div>

          <div
            className={clsx(classes.featureDiv, matches && classes.paddingR4)}
            style={{ marginBottom: 44 }}
          >
            <div
              className={clsx(classes.featureImgDiv, classes.featureImgLeft)}
            >
              <img
                srcSet="/images/video-backups.png 1x, /images/video-backups@2x.png 2x"
                alt="video backups"
              />
            </div>

            <div className={classes.descDiv}>
              <Typography
                category={category}
                variant="H4"
                className={classes.marginB3}
              >
                {t("Video Backup")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("Move files from_")}
              </Typography>
            </div>
          </div>

          <div
            className={clsx(classes.featureDiv, matches && classes.paddingL4)}
          >
            <div className={classes.descDiv}>
              <Typography
                category={category}
                variant="H4"
                className={classes.marginB3}
              >
                {t("Live GPS")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("View your vehicle’s_")}
              </Typography>
            </div>
            <div
              className={clsx(classes.featureImgDiv, classes.featureImgRight)}
              style={{ marginTop: 4, marginRight: 2 }}
            >
              <img
                srcSet="/images/live-gps.png 1x, /images/live-gps@2x.png 2x"
                alt="live gps"
              />
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: `${LightColors.primary["6"]}07` }}>
        <Container className={clsx(classes.body3, classes.flexColumnCenter)}>
          <Typography
            category={category}
            variant="H4"
            style={{ marginBottom: theme.spacing(4) }}
          >
            {t("Get more with_")}
          </Typography>
          <div className={classes.featureDiv2}>
            <div className={classes.feature2}>
              <CheckIcon className={classes.checkIcon} />
              <Typography category="Default" variant="Body">
                {t("Unlimited Remote Live_")}
              </Typography>
            </div>
            <div className={classes.feature2}>
              <CheckIcon className={classes.checkIcon} />
              <Typography category="Default" variant="Body">
                {t("Unlimited remote video_")}
              </Typography>
            </div>
            <div className={classes.feature2}>
              <CheckIcon className={classes.checkIcon} />
              <Typography category="Default" variant="Body">
                {t("Up to 3 dashcams_")}
              </Typography>
            </div>
          </div>
          <Button
            color="primary"
            fullWidth={!fullBtn}
            onClick={() => {
              history.push("/pricing");
            }}
          >
            {t("Pick a plan")}
          </Button>
        </Container>
      </div>
      {/* Fleet */}
      <div className={classes.bgWhite} ref={fleetDivRef} id="fleet">
        <Container
          className={clsx(classes.bodyFleet, classes.flexColumnCenter)}
        >
          <div>
            <div className={clsx(classes.fleetTextDiv, classes.flexColumn)}>
              <Typography
                category={category}
                variant="H2"
                className={classes.marginB3}
              >
                {t("Want to track_")}
              </Typography>
              <Typography
                category="Default"
                variant="Body"
                className={classes.marginB8}
              >
                {t("BlackVue Fleet Tracking_2")}
              </Typography>
            </div>
            <div
              className={clsx(
                classes.featureDiv3,
                matches && classes.marginB16,
                !matches && classes.marginB8
              )}
            >
              <div
                className={clsx(
                  classes.feature3,
                  classes.flexColumn,
                  matches && classes.marginB4,
                  !matches && classes.marginB2
                )}
              >
                <LiveView
                  className={clsx(classes.fontSize64, classes.fleetFeatureIcon)}
                  style={{ marginBottom: theme.spacing(2) }}
                />
                <Typography
                  category={category}
                  variant="H5"
                  style={{ marginBottom: theme.spacing(2) }}
                >
                  {t("Unlimited Remote Live_")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {t("Stream real-time video_")}
                </Typography>
              </div>

              <div
                className={clsx(
                  classes.feature3,
                  classes.flexColumn,
                  matches && classes.marginB4,
                  !matches && classes.marginB2
                )}
              >
                <CloudCamera
                  className={clsx(classes.fontSize64, classes.fleetFeatureIcon)}
                  style={{ marginBottom: theme.spacing(2) }}
                />
                <Typography
                  category={category}
                  variant="H5"
                  style={{ marginBottom: theme.spacing(2) }}
                >
                  {t("Up to 999 Dashcams")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {t("Register up to_")}
                </Typography>
              </div>

              <div
                className={clsx(
                  classes.feature3,
                  classes.flexColumn,
                  !fullBtn && classes.marginB2
                )}
              >
                <Cloud
                  className={clsx(classes.fontSize64, classes.fleetFeatureIcon)}
                  style={{ marginBottom: theme.spacing(2) }}
                />
                <Typography
                  category={category}
                  variant="H5"
                  style={{ marginBottom: theme.spacing(2) }}
                >
                  {t("15GB of Cloud_")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {t("15GB per additional_")}
                </Typography>
              </div>

              <div className={clsx(classes.feature3, classes.flexColumn)}>
                <GPSTracking
                  className={clsx(classes.fontSize64, classes.fleetFeatureIcon)}
                  style={{ marginBottom: theme.spacing(2) }}
                />
                <Typography
                  category={category}
                  variant="H5"
                  style={{ marginBottom: theme.spacing(2) }}
                >
                  {t("90-days GPS_")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {t("High accuracy: 15_")}
                </Typography>
              </div>
            </div>
          </div>
          <Typography
            category={category}
            variant="H4"
            style={{ textAlign: "center" }}
          >
            {t("Unique fleet management_")}
          </Typography>
        </Container>
        <Container>
          <Hidden xsDown>
            <div className={clsx(classes.featureDiv4, classes.flex)}>
              <div
                className={clsx(
                  classes.feature4,
                  classes.flexColumn,
                  fleetValue === 0 && classes.featureActive4
                )}
                onClick={() => setFleetValue(0)}
              >
                <Typography
                  category={category}
                  variant="H6"
                  className={classes.marginB2}
                >
                  {t("Geo Fencing")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {t("Create geofences and_")}
                </Typography>
              </div>
              <div
                className={clsx(
                  classes.feature4,
                  classes.flexColumn,
                  fleetValue === 1 && classes.featureActive4
                )}
                onClick={() => setFleetValue(1)}
              >
                <Typography
                  category={category}
                  variant="H6"
                  className={classes.marginB2}
                >
                  {t("Driving report")}
                </Typography>
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ letterSpacing: "-.01ch" }}
                >
                  {t("View at a_")}
                </Typography>
              </div>
              <div
                className={clsx(
                  classes.feature4,
                  classes.flexColumn,
                  fleetValue === 2 && classes.featureActive4
                )}
                onClick={() => setFleetValue(2)}
              >
                <Typography
                  category={category}
                  variant="H6"
                  className={classes.marginB2}
                >
                  {t("Multi Live View")}
                </Typography>
                <Typography category="Default" variant="Body">
                  {t("Monitor up to_")}
                </Typography>
              </div>
            </div>
          </Hidden>

          <div className={clsx(classes.featureImgDiv4)}>
            <Carousel
              navButtonsAlwaysInvisible={true}
              index={fleetValue}
              autoPlay={false}
              animation={mobile ? "slide" : "fade"}
              swipe={mobile}
              indicators={false}
              timeout={150}
              onChange={(index: any, active: any) => {
                setFleetValue(index);
              }}
            >
              <div className={classes.carouselDiv}>
                <Hidden smUp>
                  <div
                    className={clsx(
                      classes.feature4,
                      classes.flexColumn,
                      classes.featureActive4
                    )}
                    onClick={() => setFleetValue(0)}
                  >
                    <Typography
                      category={category}
                      variant="H6"
                      className={classes.marginB2}
                    >
                      {t("Geo Fencing")}
                    </Typography>
                    <Typography category="Default" variant="Body">
                      {t("Create geofences and_")}
                    </Typography>
                  </div>
                </Hidden>
                <img
                  srcSet="/images/geofence.png 1x, /images/geofence@2x.png 2x"
                  alt="geofence"
                  className={classes.carouselImg}
                />
              </div>
              <div className={classes.carouselDiv}>
                <Hidden smUp>
                  <div
                    className={clsx(
                      classes.feature4,
                      classes.flexColumn,
                      classes.featureActive4
                    )}
                    onClick={() => setFleetValue(1)}
                  >
                    <Typography
                      category={category}
                      variant="H6"
                      className={classes.marginB2}
                    >
                      {t("Driving report")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Body"
                      style={{ letterSpacing: "-.01ch" }}
                    >
                      {t("View at a_")}
                    </Typography>
                  </div>
                </Hidden>
                <img
                  srcSet="/images/driving-report.png 1x, /images/driving-report@2x.png 2x"
                  alt="driving report"
                  className={classes.carouselImg}
                />
              </div>
              <div className={classes.carouselDiv}>
                <Hidden smUp>
                  <div
                    className={clsx(
                      classes.feature4,
                      classes.flexColumn,
                      classes.featureActive4
                    )}
                    onClick={() => setFleetValue(2)}
                  >
                    <Typography
                      category={category}
                      variant="H6"
                      className={classes.marginB2}
                    >
                      {t("Multi Live View")}
                    </Typography>
                    <Typography category="Default" variant="Body">
                      {t("Monitor up to_")}
                    </Typography>
                  </div>
                </Hidden>
                <img
                  srcSet="/images/multi-live-view.png 1x, /images/multi-live-view@2x.png 2x"
                  alt="multi live view"
                  className={classes.carouselImg}
                />
              </div>
            </Carousel>
          </div>
          <Hidden smUp>
            <div className={classes.selectorDiv}>
              <div
                className={clsx(
                  classes.feature4Selector,
                  fleetValue === 0 && classes.feature4SelectorActive
                )}
                onClick={() => setFleetValue(0)}
              ></div>
              <div
                className={clsx(
                  classes.feature4Selector,
                  fleetValue === 1 && classes.feature4SelectorActive
                )}
                onClick={() => setFleetValue(1)}
              ></div>
              <div
                className={clsx(
                  classes.feature4Selector,
                  fleetValue === 2 && classes.feature4SelectorActive
                )}
                onClick={() => setFleetValue(2)}
              ></div>
            </div>
          </Hidden>

          <div className={classes.feature4BtnDiv}>
            <Button
              color="primary"
              fullWidth={!fullBtn}
              onClick={() => {
                if (maintenancing) {
                  setShowMaintModal(true);
                } else {
                  history.push("/signup", { fleetaPromo: true, mode: "fleet" });
                }
              }}
            >
              {t("Try 1 Month Free")}
            </Button>
          </div>
        </Container>
      </div>
      <div className={clsx(classes.body5)}>
        <Container className={classes.flexColumnCenter}>
          <Typography
            category={category}
            variant="H2"
            className={classes.marginB4}
          >
            {t("Benefits for you")}
          </Typography>
          <div className={classes.featureDiv5}>
            <div
              className={clsx(
                classes.feature5,
                classes.flexColumn,
                classes.marginB4
              )}
            >
              <PersonOutlinedIcon
                className={clsx(classes.fontSize64, classes.marginB2)}
                htmlColor={LightColors.secondary["17"]}
              />
              <Typography
                category={category}
                variant="H5"
                className={classes.marginB2}
              >
                {t("Individual_")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("Get notifications to_2")}
              </Typography>
            </div>
            <div
              className={clsx(
                classes.feature5,
                classes.flexColumn,
                classes.marginB4
              )}
            >
              <StorefrontIcon
                className={clsx(classes.fontSize64, classes.marginB2)}
                htmlColor={LightColors.secondary["17"]}
              />
              <Typography
                category={category}
                variant="H5"
                className={classes.marginB2}
              >
                {t("Small business owner")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("Monitor your commercial_2")}
              </Typography>
            </div>
            <div className={clsx(classes.feature5, classes.flexColumn)}>
              <BusinessIcon
                className={clsx(classes.fontSize64, classes.marginB2)}
                htmlColor={LightColors.secondary["17"]}
              />
              <Typography
                category={category}
                variant="H5"
                className={classes.marginB2}
              >
                {t("Company with large_")}
              </Typography>
              <Typography category="Default" variant="Body">
                {t("With BlackVue Fleet_2")}
              </Typography>
            </div>
          </div>
        </Container>
      </div>
      <TryBlackvue />
      <Footer />
      <Hidden mdUp>
        <NavigationDrawer
          open={openDrawer}
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
          onClickCloud={() => setOpenDrawer(false)}
          onClickFleet={() => setOpenDrawer(false)}
          onClickPricing={handleClickPricing}
        />
      </Hidden>
      {maintHandler}
    </React.Fragment>
  );
});
