import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IEventMapState {
  openFilter: boolean;
}

let initialState: IEventMapState = {
  openFilter: false,
};

const slice = createSlice({
  name: "event-map",
  initialState,
  reducers: {
    setOpenFilter: (state, { payload }: PayloadAction<boolean>) => {
      state.openFilter = payload;
    },
  },
});

export const { setOpenFilter } = slice.actions;

export const EVENTMAP = slice.name;
export default slice.reducer;
