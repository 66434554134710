import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Ifreetrial,
  IPaymentState,
  ISubscriptionInfo,
  ITransaction,
  IWebSubscriptionInfo,
} from "@thingsw/pitta-modules";

let initialState: IPaymentState = {
  loading: false,
  transactionHistory: [],
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearPayment: (state) => {
      state.loading = false;
      state.subscriptionInfo = undefined;
      state.webSubscriptionInfo = undefined;
      state.transactionHistory = [];
      state.type = undefined;
      state.freetrial = undefined;
    },
    loadSubscription: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    getFreetrialState: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successGetFreetrialState: (
      state,
      { payload }: PayloadAction<Ifreetrial>
    ) => {
      state.loading = false;
      state.freetrial = payload;
    },
    successLoadSubscription: (
      state,
      { payload }: PayloadAction<ISubscriptionInfo>
    ) => {
      state.loading = false;
      state.subscriptionInfo = payload;
      // state.subscriptionInfo = { ...payload, servicePlanName: "free" };
    },
    loadWebSubscription: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadWebSubscription: (
      state,
      { payload }: PayloadAction<IWebSubscriptionInfo>
    ) => {
      state.loading = false;
      state.webSubscriptionInfo = payload;
    },
    loadTransactions: (state, action: PayloadAction) => {
      state.loading = true;
      state.type = action.type;
    },
    successLoadTransactions: (
      state,
      { payload }: PayloadAction<ITransaction[]>
    ) => {
      state.loading = false;
      state.transactionHistory = payload;
    },
    cancelSubscription: (state, action: PayloadAction<string | undefined>) => {
      state.loading = true;
      state.type = action.type;
    },
    successRequest: (state) => {
      state.loading = false;
    },
  },
});

export const {
  clearPayment,
  loadSubscription,
  successLoadSubscription,
  loadWebSubscription,
  successLoadWebSubscription,
  loadTransactions,
  successLoadTransactions,
  cancelSubscription,
  successRequest,
  getFreetrialState,
  successGetFreetrialState,
} = slice.actions;
export const PAYMENT = slice.name;
export default slice.reducer;
