import { Card } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  IconButton,
  ScrollBar,
  SmartPhoneIcon,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import clsx from "clsx";

import { RootState } from "../../features/store";
import { useSelector } from "react-redux";
import { CAMERA } from "../../features/Camera/slice";
import { PAYMENT } from "../../features/Payment/slice";
import { THEME } from "../../features/Theme/slice";
import { getPlanFromServiceID } from "../../utils/Service";

import _ from "lodash";
import { Webviewer, ICameraInfo, PSN650 } from "@thingsw/pitta-modules";
import { EmailNotificationSettngPanel } from "../uxui/EmailNotificationSettngPanel";
import { PushNotificationSettngPanel } from "../uxui/PushNotificationSettngPanel";

const useStyles = makeStyles((theme: Theme) => ({
  settingWrap: {
    height: "100%",
    backgroundColor: (props: any) =>
      props.color === "dark" ? "#121216" : "#E8E8E8",
  },

  settingTitleDiv: {
    display: "flex",
    alignItems: "center",
    backgroundColor: (props: any) => props.colors.primary["0"],
    height: 56,
    padding: "18px 16px",
    // borderBottom: `1px solid ${LightColors.primary["4"]}`,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "22px 16px",
      backgroundColor: "inherit",
      height: "auto",
      borderBottom: "none",
    },
    "& button": {
      color: (props: any) => props.colors.primary["1"],
    },
  },
  container: {
    padding: "22px 16px",

    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: 0,
    },
  },
  card: {
    padding: "15px 19px",
    borderRadius: 12,
    marginBottom: 8,
    cursor: "pointer",
    color: (props: any) => props.colors.primary["1"],
    border: (props: any) =>
      props.color === "dark" ? "1px solid #2D2D33" : "none",
    backgroundColor: (props: any) =>
      props.color === "dark" ? "#1E1E23" : "#FFFFFF",
  },
  emailMenuCard: {
    padding: "14px 19px 14px 18px",
  },
  icon: {
    marginRight: 7,
    fontSize: 26,
  },
  arrowIcon: {
    color: (props: any) => props.colors.primary["3"],
  },

  settingCardDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg path:nth-child(1)": {
      fill: (props: any) => (props.color === "dark" ? "#FFFFFF" : "#000000"),
    },
  },
  dayFilterClose: {
    transform: "translateX(100%)",
  },
  listClose: {
    transform: "translateX(-100%)",
  },
  listContDiv: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    // overflow: "auto",
    overflow: "hidden",
    top: 60,
    right: 0,
    left: 0,
    bottom: 0,
    padding: "16px 15px",
    transition: theme.transitions.create("transform"),
  },
}));

export interface NotificationSettingProps {
  onClose: () => void;
  app?: boolean;
  open: boolean;
  camera?: ICameraInfo;
  currentCam?: ICameraInfo;
}

export const NotificationSetting = ({
  onClose,
  app,
  open,
  currentCam,
}: NotificationSettingProps) => {
  const { t } = useTranslation();

  const { camera } = useSelector((state: RootState) => state[CAMERA]);
  const { colors, color } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ colors, color });

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const [menu, setMenu] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [subMenuTitle, setSubmenuTitle] = useState<string>("");
  const [isClickedBack, setIsClickedBack] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      //@ts-ignore
      window.backPressed = () => {
        if (menu !== 0) {
          return setMenu(0);
        }
        if (menu === 0) {
          onClose();
        }
      };
    }
  }, [menu, onClose, open]);

  useEffect(() => {
    if (subMenuTitle) {
      setTitle(subMenuTitle);
    } else {
      if (menu === 1) {
        setTitle("Push Notifications_2");
      } else if (menu === 2) {
        setTitle("Email Notifications");
      } else {
        setTitle("Camera notification settings");
      }
    }
  }, [menu, subMenuTitle]);

  const handleClose = useCallback(() => {
    if (subMenuTitle) {
      setSubmenuTitle("");
    } else {
      if (menu === 1) {
        setMenu(0);
      } else if (menu === 2) {
        setMenu(0);
      } else {
        onClose();
      }
    }

    setIsClickedBack(true);
  }, [menu, subMenuTitle, onClose]);

  useEffect(() => {
    if (isClickedBack) {
      setTimeout(() => {
        setIsClickedBack(false);
      }, 100);
    }
  }, [isClickedBack]);

  const isFleet = useMemo(() => {
    if (subscriptionInfo) {
      return (
        getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Fleet plan"
      );
    }
    return false;
  }, [subscriptionInfo]);

  return (
    <div className={classes.settingWrap}>
      <div className={classes.settingTitleDiv}>
        <IconButton style={{ marginRight: 7 }} onClick={handleClose}>
          <ArrowBackIosIcon
            htmlColor={
              !isClickedBack ? (color === "dark" ? "white" : "black") : ""
            }
          />
        </IconButton>

        <Typography category="Default" variant="H6" style={{ marginTop: 3 }}>
          {t(`${title}`)}
        </Typography>
      </div>
      <div className={classes.container}>
        <div
          className={clsx(classes.listContDiv, {
            [classes.listClose]: menu === 1 || menu === 2,
          })}
        >
          {/* mantis - 12123, 650GW 대시캠 FW ver. 2.0이상 Push Notifications 미 노출 (Leehj) */}
          {!(
            camera &&
            _.includes(PSN650, camera.psn.substr(0, 4)) &&
            parseFloat(camera.fw_ver) >= 2
          ) && (
            <Card variant="outlined" className={classes.card}>
              <div
                className={classes.settingCardDiv}
                onClick={() => setMenu(1)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SmartPhoneIcon style={{ marginRight: 8 }} />
                  <Typography category="Default" variant="Body">
                    {t("Push Notifications_2")}
                  </Typography>
                </div>

                <IconButton>
                  <ChevronRightIcon className={classes.arrowIcon} />
                </IconButton>
              </div>
            </Card>
          )}
          {/* mantis-10376 Fleet Plan 사용자만 사용할 수 있는 기능으로 다른 Plan은 Email Notifications 항목을 볼 수 없도록 구현 */}
          {isFleet && (
            <Card
              variant="outlined"
              className={clsx(classes.card, classes.emailMenuCard)}
            >
              <div
                className={classes.settingCardDiv}
                onClick={() => setMenu(2)}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MailOutlineIcon className={classes.icon} />
                  <Typography category="Default" variant="Body">
                    {t("Email Notifications")}
                  </Typography>
                </div>

                <IconButton>
                  <ChevronRightIcon className={classes.arrowIcon} />
                </IconButton>
              </div>
            </Card>
          )}
        </div>

        <div
          className={clsx(classes.listContDiv, {
            [classes.dayFilterClose]: menu !== 1,
          })}
        >
          {camera && (
            <ScrollBar>
              <PushNotificationSettngPanel
                app={app}
                back={menu !== 1}
                currentCam={currentCam}
                darkMode={color === "dark"}
              />
            </ScrollBar>
          )}
        </div>
        <div
          className={clsx(classes.listContDiv, {
            [classes.dayFilterClose]: menu !== 2,
          })}
        >
          <ScrollBar>
            <EmailNotificationSettngPanel
              app={app}
              back={menu !== 2}
              darkMode={color === "dark"}
            />
          </ScrollBar>
        </div>
      </div>
    </div>
  );
};
