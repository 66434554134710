import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, Typography } from "@thingsw/pitta-design-system";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { RootState } from "../../features/store";
import {
  loadSubscription,
  loadTransactions,
  loadWebSubscription,
  PAYMENT,
} from "../../features/Payment/slice";
import axios from "axios";
import { USER } from "../../features/User/slice";
import moment from "moment";
import { openToast } from "../../features/Toast/slice";
import { handleServer500, setError } from "../../features/Error/slice";
import {
  Webviewer,
  FLEET_PRICE,
  FLEET_EXTRA,
  API_GATEWAY_URI,
  STRIPE_OS_TYPE,
  RESULT_CODE,
  MAX_CAM_COUNT,
  FLEETA_PROMO_CODE,
} from "@thingsw/pitta-modules";
import { useQuery } from "@tanstack/react-query";
import { checkPromoApplied } from "../../apis";
import _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import { THEME } from "../../features/Theme/slice";

interface SubMgrModalProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNegative?: () => void;
  onClickPositive?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 438,
    },
  },
  plan: {
    paddingBottom: 24,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingBottom: 0,
    },
  },
  modalTitle: {
    minHeight: 36,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      minHeight: 24,
    },
    color: (props: any) => props.colors.primary["1"],
  },
  input: {
    color: (props: any) => props.colors.primary["1"],
  },
}));

export const SubMgrModal = ({
  open,
  onClose,
  onClickNegative,
  onClickPositive,
}: SubMgrModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const { colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ colors });
  const dispatch = useDispatch();

  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["dont-show-query", email ?? ""],
    queryFn: async () => {
      if (!email) return;
      if (!loginInfo) return;
      const resp = await checkPromoApplied(
        email,
        loginInfo.user_token,
        tokenType
      );

      const a = _.find(resp.data.response, (d) => {
        if (d.promotionCode !== FLEETA_PROMO_CODE) return;
        return d.isPromotionApplied;
      });
      // console.log("SubMgrModal", "a", a);
      return a ? a : false;
    },
    refetchIntervalInBackground: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!email && !!loginInfo,
  });

  const subscriptionInfo = useSelector(
    (state: RootState) => state[PAYMENT].subscriptionInfo
  ); /*Redux 사용*/

  const [camCnt, setCamCnt] = useState<number>(
    subscriptionInfo?.cameraLimit ?? 1
  );
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const [disabledChange, setDisabledChange] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadSubscriptionInfo = useCallback(() => {
    dispatch(loadSubscription());
    dispatch(loadWebSubscription());
    dispatch(loadTransactions());
  }, [dispatch]);

  useEffect(() => {
    if (camCnt) {
      setDisabledChange(subscriptionInfo?.cameraLimit === camCnt);
    }
  }, [camCnt, subscriptionInfo]);

  const handleUpdateCam = useCallback(async () => {
    setLoading(true);

    try {
      const resp1 = await axios.post(
        `${API_GATEWAY_URI}/Payment/WebSubscribeCameraChange`,
        {
          email,
          user_token: loginInfo?.user_token,
          ostype: STRIPE_OS_TYPE,
          step: "payload",
          itemID: `fleet${camCnt}`,
          tokenType: tokenType,
        }
      );

      const data1 = resp1.data as {
        resultcode: RESULT_CODE;
        response: { payload: string };
      };

      if (data1.resultcode === "BC_ERR_OK") {
        const resp2 = await axios.post(
          `${API_GATEWAY_URI}/Payment/WebSubscribeCameraChange`,
          {
            email,
            user_token: loginInfo?.user_token,
            ostype: STRIPE_OS_TYPE,
            step: "verify",
            payload: data1.response.payload,
            cameraCount: camCnt,
            tokenType: tokenType,
          }
        );

        const data2 = resp2.data as {
          resultcode: RESULT_CODE;
        };

        if (data2.resultcode === "BC_ERR_OK") {
          setLoading(false);
          onClickPositive?.();

          loadSubscriptionInfo();
          dispatch(openToast({ message: "Changes saved" }));
        } else if (data2.resultcode === "BC_ERR_INTERNAL_ERROR") {
          dispatch(handleServer500());
        } else {
          dispatch(setError("An error occured while_"));
        }
      } else if (data1.resultcode === "BC_ERR_INTERNAL_ERROR") {
        dispatch(handleServer500());
      } else {
        dispatch(setError("An error occured while_"));
      }
    } catch (err) {
      console.error(err);
      dispatch(setError("An error occured while_"));
    } finally {
      setLoading(false);
      onClickNegative?.();
    }
  }, [
    camCnt,
    dispatch,
    email,
    loadSubscriptionInfo,
    loginInfo?.user_token,
    onClickNegative,
    onClickPositive,
    tokenType,
  ]);

  // console.log("SubMgrModal", "data", data);

  const pricingMarkup = useMemo(() => {
    if (isLoading || isFetching) {
      return <CircularProgress />;
    }

    const discount = data ? _.round(FLEET_PRICE / 2, 2) : 0;
    const extraPrice = (camCnt - 1) * FLEET_EXTRA;
    const extraDiscount = data ? _.round(extraPrice / 2, 0) : 0;
    const totalPrice = _.round(
      FLEET_PRICE + extraPrice - discount - extraDiscount,
      2
    );
    return (
      <div style={{ padding: 16 }}>
        <div
          style={{
            display: "flex",
            paddingBottom: 8,
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{ zIndex: 1 }}
            category="Default"
            variant="Small"
            htmlColor={colors.primary["1"]}
          >
            {t("Subscription")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            style={{ textAlign: "right" }}
            htmlColor={colors.primary["1"]}
          >
            {FLEET_PRICE} USD/mo
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            paddingBottom: 8,
            justifyContent: "space-between",
          }}
        >
          <Typography
            category="Default"
            variant="Small"
            htmlColor={colors.primary["1"]}
          >
            {t("Extra cameras")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            style={{ textAlign: "right" }}
            htmlColor={colors.primary["1"]}
          >
            {((camCnt - 1) * FLEET_EXTRA).toFixed(2)} USD/mo
          </Typography>
        </div>
        {data && (
          <div
            // className={classes.flexBetween}
            style={{
              display: "flex",
              paddingBottom: mobile ? 7 : 15,
              justifyContent: "space-between",
            }}
          >
            <Typography
              category="Default"
              variant="Small"
              htmlColor={colors.primary["7"]}
            >
              {t("Special Discount (50% off)")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              style={{ textAlign: "right" }}
              htmlColor={colors.primary["7"]}
            >
              - {(discount + extraDiscount).toFixed(2)} USD/mo
            </Typography>
          </div>
        )}
        <div
          style={{
            display: "flex",
            borderBottom: `1px solid ${colors.primary["5"]}`,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            paddingTop: 8,
            justifyContent: "space-between",
          }}
        >
          <Typography
            category="Default"
            variant="Small"
            htmlColor={colors.primary["1"]}
          >
            {t("Total due")}{" "}
            {moment(
              subscriptionInfo?.endOfMonthDT,
              "YYYY-MM-DD hh:mm:ss"
            ).format("MMM D, YYYY")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            style={{ textAlign: "right" }}
            htmlColor={colors.primary["1"]}
          >
            {totalPrice} USD/mo
          </Typography>
        </div>
      </div>
    );
  }, [
    camCnt,
    colors.primary,
    data,
    isFetching,
    isLoading,
    mobile,
    subscriptionInfo?.endOfMonthDT,
    t,
  ]);

  const promoRemainsMarkup = useMemo(() => {
    // console.log("SubMgrModal", "promoRemainsMarkup", "data", data);
    if (!data) return;
    const now = moment();
    const oneMonth = moment(data.createdAt)
      .add(data.freetrialPeriod, "month")
      .isAfter(now);
    if (oneMonth) {
      return (
        <>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={colors.primary["2"]}
          >
            * {t("1 Month Free Trial")}
          </Typography>
          <br />
        </>
      );
    }

    const endDate = moment(data.createdAt).add(
      data.freetrialPeriod + data.servicePeriod,
      "month"
    );
    console.log("endDate", data, endDate);
    return (
      <>
        <Typography
          category="Default"
          variant="Small"
          htmlColor={colors.primary["2"]}
        >
          * {t("Promotion valid until")} {endDate.format("MMM D, YYYY")}
        </Typography>
        <br />
      </>
    );
  }, [colors.primary, data, t]);

  return (
    <Modal
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={() => handleUpdateCam()}
      className={classes.root}
      heading={t("Manage cameras")}
      close={true}
      fullSizeSub={mobile}
      titleClassName={classes.modalTitle}
      content={
        <div style={{}}>
          {promoRemainsMarkup}
          <Typography
            category="Default"
            variant="Small"
            htmlColor={colors.primary["2"]}
          >
            {t("You manage_available", { a: camCnt, b: MAX_CAM_COUNT })}
          </Typography>
          <div
            style={{
              margin: "16px 0 32px",
              width: "100%",
              backgroundColor: `${colors.primary["1"]}08`,
              zIndex: 0,
              borderRadius: 4,
            }}
          >
            {pricingMarkup}
          </div>
          <div>
            <Input
              PlusButtonDis={camCnt === MAX_CAM_COUNT}
              MinusButtonDis={camCnt === 1}
              label="Cameras"
              count
              inputmode="numeric"
              pattern="[0-9]*"
              value={camCnt}
              onPlus={() => {
                setCamCnt((c) => Math.min(c + 1, MAX_CAM_COUNT));
              }}
              onMinus={() => {
                setCamCnt((c) => Math.max(c - 1, 1));
              }}
              onChange={(event) => {
                console.log(event.target.value);
                if (event.target.value === "") {
                  setCamCnt(1);
                } else {
                  const value = Number.parseInt(event.target.value);
                  console.log("aaa", value);
                  if (value <= MAX_CAM_COUNT && value > 0) {
                    setCamCnt(value);
                  }
                }
                setDisabledChange(false);
              }}
              inputClassName={classes.input}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className={classes.plan}
                style={{
                  width: "94%",
                  display: "flex",
                }}
              >
                <Typography
                  style={{ paddingTop: 3 }}
                  category="Default"
                  variant="Caption"
                  htmlColor={colors.primary["2"]}
                >
                  {t("available on your_", { a: MAX_CAM_COUNT })}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Change")}
      RButtonDisabled={disabledChange}
      Secondary={false}
      loading={loading}
    />
  );
};
