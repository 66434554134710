import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";
import { makeStyles, Theme } from "@material-ui/core";
import { Button, Modal, Typography } from "@thingsw/pitta-design-system";
import React from "react";
import Lottie from "lottie-react";

import FleetaPromoSuccess from "../../lottiefiles/fleeta-promo-success.json";
import FleetaPromoSuccessIcon from "../../icons/FleetaPromoSuccessIcon";
import { PAYMENT } from "../../features/Payment/slice";

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    backgroundColor: (props: any) => props.colors.primary["0"],
    border: "unset",
    borderRadius: 12,
    maxWidth: 430,
    minWidth: 343,
  },
  modalContent: {
    display: "flex",
    border: "unset",
    padding: "16px 24px",
  },
}));

interface FleetaPromoSuccessModalProps {
  open: boolean;
  onClose: () => void;
}

export const FleetaPromoSuccessModal = ({
  open,
  onClose,
}: FleetaPromoSuccessModalProps) => {
  const { t } = useTranslation();
  const { colors } = useSelector((state: RootState) => state[THEME]);

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );
  const classes = useStyles({ colors });

  return (
    <Modal
      className={classes.modalBody}
      contentClassName={classes.modalContent}
      open={open}
      content={
        <div className="flex flex-col gap-4 w-full">
          <div className="text-primary-7">
            <Typography variant="H4">{t("Congratulations !")}</Typography>
          </div>
          <div className="flex flex-col gap-3">
            <div>
              <Lottie
                animationData={FleetaPromoSuccess}
                loop={true}
                style={{ width: "100%" }}
              />
            </div>
            <div className="flex flex-col gap-2  pt-8 relative">
              <div className="absolute right-0 top-0">
                <FleetaPromoSuccessIcon />
              </div>
              <div className="flex flex-col gap-1 text-primary-7">
                <Typography variant="Body">
                  {t("Now, You are now a")}
                </Typography>
                <Typography variant="H3">{t("Fleet Plan user")}</Typography>
              </div>
              <div className="text-primary-7">
                <Typography variant="Small">
                  {t("Available Cloud Camera slot(s)_", {
                    d: subscriptionInfo?.cameraLimit ?? 0,
                  })}
                </Typography>
              </div>
              <div className="text-primary-2">
                <Typography variant="Small">
                  {t("Enjoy your BlackVue Cloud service with your dashcam.")}
                </Typography>
              </div>
              <div className="text-primary-2 mt-4">
                <Typography variant="Small">
                  {t("*If you are subscribed in-app_")}
                </Typography>
                <br />
                <Typography variant="Small">
                  {t("*If you made the payment through_")}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex justify-end pt-3">
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ minWidth: 150 }}
              onClick={onClose}
              className="w-full sm:w-auto"
            >
              {t("OK")}
            </Button>
          </div>
        </div>
      }
      close
      onClose={onClose}
    />
  );
};
