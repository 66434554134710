import { makeStyles, Theme } from "@material-ui/core";
import { SettingsIcon, Typography } from "@thingsw/pitta-design-system";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LightColors, ICameraInfo } from "@thingsw/pitta-modules";
import { useQuery } from "@thingsw/pitta-liveview-module";
import { NotificationSettingPanel } from "../../../components/renewal2/NotificationSettingPanel";
import { loadCameras } from "../../../features/Camera/slice";
import { RootState } from "../../../features/store";

const useStyles = makeStyles((theme: Theme) => ({
  guideRoot: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(19, 19, 28, 0.45)",
    zIndex: 9999,
  },
  guideContent: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  settingIcon: {
    fontSize: 28,
    "& path": {
      fill: "none",
      stroke: LightColors.primary["1"],
    },
  },
  settingBg: {
    width: 44,
    height: 44,
    borderRadius: "50%",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 8,
    top: 6,
  },
  arrow: {
    position: "absolute",
    right: 60,
    top: 26,
  },
  text: {
    width: 231,
    padding: "8px 12px",
    backgroundColor: LightColors.primary["8"],
    borderRadius: 12,
    position: "absolute",
    right: 20,
    top: 112,
  },
}));

export const RenewalNotificationScreen = () => {
  const classes = useStyles();
  const query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { cameraList } = useSelector((state: RootState) => state.camera);

  const [camera, setCamera] = useState<ICameraInfo>();
  const [hide, setHide] = useState(false);

  // const windowSize = useMemo(() => {
  //   return JSON.stringify({
  //     innerWidth: window.innerWidth,
  //     clientWidth: document.documentElement.clientWidth,
  //     outerWidth: window.outerWidth,
  //   });
  // }, []);

  useEffect(() => {
    dispatch(loadCameras());
  }, [dispatch]);

  const hideGuide = useMemo(() => {
    return Boolean(JSON.parse(query.get("hide_guide") ?? "false"));
  }, [query]);

  useEffect(() => {
    const psn = query.get("psn");
    if (psn) {
      const cam = _.find(
        cameraList?.deviceListInfo,
        (dev) => dev.device.psn === psn
      );
      if (cam) {
        setCamera(cam.device);
      }
    }
  }, [cameraList?.deviceListInfo, query]);

  return (
    <>
      {/* <div style={{ zIndex: 9999, background: "white" }}>{windowSize}</div> */}
      <NotificationSettingPanel
        open
        onClose={() => {}}
        currentCam={camera}
        app
      />
      {!hideGuide && !hide && (
        <div className={classes.guideRoot} onClick={() => setHide(true)}>
          <div className={classes.guideContent}>
            <div className={classes.settingBg}>
              <SettingsIcon className={classes.settingIcon} />
            </div>

            <img
              src="/images/notification-history-arrow.png"
              alt="notification guide arrow"
              className={classes.arrow}
            />
            <div className={classes.text}>
              <Typography
                category="Default"
                variant="BodyBold"
                htmlColor="#FFFFFF"
              >
                {t("You can adjust notification settings here.")}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
