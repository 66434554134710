import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";
import { useTranslation } from "react-i18next";
import { Typography } from "@thingsw/pitta-design-system";
import { SemanticDarkColors } from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  BatteryStatusButton: {
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl" ? { marginRight: 25 } : { marginLeft: 25 }),
  },
  btnContainer: {
    display: "flex",
    padding: 2,
    gap: 4,
  },
  btn: {
    flex: 1,
    height: 80,
    border: (props: any) => `1px solid ${props.colors.primary["6"]}`,
    borderRadius: 8,
    padding: 8,
    gap: 4,
    display: "flex",
    flexDirection: "column",
    backgroundColor: (props: any) =>
      props.color === "dark" ? SemanticDarkColors.primary["0"] : "white",
    "&:active": {
      backgroundColor: (props: any) => props.colors.primary["6"],
    },
  },
  rootTransition: {
    transition: theme.transitions.create(["transform", "height"]),
    userSelect: "none",
  },
  panelClose: {
    height: 118,
  },
  panelOpen: {
    height: 290,
  },
  flex: {
    display: "flex",
  },
  between: {
    justifyContent: "space-between",
  },
}));

export interface RenewalCameraMenuProps {
  title: string;
  icon: ReactElement;
  onClick: VoidFunction;
  subIcon?: ReactElement;
}

export const RenewalCameraMenu = ({
  title,
  icon,
  subIcon,
  onClick,
}: RenewalCameraMenuProps) => {
  const { colors, color } = useSelector((state: RootState) => state[THEME]);

  const classes = useStyles({ colors, color });
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.btn)} onClick={onClick}>
      <div className={clsx(classes.flex, classes.between)}>
        {icon}
        {subIcon}
      </div>
      <Typography
        category="Default"
        variant="Caption"
        htmlColor={colors.primary["1"]}
      >
        {t(title)}
      </Typography>
    </div>
  );
};
