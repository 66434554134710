import Drawer from "@material-ui/core/Drawer";
import {
  makeStyles,
  Theme,
  ThemeProvider,
  // ThemeProvider,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  SettingsIcon,
  Button,
  Fab,
  Fonts,
  IconButton,
  Tooltip,
  Typography,
  // theme as TWTheme,
  SearchDateIcon,
  Menu,
  Modal,
  DateRangePicker,
  RangeModifier,
  theme as TWTheme,
} from "@thingsw/pitta-design-system";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import List, {
  ListRowRenderer,
  RenderedRows,
} from "react-virtualized/dist/commonjs/List";
// import * as XLSX from "xlsx";
// import * as FileSaver from "file-saver";

import CloseIcon from "@material-ui/icons/Close";
// import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { RootState } from "../../features/store";
import { CAMERA, loadCamera } from "../../features/Camera/slice";
import _ from "lodash";
import { EVENT, IUXUILatestEvent } from "../../features/Event/slice";
import clsx from "clsx";
import { CircularProgress, Divider, useMediaQuery } from "@material-ui/core";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";

import BScroll from "@better-scroll/core";
import Indicators from "@better-scroll/indicators";
import { Index, AutoSizer } from "react-virtualized";
import { Scrollbars } from "react-custom-scrollbars";
// import { getTextHeight } from "../../../utils/Text";
import { isFree100Check } from "../../utils/isFree100Check";
import { ExceedModal } from "../modals/ExceedModal";
import { PAYMENT } from "../../features/Payment/slice";
import {
  loadUsageInfo,
  loadUserPermissions,
  USER,
} from "../../features/User/slice";
// import ReactDOMServer from "react-dom/server";
// import { THEME } from "../../../features/Theme/slice";
import { useLocation } from "react-router-dom";
import { finishLiveview, startLiveView } from "../../features/LiveView/slice";

import FilterListIcon from "@material-ui/icons/FilterList";

// import { useSwipeable } from "react-swipeable";
// import SimpleBarReact from "simplebar-react";
// import EventName from "../../../contants/EventName";
// import { NotificationScrollBar } from "../../NotificationScrollBar";
import { detect } from "detect-browser";
import ReactDOMServer from "react-dom/server";
import { THEME } from "../../features/Theme/slice";
import { getTextHeight } from "../../utils/Text";
import {
  Direction,
  DMS_EVENTS,
  Front,
  ICameraInfo,
  Interior,
  jwtAxiosInst,
  LightColors,
  MobileLang,
  MODELS_2CH,
  MSG_CODE_TYPE,
  Rear,
  sendMessageBack,
  Webviewer,
} from "@thingsw/pitta-modules";
import { EmptyItemsUXUI } from "../uxui/EmptyItemsUXUI";
import { LiveViewMapboxModal } from "../uxui/LiveViewMapboxModal";
import { NotificationEventFilterModal } from "./NotificationEventFilterModal";
import { NotificationSetting } from "./NotificationSetting";
import { UXUINotificationListItem } from "./UXUINotificationListItem";
import { VideoPlayerMapboxUXUIModal } from "../uxui/VideoPlayerMapboxUXUIModal";
import { getCloudConnectivityIcon } from "../../utils/Camera";

BScroll.use(Indicators);
////////////////////////////////////////////////////////////////////////////////////////
// CSS 스타일
export const FormControlLabel = withStyles((theme: Theme) => ({
  label: {
    ...Fonts.Default.Body,
    color: LightColors.primary["1"],
    marginLeft: 2,
    marginRight: 2,
  },
  root: {
    marginLeft: -6,
    marginRight: -6,
    marginBottom: theme.spacing(1.25),
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
}))(MuiFormControlLabel);

const useStyles = makeStyles((theme: Theme) => ({
  filterDiv: {
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: (props: any) => props.colors.primary["1"],
    backgroundColor: (props: any) => props.colors.primary["0"],
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 469,
    },
    overflow: "hidden",
    position: "relative",
  },
  filterTitleDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "17.5px 16px",
    // borderBottom: (props: any) => `1px solid ${props.colors.primary["6"]}`,
    height: 56,
    "& button": {
      color: (props: any) => props.colors.primary["1"],
    },
  },
  camInfoWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 8px 8px 16px",
    // borderBottom: (props: any) => `1px solid ${props.colors.primary["6"]}`,
    height: 49,
    boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.1)",
  },
  camInfoDiv: {
    display: "flex",
    alignItems: "center",
    // mantis - 10565, 모바일인 경우 카메라 이름 말줄임 처리되지 않는 이슈, 필터,달력아이콘 보이지 않는 이슈 수정(Leehj)
    width: "70%",
  },
  avatarStyle: {
    width: 32,
    height: 32,
  },
  camInfoIconDiv: {
    marginTop: 1,
    // mantis - 10565, 모바일인 경우 카메라 이름 말줄임 처리되지 않는 이슈, 필터,달력아이콘 보이지 않는 이슈 수정(Leehj)
    display: "flex",
  },
  camNameText: {
    letterSpacing: "-0.2px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  searchDateIcon: {
    width: 48,
    height: 48,
    "& path": {
      fill: (props: any) => props.colors.primary["1"],
    },
    "&:hover path": {
      fill: (props: any) => props.colors.primary["7"],
    },
  },
  filterIcon: {
    width: 48,
    height: 48,
    "& path": {
      fill: (props: any) => props.colors.primary["1"],
    },
    "&:hover path": {
      fill: (props: any) => props.colors.primary["7"],
    },
  },
  filterListDiv: {
    flex: 1,
    // padding: theme.spacing(0, 2),
    overflow: "auto",
    "& div.MuiPaper-root": {
      margin: theme.spacing(0, 3),
    },
  },
  filterBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    padding: theme.spacing(2),
  },
  filterChipBtnDiv: {
    display: "flex",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingLeft: theme.spacing(3),
    },
  },
  listContDiv: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    top: 105,
    right: 0,
    left: 0,
    bottom: 0,
    transition: theme.transitions.create("transform"),
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  settingListContDiv: {
    top: 0,
  },
  top0: {
    top: 0,
  },
  listClose: {
    transform: "translateX(-100%)",
  },
  dayFilterClose: {
    transform: "translateX(100%)",
  },
  btn: {
    margin: theme.spacing(0, 2, 0, 1.75),
  },
  closeBtn: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(1.75) }
      : { marginLeft: theme.spacing(1.75) }),
  },
  settingIcon: {
    fontSize: 28,
    "& path": {
      fill: "none",
      stroke: (props: any) => props.colors.primary["1"],
    },
    "&:hover  path": {
      fill: "none",
      stroke: (props: any) => props.colors.primary["7"],
    },
  },
  settingIconDisabled: {
    "& path": {
      fill: "none",
      stroke: (props: any) => props.colors.primary["4"],
    },
    fontSize: 28,
  },
  chipDiv: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
    "&:last-child": {
      ...(theme.direction === "rtl" ? { marginLeft: 0 } : { marginRight: 0 }),
    },
  },
  emptyDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  modalRoot: {
    height: "100%",
    maxWidth: 593,
    maxHeight: 475,
    // maxHeight: "100%",
    margin: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
      maxHeight: 627,
    },
  },
  modalImageRoot: {
    border: 0,
    maxHeight: "unset",
    height: "fit-content",
  },
  modalContentDiv: {
    padding: theme.spacing(0, 0, 4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(0, 14.625, 4),
    },
  },
  modalImageContenDiv: {
    position: "relative",
    padding: 0,
    // height: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      // padding: "17.5px 20px",
    },
  },
  // datePickerDiv: {
  //   padding: theme.spacing(0, 1, 2),
  //   borderBottom: `1px solid ${LightColors.primary["6"]}`,
  // },
  timeRadioDiv: {
    padding: theme.spacing(3),
  },
  filterSetChip: {
    backgroundColor: LightColors.primary["7"],
    color: LightColors.primary["0"],
    borderColor: LightColors.primary["7"],
  },
  dashcamFormLabel: {
    marginBottom: 0,
  },
  modalImageCont: {
    height: "100%",
    display: "flex",
    //@ts-ignore
    // eslint-disable-next-line no-dupe-keys
    display: "-webkit-flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  modalImageDiv: {
    position: "relative",
    // top: 0,
    // right: 0,
    // bottom: 0,
    // left: 0,
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // overflow: "hidden",
  },
  modalImageClose: {
    color: LightColors.primary["2"],
    top: 13,
    right: 13,
  },
  imageModalCamName: {
    marginBottom: 37.5,
    // mantis - 10567 x버튼과 겹치지 않도록 width수정(Leehj)
    width: "95%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 53,
      width: "100%",
    },
  },
  imageModalEventMsgDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 8,
  },
  imageModalEventDateDiv: {
    display: "flex",
    justifyContent: "center",
  },
  zoomInBtn: {
    borderBottom: `1px solid ${LightColors.primary["6"]}`,
    borderRadius: theme.spacing(1, 1, 0, 0),
    boxShadow: "none",
  },
  zoomOutBtn: {
    borderTop: 0,
    borderRadius: theme.spacing(0, 0, 1, 1),
    boxShadow: "none",
  },
  zoomControlBtn: {
    width: 28,
    height: 28,
    color: LightColors.primary["6"],
    minHeight: 28,
  },
  modalImageBtn: {
    borderRadius: 10,
    padding: "16px 39.75px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: "16px 82.25px",
    },
  },
  modalLiveViewBtn: {
    marginRight: 16,
    whiteSpace: "nowrap",
  },

  scrollIndicator: {
    width: 168,
    height: 115,
    position: "relative",
    // backgroundColor: "rgba(19, 19, 28, 0.45)",
    overflow: "hidden",
  },
  scrollIndicatorBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  scrollIndicatorHandle: {
    position: "absolute",
    border: "1px solid white",
    // boxShadow: "0 0 5px white",
    width: 64,
    height: 36,
    zIndex: 1,
    boxShadow: "0 0 0 9999px rgba(19, 19, 28, 0.45)",
  },
  scrollIndicatorWrapper: {
    position: "absolute",
    bottom: 16,
    right: 80,
    border: `3px solid ${LightColors.primary["1"]}`,
    boxShadow:
      "0px 0px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.14)",
  },
  noCamText: {
    marginTop: theme.spacing(1.125),
  },
  camAllCheckDiv: {
    flex: 1,
  },
  camAllCheckHide: {
    width: 0,
    overflow: "hidden",
  },
  notificationTextDiv: {
    boxSizing: "border-box",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Roboto",
    width: "100%",
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 421,
      padding: 0,
    },
  },
  notificationThumbTextDiv: {
    boxSizing: "border-box",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: 1.5,
    fontFamily: "Roboto",
    width: "100%",
    padding: theme.spacing(0, 8),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 326,
      padding: 0,
    },
  },
  modalTitle: {
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(2, 2, 2, 1.625) }
      : { padding: theme.spacing(2, 1.625, 2, 2) }),
    textOverflow: "ellipsis",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { padding: theme.spacing(2, 4, 2, 3.625) }
        : { padding: theme.spacing(2, 3.625, 2, 4) }),
    },
  },
  closeStyle: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      right: 28,
    },
  },

  settingTitleDiv: {
    display: "flex",
    alignItems: "center",
  },

  calendarBtnDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  eventFilterWrap: {
    width: "100vw",
    "&::-webkit-scrollbar": {
      display: "none",
      width: "unset",
    },
  },

  eventFilterDiv: {
    display: "flex",
    padding: "23px 16px 15px",
  },
  eventFilterChip: {
    display: "flex",
    alignItems: "center",
    backgroundColor: LightColors.primary["4"],
    borderRadius: 99,
    padding: "5.5px 16px 4px",
    width: "fit-content",
    marginRight: 8,
  },
  eventFilterRemoveIcon: {
    color: LightColors.primary["0"],
    fontSize: 22,
    cursor: "pointer",
  },
  notificationListItemWrap: {
    backgroundColor: (props: any) =>
      props.color === "dark" ? "#1E1E23" : "#FFFFFF",
    // borderLeft: (props: any) =>
    //   props.color === "dark" ? "1px solid #404046" : "none",
    // borderRight: (props: any) =>
    //   props.color === "dark" ? "1px solid #404046" : "none",
    borderWidth: "0 2px 0 2px",
    borderColor: (props: any) => props.colors.primary["6"],
    borderStyle: "solid",
  },
  notificationListItemFirst: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderTopWidth: 2,
    // borderColor: (props: any) => props.colors.primary["6"],
    // borderStyle: "solid",
  },
  notificationListItemLast: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    borderBottomWidth: 2,
  },
  rowRenderDiv: {
    padding: "0 16px",
  },
  measureRowDiv: {
    margin: "0 16px",
  },
  notiDivider: {
    margin: "0px 19px",
  },
  notiItemDivider: {
    margin: "0px 12px",
    backgroundColor: (props: any) =>
      props.color === "dark" ? "#404046" : "#E9E9EA",
  },
  fixedNotiDate: {
    top: 0,
    paddingBottom: 16,
    paddingTop: 24,
    // liveview, playback버튼 겹치는이슈 방지하기위해
    zIndex: 99,
    backgroundColor: (props: any) => props.colors.primary["0"],
  },
  // overflow: auto、overflow: hidden、overflow: scroll 가 적용된 부모가 있으면 sticky가 작동되지 않아서
  autoSizerOverflow: {
    "&>div>div>div>div": {
      overflow: "inherit !important",
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      "&>div,&>div>div ": {
        overflow: "inherit !important",
      },
    },
  },
  selectDaysNum: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 6,
    marginLeft: -5,
  },
  calendarBtn: {
    width: 151.83,
    height: 48,
    "&:last-child": {
      marginLeft: 11,
    },
    "&.MuiButton-outlined": {
      color: (props: any) => (props.colors === "dark" ? "#FFFFFF" : "#1E1E23"),
      backgroundColor: (props: any) =>
        props.color === "dark" ? "#323236" : "#FFFFFF",
      borderColor: (props: any) =>
        props.color === "dark" ? "#636367" : "#D4D4D5",
    },
  },
  dayFilterRoot: {
    padding: "8px 0px 24px",
    borderRadius: 12,
    color: (props: any) => props.colors.primary["1"],
  },
  dayFilterPaperRoot: {
    marginTop: -3,
    borderRadius: 12,
    boxShadow:
      "0px 6px 20px rgba(0, 0, 0, 0.05), 0px 3px 15px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.08)",
    color: (props: any) => props.colors.primary["1"],
    border: (props: any) =>
      props.color === "dark" ? "1px solid #505054" : "1px solid #D4D4D5",
    backgroundColor: (props: any) =>
      props.color === "dark" ? "#1E1E23" : "#FFFFFF",
  },
}));

const GEOFENCE_EVENTS = [
  "ALARM_GEOFENCE_ENTER",
  "ALARM_GEOFENCE_EXIT",
  "ALARM_GEOFENCE_PASS",
];

//  mantis - 10527 피그마 디자인 적용하여 export cvs 버튼 미노출로 해당부분 주석처리(Leehj)
// const EXCLUDE_EVENT: string[] = [
//   "ALARM_PARK_OUT",
//   "DEVICE_DISCONNECT",
//   "ALARM_SETTING_SAVED",
//   "ALARM_CALLING",
// ];

export interface NotificationPanelProps {
  open: boolean;
  onClose: () => void;
  currentCam?: ICameraInfo;
  app?: boolean;
}

export const NotificationSettingPanel = ({
  open,
  onClose,
  currentCam,
  app,
}: NotificationPanelProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const listUpdateKey = useRef(0);
  const filterDivRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List>(null);
  const moveXYRef = useRef({ x: 0, y: 0, count: 0 });

  const { direction, fontFamily, colors, color } = useSelector(
    (state: RootState) => state[THEME]
  );
  const classes = useStyles({ colors, color });
  const { cameraList } = useSelector((state: RootState) => state[CAMERA]);
  const { loading } = useSelector((state: RootState) => state[EVENT]);
  const [actives, setActives] = useState<{ psn: string; active: boolean }[]>(
    []
  );

  // const [msgCodes, setMsgCodes] = useState<string[]>(MSG_CODE_TYPE);
  // const [date, setDate] = useState(moment().startOf("d"));

  const [filterDateOption, setFilterDateOption] = useState(-1);
  const [filterMsgCodes, setFilterMsgCodes] = useState<string[]>(MSG_CODE_TYPE);
  const [openLiveModal, setOpenLiveModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openDayFilter, setOpenDayFilter] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openEventFilter, setOpenEventFilter] = useState(false);
  const [appliedDayFilter, setAppliedDayFilter] = useState(false);
  const [appliedEventFilter, setAppliedEventFilter] = useState(false);
  const [openExceedModal, setOpenExceedModal] = useState(false);
  const [camera, setCamera] = useState<ICameraInfo>();
  const [event, setEvent] = useState<IUXUILatestEvent>();
  const [imgScale, setImgScale] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [moveXY, setMoveXY] = useState({ x: 0, y: 0 });

  const [enabledDays, setEnabledDays] = useState<string[]>();

  // mantis - 12627, gmt시간 설정에 따라 7일 이상의 날짜가 선택되어있는 이슈 수정 (Leehj)
  // 오늘 날짜
  const today = useMemo(() => {
    const localTime = new Date();
    return new Date(
      new Date(
        localTime.getTime() + localTime.getTimezoneOffset() * 60000
      ).getTime() +
        (currentCam?.interval ?? 0) * 60 * 1000
    );
  }, [currentCam?.interval]);

  // 6일전 날짜 (7개 날짜 출력)
  const sixDayAgo = useMemo(() => {
    return new Date(new Date(today).setDate(new Date(today).getDate() - 6));
  }, [today]);

  const [date, setDate] = useState<RangeModifier>({
    from: sixDayAgo,
    to: today,
  });

  const [dateOption, setDateOption] = useState(-1);
  const [filterDate, setFilterDate] = useState<RangeModifier>({
    from: sixDayAgo,
    to: today,
  });

  // 처음 currentCam?.interval는 undefined이므로 currentCam?.interval값을 얻으면 한번더 setState (Leehj)
  useEffect(() => {
    if (currentCam?.interval) {
      setDate({
        from: sixDayAgo,
        to: today,
      });
      setFilterDate({
        from: sixDayAgo,
        to: today,
      });
    }
  }, [currentCam?.interval, sixDayAgo, today]);

  // 스와이프
  // const simpleBarRef = useRef<SimpleBarReact>(null);
  // const deltaX = useRef(0);

  // mantis - 7153, camList 초기값 All camera로.
  const [camList, setCamList] = useState<ICameraInfo[]>(
    _.map(cameraList?.deviceListInfo, (dev) => dev.device)
  );
  const [events, setEvents] = useState<{ [key: string]: IUXUILatestEvent[] }>(
    {}
  );

  const [apiEvents, setApiEvents] = useState<IUXUILatestEvent[]>();
  const [now, setNow] = useState(moment.tz());

  const [rowInfo, setRowInfo] = useState<RenderedRows>();

  // 10579 - 앱에서 호출 시, 다른 API호출때문에 이벤트 리스트 호출까지 시간이 걸려서
  // 잠시 Reset filter 메시지 출력되는 문제 수정
  const [eventsListLoading, setEventsListLoading] = useState(true);

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const { email, userProfile, permissions } = useSelector(
    (state: RootState) => state[USER]
  );

  const userUsage = useSelector((state: RootState) => state[USER].usageInfo);

  const location = useLocation();
  const pathnames = location.pathname;

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!openSetting) {
      //@ts-ignore
      window.backPressed = () => {
        if (openDayFilter) {
          return setOpenDayFilter(false);
        }
        if (openEventFilter) {
          return setOpenEventFilter(false);
        }
        sendMessageBack();
      };
    }
  }, [openDayFilter, openEventFilter, openSetting]);

  // 이벤트 필터 출력 가로 슬라이드
  // const handlers = useSwipeable({
  //   onSwipeStart: () => {
  //     deltaX.current = 0;
  //   },
  //   onSwiping: (eventData) => {
  //     if (!simpleBarRef.current) return;
  //     const scrollElement = simpleBarRef.current.getScrollElement();

  //     const left = scrollElement.scrollLeft;
  //     const dx = eventData.deltaX - deltaX.current;

  //     deltaX.current = eventData.deltaX;
  //     scrollElement.scrollTo({ left: left - dx });
  //   },
  //   onSwiped: () => {},
  //   trackMouse: true,
  // });

  useEffect(() => {
    dispatch(loadUserPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (currentCam) {
      dispatch(loadCamera(currentCam?.psn));
    }
  }, [currentCam, dispatch]);

  // mantis - 8828, pathnames이 바뀌면 VideoModal,LiveModal close (Leehj)
  useEffect(() => {
    setCamera(undefined);
    setEvent(undefined);
    setOpenVideoModal(false);
    setOpenLiveModal(false);
  }, [pathnames]);

  useEffect(() => {
    dispatch(loadUsageInfo());
  }, [dispatch]);

  useEffect(() => {
    const updateNow = () => {
      setNow(moment.tz());
    };
    const timer = setInterval(updateNow, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setCamList((cams) => {
      const pp = _.map(cameraList?.deviceListInfo, (dev) => dev.device.psn);
      const pp2 = _.map(cams, (cam) => cam.psn);
      console.log("found2", !_.isEqual(pp, pp2));
      if (!_.isEqual(pp, pp2)) {
        return _.map(cameraList?.deviceListInfo, (dev) => dev.device);
      } else {
        return cams;
      }
    });
    //deviceListInfo 불러올때마다 계속 업데이트됨
    setActives((active) =>
      _.map(cameraList?.deviceListInfo, (dev) => {
        const found = _.find(
          active,
          (act) =>
            act.psn === dev.device.psn &&
            act.active === (dev.device.active === "on" ? true : false)
        );
        if (found) {
          return found;
        }
        return {
          psn: dev.device.psn,
          active: dev.device.active === "on" ? true : false,
        };
      })
    );
  }, [cameraList?.deviceListInfo]);

  useEffect(() => {
    setAppliedEventFilter(filterMsgCodes.length !== MSG_CODE_TYPE.length);
  }, [filterMsgCodes]);

  useEffect(() => {
    setAppliedDayFilter(
      date.from?.getDate() !== moment().toDate().getDate() || dateOption !== -1
    );
  }, [date, dateOption]);

  useEffect(() => {
    setActives((a) => {
      const newActives = _.map(camList, (dev) => ({
        psn: dev.psn,
        active: dev.active === "on" ? true : false,
      }));
      if (
        _.difference(a, newActives).length === 0 &&
        a.length === newActives.length
      ) {
        return a;
      } else {
        return newActives;
      }
    });
  }, [camList]);

  const free100Check = useMemo(() => {
    if (subscriptionInfo && userUsage) {
      return isFree100Check(subscriptionInfo, userUsage);
    }
  }, [subscriptionInfo, userUsage]);

  // 35. alarm 날짜 목록 api
  useEffect(() => {
    const getNotificationDate = async () => {
      if (currentCam) {
        try {
          setEventsListLoading(true);
          const res = await jwtAxiosInst.get(
            `/Noti/AlarmDate?email=${email}&psn=${currentCam.psn}&interval=${currentCam.interval}`
          );

          const result = _.map(res.data.alarmDateList, (d) =>
            moment
              .utc(d * 1000)
              .add(currentCam.interval, "minutes")
              .format("YYYY-MM-DD")
          );

          console.log("NotificationSettingPanel", "result", result);

          setEnabledDays(result);

          // 시나리오 수정으로 삭제
          // // 히스토리 진입시 가장 최근 7일의 알림을 전달받아 표시한다.(날짜와 상관없이 데이터가 있는 일수를 계산)
          // // mantis - 11457, 가장 최근 발생한 알람이 7개 미만일 때 발생하는 이슈 수정 (Leehj)
          // setDate({
          //   from: moment(_.last(result.slice(0, 7))).toDate(),
          //   to: moment(_.first(result.slice(0, 7))).toDate(),
          // });
          // setFilterDate({
          //   from: moment(result[6]).toDate(),
          //   to: moment(result[0]).toDate(),
          // });
        } catch {
        } finally {
          // setEventsListLoading(false);
        }
      }
    };
    getNotificationDate();
  }, [currentCam, email]);

  // 필터 변경시 api계속 호출방지하기 위해 분리
  useEffect(() => {
    const getAPINotification = async () => {
      if (currentCam && open) {
        let startDate: Date;
        let endDate: Date;
        console.log(
          "NotificationSettingPanel",
          "getAPINotification",
          dateOption,
          date
        );

        if (dateOption === -1) {
          startDate = moment(date.from).startOf("d").toDate();
          endDate = moment(date.to).endOf("d").toDate();
        } else {
          startDate = moment(date.from)
            .startOf("d")
            .set("hour", dateOption)
            .toDate();
          endDate = moment(date.to).endOf("h").toDate();
        }
        // alarm List 요청api호출
        try {
          setEventsListLoading(true);
          const lang = i18n.language;
          const res = await jwtAxiosInst.get(
            `/Noti/AlarmInfo?email=${email}&psn=${currentCam.psn}&interval=${
              currentCam.interval
            }&startDT=${moment(startDate).format("YYYYMMDD")}&endDT=${moment(
              endDate
            ).format("YYYYMMDD")}&lang=${MobileLang[lang]}`
          );

          setApiEvents(res.data.alarmList as IUXUILatestEvent[]);
        } catch {
        } finally {
          setEventsListLoading(false);
        }
      }
    };
    getAPINotification();
  }, [
    currentCam,
    date,
    date.from,
    date.to,
    dateOption,
    email,
    i18n.language,
    open,
  ]);

  useEffect(() => {
    if (open) {
      const geofenceEvents = _.filter(filterMsgCodes, (code) =>
        _.includes(GEOFENCE_EVENTS, code)
      );
      let evMsgCodes = _.filter(
        filterMsgCodes,
        (code) => !_.includes(GEOFENCE_EVENTS, code)
      );
      if (geofenceEvents.length > 0) {
        evMsgCodes = [...evMsgCodes, "ALARM_GEOFENCE"];
      }

      try {
        listUpdateKey.current = listUpdateKey.current + 1;
        setEvents(
          _.chain(apiEvents)
            .filter((f: IUXUILatestEvent) =>
              _.includes(evMsgCodes, f.alarm_type)
            )
            .map((v) => ({
              ...v,
              cdate: moment(v.cdate).add(currentCam?.interval ?? 0, "minutes"),
              originalCdate: moment(v.cdate),
            }))
            // 10556 - 날짜 그룹핑 잘못되는 문제 수정
            .groupBy((f: IUXUILatestEvent) =>
              f.cdate?.utc(false).format("YYYYMMDD")
            )
            .reduce(
              (result, values, key) => ({
                ...result,
                [key]: values,
              }),
              {} as { [key: string]: IUXUILatestEvent[] }
            )
            .value()
        );
      } catch {}
    }
  }, [apiEvents, currentCam?.interval, filterMsgCodes, open]);

  const onLiveView = useCallback(
    (evt) => {
      if (currentCam) {
        const cam = _.find(
          cameraList?.deviceListInfo,
          (dev) => dev.device.psn === currentCam.psn
        )?.device;
        if (cam) {
          if (app) {
            const browser = detect();

            const msg = {
              psn: cam.psn,
              dev_name: cam.dev_name,
              cdate: evt.originalCdate
                ?.utc(false)
                .format("YYYY-MM-DD HH:mm:ss"),
              image_url: evt.img_url,
              "image-url": evt.img_url,
              event_filename: evt.event_file,
              message: evt.msg,
              msg_code: evt.alarm_type,
              model: cam.model,
              fw_ver: cam.fw_ver,
            };
            if (browser?.name === "ios-webview") {
              //@ts-ignore
              return webkit.messageHandlers.openLive.postMessage(msg);
            }
            if (browser?.name === "chromium-webview") {
              //@ts-ignore
              window.Webviewer?.openLive(JSON.stringify(msg));
            }
          } else {
            dispatch(finishLiveview());
            setCamera(cam);
            setEvent(evt);
            setOpenLiveModal(true);
          }
        }
      }
    },
    [app, cameraList?.deviceListInfo, currentCam, dispatch]
  );

  const onPlay = useCallback(
    (evt) => {
      if (currentCam) {
        const cam = _.find(
          cameraList?.deviceListInfo,
          (dev) => dev.device.psn === currentCam.psn
        )?.device;

        if (cam) {
          if (app) {
            const browser = detect();
            const msg = {
              psn: cam.psn,
              dev_name: cam.dev_name,
              cdate: evt.originalCdate
                ?.utc(false)
                .format("YYYY-MM-DD HH:mm:ss"),
              image_url: evt.img_url,
              "image-url": evt.img_url,
              event_filename: evt.event_file,
              message: evt.msg,
              msg_code: evt.alarm_type,
              model: cam.model,
              fw_ver: cam.fw_ver,
            };
            console.log("msg", msg);
            if (browser?.name === "ios-webview") {
              //@ts-ignore
              return webkit.messageHandlers.openVideo.postMessage(msg);
            }
            if (browser?.name === "chromium-webview") {
              //@ts-ignore
              window.Webviewer?.openVideo(JSON.stringify(msg));
            }
          } else {
            if (free100Check) {
              setOpenExceedModal(true);
            } else {
              setCamera(cam);
              setEvent(evt);
              setOpenVideoModal(true);
            }
          }
        }
      }
    },
    [app, cameraList?.deviceListInfo, currentCam, free100Check]
  );

  const openImage = useCallback(
    (evt: IUXUILatestEvent) => {
      if (currentCam) {
        const cam = _.find(
          cameraList?.deviceListInfo,
          (dev) => dev.device.psn === currentCam.psn
        )?.device;

        if (cam) {
          if (app) {
            const browser = detect();

            const msg = {
              psn: cam.psn,
              dev_name: cam.dev_name,
              cdate: evt.originalCdate
                ?.utc(false)
                .format("YYYY-MM-DD HH:mm:ss"),
              image_url: evt.img_url,
              "image-url": evt.img_url,
              event_filename: evt.event_file,
              message: evt.msg,
              msg_code: evt.alarm_type,
              model: cam.model,
              fw_ver: cam.fw_ver,
            };
            if (browser?.name === "ios-webview") {
              //@ts-ignore
              return webkit.messageHandlers.openNotification.postMessage(msg);
            }
            if (browser?.name === "chromium-webview") {
              //@ts-ignore
              window.Webviewer?.openNotification(JSON.stringify(msg));
            }
          } else {
            setEvent(evt);
            setOpenImageModal(true);
          }
        }
      }
    },
    [app, cameraList?.deviceListInfo, currentCam]
  );

  const openFailModal = useCallback(() => {}, []);

  const rowData = useMemo(() => {
    const keys = _.keys(events).sort().reverse();
    return _.chain(keys)
      .map((key) => {
        const evts = events[key];
        evts[0].first = true;
        evts[evts.length - 1].last = true;
        return [key, ...evts, "divider"];
      })
      .flattenDeep()
      .value();
  }, [events]);

  const rowRenderer: ListRowRenderer = useCallback(
    (props) => {
      const evt = rowData[props.index];
      if (typeof evt === "string") {
        if (evt === "divider") {
          return (
            <div
              key={props.key}
              style={{ ...props.style, padding: 0 }}
              className={classes.rowRenderDiv}
            >
              <Divider
                className={classes.notiItemDivider}
                style={{ margin: "25px 0" }}
              />
            </div>
          );
        }
        return (
          <div key={props.key} style={props.style}>
            {/* 디자인 수정요청건 반영 - Noti 리스트에서 다음 Date 가 올때까지 상단고정. (Leehj) */}
            <div className={classes.fixedNotiDate}>
              <Typography
                category="Default"
                variant="H6"
                style={{ marginLeft: 32, height: 52 }}
              >
                {moment(evt).format("YYYY-MM-DD")}
              </Typography>
            </div>
          </div>
        );
      }
      const active = _.find(actives, (a) => a.psn === currentCam?.psn)?.active;
      const activePlay =
        // now.diff(evt.cdate?.utc(false), "s") > 90 &&
        now.diff(evt.originalCdate?.utc(false), "s") > 90 &&
        !_.includes(["ALARM_DETECTED", "ALARM_MASK_OFF"], evt.alarm_type);
      return (
        <div
          key={props.key}
          style={props.style}
          className={classes.rowRenderDiv}
        >
          <div
            className={clsx(classes.notificationListItemWrap, {
              [classes.notificationListItemFirst]: evt.first,
              [classes.notificationListItemLast]: evt.last,
            })}
          >
            <UXUINotificationListItem
              key={evt._id}
              event={evt}
              utcOffset={0}
              disableLiveview={!active}
              disablePlaback={!active || !activePlay}
              onLiveView={active ? onLiveView : openFailModal}
              onPlay={active ? onPlay : openFailModal}
              onImage={openImage}
              darkMode={color === "dark"}
            />
            {/* 마지막 이벤트 아이템에는 구분선을 출력안하도록 조건추가 */}
            {!evt.last && (
              <Divider
                className={classes.notiItemDivider}
                style={{ marginTop: "-1px" }}
              />
            )}
          </div>
        </div>
      );
    },
    [
      actives,
      classes.fixedNotiDate,
      classes.notiItemDivider,
      classes.notificationListItemFirst,
      classes.notificationListItemLast,
      classes.notificationListItemWrap,
      classes.rowRenderDiv,
      currentCam?.psn,
      now,
      onLiveView,
      onPlay,
      openFailModal,
      openImage,
      rowData,
      color,
    ]
  );

  const rowHeight = useCallback(
    (indx: Index) => {
      const evt = rowData[indx.index];
      if (typeof evt === "string") {
        if (evt === "divider") {
          return 26;
        }
        return 62;
      }

      const msgTypo = ReactDOMServer.renderToString(
        <ThemeProvider
          theme={TWTheme({
            fontFamily,
            dir: direction,
            Colors: LightColors,
            breakpoints: {
              values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
            },
          })}
        >
          <div style={{ maxWidth: 460 }}>
            <UXUINotificationListItem
              key={evt._id}
              event={evt}
              utcOffset={0}
              // disableLiveview={!active}
              // disablePlaback={!active || !activePlay}
              // onLiveView={active ? onLiveView : openFailModal}
              // onPlay={active ? onPlay : openFailModal}
            />
          </div>
        </ThemeProvider>
      );

      const height = getTextHeight(msgTypo, [classes.measureRowDiv]);
      // return color === "dark" ? height + 1 : height;
      return height + (evt.first || evt.last ? 2 : 0);
    },
    [classes.measureRowDiv, direction, fontFamily, rowData]
  );

  const handleScroll = useCallback((e) => {
    if (listRef.current) {
      const { scrollTop, scrollLeft } = e.target;
      const { Grid } = listRef.current;
      Grid?.handleScrollEvent({ scrollTop, scrollLeft });
    }
  }, []);

  // 달력필터에서 선택한 날짜 배열출력 함수
  const getDaysBetweenDates = useCallback(
    (startDate: Moment, endDate: Moment) => {
      const now = startDate.clone();
      const dates = [];

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format("YYYY-MM-DD"));
        now.add(1, "days");
      }
      return dates;
    },
    []
  );

  // 달력필터에서 선택한 날짜의 수
  const selectDaysLength = useMemo(() => {
    return _.intersection(
      enabledDays,
      getDaysBetweenDates(moment(filterDate.from), moment(filterDate.to))
    ).length;
  }, [enabledDays, filterDate, getDaysBetweenDates]);
  // console.log("NotificationSettingPanel", "selectDaysLength", selectDaysLength);

  const calendarMarkup = useMemo(() => {
    return (
      <div dir={theme.direction} style={{ width: 343 }}>
        <div>
          <DateRangePicker
            variant="range"
            range={filterDate}
            // 날짜 disable 시키는건 아직 API가 없어 일단 임의로 적용
            enabledDays={enabledDays}
            onChangeDay={(range) =>
              setFilterDate(range.range ?? { from: null, to: null })
            }
            // disableAfterToday={disableAfterToday}
            // disableDayBefore={disableDayBefore}
            t={t}
            notification
            darkMode={color === "dark"}
          />
        </div>
        <div className={classes.selectDaysNum}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={
              selectDaysLength > 7
                ? colors.secondary["11"]
                : colors.primary["1"]
            }
          >
            {selectDaysLength}
            /7
          </Typography>
        </div>
        <div className={classes.calendarBtnDiv}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.calendarBtn}
            disabled={
              filterDate.from?.getDate() ===
                moment(enabledDays && enabledDays[6])
                  .toDate()
                  .getDate() &&
              filterDate.to?.getDate() ===
                moment(enabledDays && enabledDays[0])
                  .toDate()
                  .getDate() &&
              filterDateOption === -1
            }
            onClick={() => {
              // setFilterDate(moment());

              setFilterDate({
                from: sixDayAgo,
                to: today,
              });

              setFilterDateOption(-1);
            }}
          >
            {t?.("Reset") ?? "Reset"}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.calendarBtn}
            onClick={() => {
              setDate(filterDate);
              setDateOption(filterDateOption);
              setOpenDayFilter(false);
            }}
            // 선택한 날짜범위가 7일이 넘으면 일단 버튼 비활성화 처리
            disabled={selectDaysLength > 7}
          >
            {t?.("OK") ?? "OK"}
          </Button>
        </div>
      </div>
    );
  }, [
    classes.calendarBtn,
    classes.calendarBtnDiv,
    classes.selectDaysNum,
    enabledDays,
    filterDate,
    filterDateOption,
    selectDaysLength,
    sixDayAgo,
    t,
    theme.direction,
    today,
    color,
    colors,
  ]);

  const notificationsMarkup = useMemo(() => {
    if (loading || eventsListLoading) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    } else if (rowData.length > 0) {
      if (filterDivRef.current) {
        const dateString = _.chain(rowData)
          .slice(0, (rowInfo?.startIndex ?? 0) + 1)
          .findLast((r) => typeof r === "string")
          .value() as string | undefined;
        const currentRow = rowData[rowInfo?.startIndex ?? -1];

        const visibleHeader =
          currentRow !== "divider" && dateString && !openSetting;

        // console.log(
        //   "NotificationSettingPanel",
        //   "dateString",
        //   dateString,
        //   "rowInfo",
        //   rowInfo,
        //   "currentRow",
        //   currentRow
        // );

        return (
          <AutoSizer>
            {({ height, width }) =>
              mobile ? (
                <>
                  {visibleHeader && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        width: width - 14,
                        backgroundColor:
                          color === "dark" ? "#121216" : "#E8E8E8",
                        zIndex: 99,
                      }}
                    >
                      <div className={classes.fixedNotiDate}>
                        <Typography
                          category="Default"
                          variant="H6"
                          style={{ marginLeft: 32, height: 52 }}
                        >
                          {moment(dateString).format("YYYY-MM-DD")}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <List
                    key={`list-${listUpdateKey.current}`}
                    width={width}
                    height={height}
                    rowHeight={rowHeight}
                    overscanRowCount={10}
                    rowCount={rowData.length}
                    rowRenderer={rowRenderer}
                    ref={listRef}
                    onRowsRendered={setRowInfo}
                  />
                </>
              ) : (
                <div style={{ height, width }}>
                  {visibleHeader && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        width: width - 9,
                        backgroundColor:
                          color === "dark" ? "#121216" : "#E8E8E8",
                        zIndex: 99,
                      }}
                    >
                      <div className={classes.fixedNotiDate}>
                        <Typography
                          category="Default"
                          variant="H6"
                          style={{ marginLeft: 32, height: 52 }}
                        >
                          {moment(dateString).format("YYYY-MM-DD")}
                        </Typography>
                      </div>
                    </div>
                  )}
                  <Scrollbars
                    onScroll={handleScroll}
                    style={{ height, width }}
                    autoHide
                  >
                    <List
                      key={`list-${listUpdateKey.current}`}
                      width={width}
                      height={height}
                      rowHeight={rowHeight}
                      overscanRowCount={10}
                      rowCount={rowData.length}
                      rowRenderer={rowRenderer}
                      style={{ overflowY: "visible", overflowX: "visible" }}
                      ref={listRef}
                      onRowsRendered={setRowInfo}
                    />
                  </Scrollbars>
                </div>
              )
            }
          </AutoSizer>
        );
      } else {
        return <></>;
      }
    } else if (appliedDayFilter || appliedEventFilter) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              marginTop: mobile ? 154 : 296,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={LightColors.primary["2"]}
            >
              {t("No results")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              style={{ marginBottom: 24 }}
            >
              {t("Try changing your_")}
            </Typography>
            <Button
              style={{ borderRadius: 12, width: mobile ? 195 : 173 }}
              variant="contained"
              color="primary"
              onClick={() => {
                setDate({
                  from: sixDayAgo,
                  to: today,
                });
                setFilterDate({
                  from: sixDayAgo,
                  to: today,
                });

                setDateOption(-1);
                setFilterMsgCodes(MSG_CODE_TYPE);

                setFilterDateOption(-1);
                setFilterMsgCodes(MSG_CODE_TYPE);
              }}
            >
              {t("Remove all filters")}
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.emptyDiv}>
          <EmptyItemsUXUI
            variant="small"
            mode="notifications"
            noCamTextClassName={clsx(mobile && classes.noCamText)}
          />
        </div>
      );
    }
  }, [
    loading,
    eventsListLoading,
    rowData,
    appliedDayFilter,
    appliedEventFilter,
    rowInfo?.startIndex,
    openSetting,
    mobile,
    classes.fixedNotiDate,
    classes.emptyDiv,
    classes.noCamText,
    rowHeight,
    rowRenderer,
    handleScroll,
    t,
    sixDayAgo,
    today,
    color,
  ]);

  const imageModalContentMarkup = useMemo(() => {
    if (event && currentCam) {
      const cam = _.find(camList, (dev) => dev.psn === currentCam.psn);
      // eslint-disable-next-line no-control-regex
      const regex = new RegExp(/(\[[\u0000-\uFFFF]+\])?([\u0000-\uFFFF]+)/);
      const activePlay =
        // mantis - 10879, notification history 리스트와 조건 동일하게 수정(Leehj)
        now.diff(event.originalCdate?.utc(false), "s") > 90 &&
        !_.includes(["ALARM_DETECTED", "ALARM_MASK_OFF"], event.alarm_type);

      const modalStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };

      return (
        <div className={classes.modalImageCont}>
          <div style={{ padding: "17.5px 20px" }}>
            <div className={classes.imageModalCamName}>
              <Typography
                category="Default"
                variant="H6"
                htmlColor={LightColors.primary["2"]}
              >
                {cam?.dev_name}
              </Typography>
            </div>
            <div className={classes.imageModalEventMsgDiv}>
              <Typography category="Default" variant="H5">
                {_.last(event.msg.match(regex))}
              </Typography>
            </div>
            <div className={classes.imageModalEventDateDiv}>
              <Typography
                category="Default"
                variant="Body"
                style={{ marginRight: 8 }}
              >
                {event.cdate?.format("YYYY-MM-DD")}
              </Typography>
              <Typography category="Default" variant="Body">
                {event.cdate?.format("h:mm:ss A")}
              </Typography>
            </div>
          </div>
          <div className={clsx(classes.modalImageDiv)}>
            <div
              style={{
                width: "100%",
                // height: mobile ? 200 : 322,
                paddingTop: "62.5%",
                overflow: "hidden",
                position: "relative",
                backgroundColor: LightColors.primary["1"],
                ...modalStyle,
              }}
              onMouseMove={(e) => {
                if (dragging && imgScale > 1) {
                  // console.log("onMouseMove", e.movementX, e.movementY);
                  const moveRef = moveXYRef.current;
                  moveXYRef.current = {
                    x: moveRef.x + e.movementX,
                    y: moveRef.y + e.movementY,
                    count: moveRef.count + 1,
                  };
                  if (moveRef.count % 10 === 0) {
                    setMoveXY((move) => ({
                      x: moveRef.x + e.movementX,
                      y: moveRef.y + e.movementY,
                    }));
                  }
                }
              }}
              onMouseUp={(e) => {
                setDragging(false);
              }}
              onMouseLeave={(e) => {
                setDragging(false);
              }}
            >
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img
                src={event.img_url}
                style={{
                  width: mobile ? "calc(100% - 8px)" : "calc(100% - 16px)",
                  height: mobile ? "calc(100% - 8px)" : "calc(100% - 16px)",
                  transform: `translate(${moveXY.x}px, ${moveXY.y}px)scale(${imgScale})`,
                  userSelect: "none",
                  cursor: "grab",
                  position: "absolute",
                  top: mobile ? 4 : 8,
                }}
                onDragStart={(e) => {
                  e.preventDefault();
                  setDragging(true);
                }}
                alt="Event Image"
              />

              {!mobile && (
                <div style={{ position: "absolute", right: 20, bottom: 27 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: 8,
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Tooltip
                      disableTouchListener={mobile}
                      title={t("Zoom in") ?? "Zoom in"}
                    >
                      <Fab
                        size="small"
                        variant="rounded"
                        className={clsx(
                          classes.zoomControlBtn,
                          classes.zoomInBtn
                        )}
                        onClick={() => {
                          setImgScale((s) => Math.min(s + 0.1, 2.5));
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Fab>
                    </Tooltip>
                    <Tooltip
                      disableTouchListener={mobile}
                      title={t("Zoom out") ?? "Zoom out"}
                    >
                      <Fab
                        size="small"
                        variant="rounded"
                        className={clsx(
                          classes.zoomControlBtn,
                          classes.zoomOutBtn
                        )}
                        onClick={() => {
                          setImgScale((s) => {
                            const scale = Math.max(s - 0.1, 0.3);
                            if (scale <= 1) {
                              setMoveXY({ x: 0, y: 0 });
                              moveXYRef.current = { x: 0, y: 0, count: 0 };
                            }
                            return scale;
                          });
                        }}
                      >
                        <RemoveIcon fontSize="small" />
                      </Fab>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: mobile ? "24px 0" : "32px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                color="primary"
                className={clsx(
                  classes.modalImageBtn,
                  classes.modalLiveViewBtn
                )}
                disabled={cam?.active !== "on"}
                onClick={() => onLiveView(event)}
              >
                {t("Live View")}
              </Button>
              <Button
                color="primary"
                className={classes.modalImageBtn}
                disabled={cam?.active !== "on" || !activePlay}
                onClick={() => {
                  onPlay(event);
                  setOpenImageModal(false);
                }}
              >
                {t("Playback")}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return <div></div>;
  }, [
    camList,
    classes.imageModalCamName,
    classes.imageModalEventDateDiv,
    classes.imageModalEventMsgDiv,
    classes.modalImageBtn,
    classes.modalImageCont,
    classes.modalImageDiv,
    classes.modalLiveViewBtn,
    classes.zoomControlBtn,
    classes.zoomInBtn,
    classes.zoomOutBtn,
    currentCam,
    dragging,
    event,
    imgScale,
    mobile,
    moveXY.x,
    moveXY.y,
    now,
    onLiveView,
    onPlay,
    t,
  ]);

  const liveModalMarkup = useMemo(() => {
    let dir: Direction = Front;
    if (_.includes(DMS_EVENTS, event?.alarm_type)) {
      if (_.includes(MODELS_2CH, camera?.model)) {
        dir = Rear;
      } else {
        dir = Interior;
      }
    }

    return (
      // 변경된 디자인 적용
      <LiveViewMapboxModal
        open={openLiveModal}
        camera={camera}
        //8868 - DMC 이벤트의 경우 Interior영상 노출되도록 수정
        dir={dir}
        notification
        noMargin
        mobile={mobile}
        onClose={() => {
          setOpenLiveModal(false);
          setCamera(undefined);
          dispatch(startLiveView());
        }}
      />
    );
  }, [camera, dispatch, event?.alarm_type, mobile, openLiveModal]);

  return (
    <>
      <Drawer
        anchor={theme.direction === "rtl" ? "left" : "right"}
        open={open}
        transitionDuration={app ? 0 : undefined}
      >
        <div className={classes.filterDiv} dir={theme.direction}>
          <div className={classes.filterTitleDiv}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {app && (
                <IconButton onClick={() => sendMessageBack()}>
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              <Typography category="Default" variant="H6">
                {t("Notifications")}
              </Typography>
            </div>

            {/* Driver : 권한없음 / 설정 접근 비활성화 처리 , Admin : 권한목록 “Notification Settings” 권한의 설정에 따라 알림설정 활성화/비활성화 처리
             */}
            <div>
              <IconButton
                onClick={() => setOpenSetting(true)}
                disabled={
                  userProfile?.userType === "User" ||
                  (userProfile?.userType === "SubMaster" &&
                    !permissions?.pushNotifications) ||
                  cameraList?.deviceListInfo?.length === 0
                }
              >
                <SettingsIcon
                  className={clsx(classes.settingIcon, {
                    [classes.settingIconDisabled]:
                      userProfile?.userType === "User" ||
                      (userProfile?.userType === "SubMaster" &&
                        !permissions?.pushNotifications) ||
                      cameraList?.deviceListInfo?.length === 0,
                  })}
                />
              </IconButton>
              {!app && (
                <Tooltip
                  disableTouchListener={mobile}
                  title={
                    <Typography category="Default" variant="Caption">
                      {t("Close")}
                    </Typography>
                  }
                  placement="bottom"
                >
                  <IconButton
                    // mantis - 10527 피그마 디자인 적용하여 export cvs 버튼 미노출 적용(Leehj)
                    className={classes.closeBtn}
                    onClick={() => {
                      onClose();
                      setOpenSetting(false);
                      setFilterMsgCodes(MSG_CODE_TYPE);
                      setDate({
                        from: sixDayAgo,
                        to: today,
                      });
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>

          {/* 카메라 정보 */}
          {/* 디자인 수정요청건 반영 - 세팅이 진입하면 카메라의 이름과 달력, 필터는 출력되지 않게 수정(Leehj) */}
          {!openSetting && (
            <div className={classes.camInfoWrap}>
              <div className={classes.camInfoDiv}>
                {currentCam && getCloudConnectivityIcon(currentCam)}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 8,
                    // mantis - 10565, 모바일인 경우 카메라 이름 말줄임 처리되지 않는 이슈 수정(Leehj)
                    // width: mobile ? "90%" : 285,
                    // 10293 말줄임 처리 다시 수정 (mckim)
                    minWidth: 0,
                  }}
                >
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    className={classes.camNameText}
                  >
                    {currentCam?.dev_name}
                  </Typography>
                  <Typography
                    category="Default"
                    variant="Caption"
                    style={{ fontSize: 10, marginTop: -2 }}
                  >
                    {currentCam?.model}
                  </Typography>
                </div>
              </div>

              <div className={classes.camInfoIconDiv}>
                <IconButton
                  ref={anchorRef}
                  className={classes.searchDateIcon}
                  onClick={() => {
                    setOpenDayFilter((o) => !o);
                    setFilterDate(date);
                  }}
                >
                  <SearchDateIcon />
                </IconButton>
                <IconButton
                  className={classes.filterIcon}
                  onClick={() => setOpenEventFilter(true)}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FilterListIcon />
                    {/* mantis - 10553, 필터변경시 오른쪽 상단 파란점 UI 추가 (Leehj) */}
                    {filterMsgCodes.length !== MSG_CODE_TYPE.length && (
                      <div
                        style={{
                          width: 6,
                          height: 6,
                          borderRadius: 3,
                          backgroundColor: LightColors.primary["7"],
                          position: "absolute",
                          top: -3,
                          right: -3,
                        }}
                      />
                    )}
                  </div>
                </IconButton>
              </div>
            </div>
          )}

          <div className={clsx(classes.listContDiv)}>
            <div ref={filterDivRef} className={classes.filterListDiv}>
              {notificationsMarkup}
            </div>
          </div>

          {openDayFilter && (
            <Menu
              open={openDayFilter}
              classes={{ root: classes.dayFilterRoot }}
              paperClasses={{ root: classes.dayFilterPaperRoot }}
              onClickAway={() => {
                setOpenDayFilter(false);
                // mantis-10600, 날짜 변경한 뒤 ok버튼을 누르지 않으면 이전에 선택했던 날짜로 출력 (Leehj)
                setFilterDate(date);
              }}
              anchorEl={anchorRef.current}
              placement="bottom-end"
              modifiers={{
                offset: {
                  enabled: true,
                  offset: "-13px, 0px",
                },
              }}
            >
              {calendarMarkup}
            </Menu>
          )}

          {/* setting 화면 */}
          <div
            className={clsx(classes.listContDiv, classes.settingListContDiv, {
              [classes.dayFilterClose]: !openSetting,
              [classes.top0]: openSetting && mobile,
            })}
          >
            <NotificationSetting
              currentCam={currentCam}
              app={app}
              onClose={() => {
                setOpenSetting(false);
              }}
              open={openSetting}
              // mantis - 12123, 650GW 대시캠 FW ver. 2.0이상 Push Notifications 미 노출을 위한 props추가 (Leehj)
              camera={camera}
            />
          </div>
        </div>
      </Drawer>
      {openVideoModal && camera && event && (
        // videoplayer Modal UI 변경 (Leehj)
        <VideoPlayerMapboxUXUIModal
          open={openVideoModal}
          mobile={mobile}
          camera={camera}
          mode={0}
          event={event}
          notification
          onClose={() => {
            setOpenVideoModal(false);
            setCamera(undefined);
            setEvent(undefined);
          }}
        />
        // <VideoPlayerUXUIModal
        //   open={openVideoModal}
        //   camera={camera}
        //   mode={0}
        //   event={event}
        //   notification
        //   onClose={() => {
        //     setOpenVideoModal(false);
        //     setCamera(undefined);
        //     setEvent(undefined);
        //   }}
        // />
      )}
      {liveModalMarkup}
      {openImageModal && event && (
        <Modal
          className={clsx(classes.modalRoot, classes.modalImageRoot)}
          contentClassName={classes.modalImageContenDiv}
          open={openImageModal}
          closeStyle={classes.modalImageClose}
          onClose={() => {
            setOpenImageModal(false);
            setEvent(undefined);
            setImgScale(1);
            setMoveXY({ x: 0, y: 0 });
            moveXYRef.current = { x: 0, y: 0, count: 0 };
          }}
          content={imageModalContentMarkup}
          close
        />
      )}
      <ExceedModal
        open={openExceedModal}
        onClose={() => setOpenExceedModal(false)}
        onClickPositive={() => {
          setOpenExceedModal(false);
        }}
      />

      <NotificationEventFilterModal
        currentCam={currentCam}
        open={openEventFilter}
        onClose={() => {
          setOpenEventFilter(false);
        }}
        filterMsgCodes={filterMsgCodes}
        onFilterMsgCodes={(codes) => {
          setFilterMsgCodes(
            _.sortBy(codes, (code) =>
              _.findIndex(MSG_CODE_TYPE, (x) => x === code)
            )
          );
          setOpenEventFilter(false);
        }}
        darkMode={color === "dark"}
      />
    </>
  );
};
