import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { Typography } from "@thingsw/pitta-design-system";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import moment from "moment";
import clsx from "clsx";
import {
  Webviewer,
  LightColors,
  getPlanFromServiceID,
} from "@thingsw/pitta-modules";
import { PAYMENT } from "../../features/Payment/slice";
import { USER, loadUsageInfo } from "../../features/User/slice";
import { RootState } from "../../features/store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    overflowY: "auto",
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(3, 0),
    },
  },
  boxGroupDiv: {
    display: "flex",
    // justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  boxDiv: {
    display: "flex",
    //justifyContent: "center",
    flexDirection: "column",
    width: 288,
    height: 230,
    backgroundColor: LightColors.primary["0"],
    border: `1px solid #D4D4D5`,
    boxSizing: `border-box`,
    borderRadius: 4,
    margin: theme.spacing(0, 0, 2),
    paddingTop: theme.spacing(2),
    "&:last-child": {
      height: 206,
      marginBottom: 0,
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: 359,
      height: 250,
      alignItems: "baseline",

      ...(theme.direction === "rtl"
        ? {
            margin: theme.spacing(0, 0, 2, 4),
            padding: theme.spacing(3, 4, 0, 0),
          }
        : {
            margin: theme.spacing(0, 4, 2, 0),
            padding: theme.spacing(3, 0, 0, 4),
          }),

      "&:last-child": {
        height: 250,
        marginBottom: theme.spacing(2),
        ...(theme.direction === "rtl"
          ? { paddingRight: 0 }
          : { paddingLeft: 0 }),
      },
    },
    [theme.breakpoints.up("lg")]: {
      "&:last-child": {
        ...(theme.direction === "rtl" ? { marginLeft: 0 } : { marginRight: 0 }),
      },
    },
  },
  textDiv: {
    display: "flex",
    flexDirection: "column",
    // width: 240,
  },
  textDivMargin: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(4) }
        : { marginLeft: theme.spacing(4) }),
    },
  },
  marginText: {
    lineHeight: "21px",
  },
  noteDiv: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      alignItems: "baseline",
    },
  },
  // 박스 내부
  usageDiv: {
    display: "flex",
    width: 240,
    alignItems: "baseline",
    margin: theme.spacing(3, 0, 4),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(2.5, 0, 4.5, 0),
    },
  },
  // 마지막 박스 내부
  spaceDiv: {
    display: "flex",
    width: 240,
    alignItems: "baseline",
    margin: theme.spacing(1, 0, 2.875),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: theme.spacing(2.375, 0, 4.5, 0),
      ...(theme.direction === "rtl"
        ? { marginRight: theme.spacing(4) - 2 }
        : { marginLeft: theme.spacing(4) - 2 }),
    },
  },
  spaceDivText: {
    fontSize: "46px !important",
    lineHeight: "53.91px !important",
    "&:last-child": {
      fontSize: "30px !important",
      lineHeight: "35.16px !important",
    },
    [theme.breakpoints.up(Webviewer.mobile)]: {
      fontSize: "56px !important",
      lineHeight: "65.63px !important",
      "&:last-child": {
        fontSize: "40px !important",
        lineHeight: "46.88px !important",
      },
    },
  },
  // 마지막 박스 하단 graph
  graphDiv: {
    display: "flex",
    width: "100%",
    height: 55,
    position: "relative",
  },
  usageGradient: {
    display: "flex",
    position: "absolute",
    height: 53,
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #D0EBF9 100%)`,
    maxWidth: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 359,
    },
  },
  usageLimitGradient: {
    background: `linear-gradient(180deg, rgba(255, 88, 88, 0) 0%, rgba(227, 175, 184, 0.59375) 99.99%, #D0EBF9 100%);
    `,
  },

  usage: {
    position: "absolute",
    top: "19px",
    left: "16px",
  },
  totalStorage: {
    position: "absolute",
    top: "19px",
    right: "16px",
  },
  gradientUnderline: {
    position: "absolute",
    height: 3,
    top: "52px",
    background: LightColors.primary[7],
    borderRadius: "0px 0px 0px 3px",
    maxWidth: 288,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 359,
    },
  },
  gradientLimitUnderline: {
    background: LightColors.secondary[11],
  },
  explainTypo: {
    lineHeight: "21px",
    color: LightColors.primary[2],
  },
  arrowIcon: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(2) }
      : { marginLeft: theme.spacing(2) }),
  },
  MgL24: {
    [theme.breakpoints.down(Webviewer.mobile)]: {
      marginLeft: theme.spacing(3),
    },
  },
  unlimitedText: {
    fontSize: "2.8rem !important",
    lineHeight: "3.8rem !important",
    "&.fontSmall": {
      fontSize: "2rem !important",
      lineHeight: "4rem !important",
    },
    [theme.breakpoints.down(Webviewer.mobile)]: {
      fontSize: "2.6rem !important",
      lineHeight: "3.6rem !important",
      "&.fontSmall": {
        fontSize: "1.5rem !important",
        lineHeight: "4rem !important",
      },
    },
    ...(theme.direction === "rtl" ? { marginRight: 14 } : { marginLeft: 14 }),
  },
}));

export const UsagePanel = () => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme() as Theme;

  const [date, setDate] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [storageUsed, setStorageUsed] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);

  const [storageUsedRatio, setStorageUsedRatio] = useState(0);

  const userUsage = useSelector((state: RootState) => state[USER].usageInfo);
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));

  const { subscriptionInfo } = useSelector(
    (state: RootState) => state[PAYMENT]
  );

  const freePlan = useMemo(() => {
    return subscriptionInfo && subscriptionInfo?.servicePlanID === 1;
  }, [subscriptionInfo]);

  const litePlan = useMemo(() => {
    // mantis - 12312, Lite Plan 최대 15대 적용 (Leehj)
    return (
      subscriptionInfo &&
      getPlanFromServiceID(subscriptionInfo.servicePlanID) === "Lite plan"
    );
  }, [subscriptionInfo]);

  // mantis - 12445, 카메라가 없는 경우 즉 제한량이 0이면 0으로
  const noCamera = useMemo(() => {
    return userUsage && userUsage.LimitCloudStroage === 0;
  }, [userUsage]);

  const getLiveViewLimitTime = useMemo(() => {
    if (userUsage && userUsage.LimitLiveView === 0) {
      return t("of Unlimited");
    }
    if (freePlan) {
      return t("of A minutes", { a: 10 });
    } else if (litePlan) {
      return t("of A minutes", { a: 30 });
    }
  }, [freePlan, litePlan, t, userUsage]);

  const loginInfo = useSelector((state: RootState) => state[USER].loginInfo);

  useEffect(() => {
    if (userUsage) {
      // mantis - 12375, live view limited 초과된 상태면 제한 용량으로 출력 (Leehj)
      const duration = moment.duration(
        userUsage.liveViewDayUsage > userUsage.LimitLiveView
          ? userUsage.LimitLiveView
          : userUsage.liveViewDayUsage,
        "seconds"
      );
      setDate({
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
      // mantis - 12192, LimitCloudStroage 0이면 0으로 출력(Leehj)
      setStorageUsedRatio(
        userUsage.LimitCloudStroage === 0
          ? 0
          : (userUsage.cloudStorageUsage / userUsage.LimitCloudStroage) * 100
      );
      setTotalStorage(userUsage.LimitCloudStroage / 1024 / 1024 / 1024);
      setStorageUsed(userUsage.cloudStorageUsage / 1024 / 1024 / 1024);
    }
  }, [userUsage]);

  useEffect(() => {
    if (loginInfo?.userType === "Master" || loginInfo?.userType === "Etc") {
      dispatch(loadUsageInfo());
    }
  }, [dispatch, loginInfo?.userType]);

  //  mantis - 12243, 실 사용 용량이 제한 용량보다 더 큰 경우 제한 용량으로 표시 (Leehj)
  const getVideoPlaybacksAndDownload = useMemo(() => {
    const maxLimit = 100;
    const usage = userUsage?.remoteVideoPlayBackAndDownLoad ?? 0;
    return freePlan && usage >= maxLimit ? maxLimit : usage;
  }, [freePlan, userUsage]);

  const getStorageUsedPercent = useMemo(() => {
    // mantis - 12445, 클라우드 연결된 카메라가 없는 경우 즉 LimitCloudStroage가 0인경우 0%로 표시 (Leehj)
    if (noCamera) {
      return 0;
      // mantis - 12243, 실 사용 용량이 제한 용량보다 더 큰 경우 100%로 표시 (Leehj)
    } else if (storageUsed > totalStorage) {
      return 100;
    } else {
      return storageUsedRatio.toFixed(2);
    }
  }, [noCamera, storageUsed, storageUsedRatio, totalStorage]);

  // mantis - 12403, 다운그레이드했는데 사용량이 제한용량을 넘는 경우 빨간텍스트로 출력 (Leehj)
  // mantis - 12445, 카메라가 없는 경우 검정색 (Leehj)
  const getLimitColor = useMemo(() => {
    return !noCamera && userUsage && storageUsed >= totalStorage;
  }, [noCamera, storageUsed, totalStorage, userUsage]);

  //  mantis - 12458, 사용량이 제한용량보다 같거나 큰경우 빨간색으로 적용 (Leehj)
  // LimitLiveView가 0인 경우는 검은색으로 표시
  const getLiveViewLimitColor = useMemo(() => {
    return (
      userUsage &&
      userUsage.LimitLiveView !== 0 &&
      userUsage.liveViewDayUsage >= userUsage.LimitLiveView
    );
  }, [userUsage]);

  // mantis - 12411, 12437, 12438 unlimitedMarkup 디자인 추가 (Leehj)
  const unlimitedMarkup = useMemo(() => {
    return (
      <div style={{ display: "flex", marginTop: 21 }}>
        <svg
          width="53"
          height="65"
          viewBox="0 0 53 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7077_98253)">
            <path
              d="M39.7969 21.625C37.7453 21.625 35.6836 22.1836 33.6828 23.2906C32.0273 24.2047 30.4227 25.5047 29.0414 27.0383C28.4422 27.7086 28.493 28.7344 29.1633 29.3336C29.8336 29.9328 30.8594 29.882 31.4586 29.2117C33.9367 26.4594 36.9734 24.875 39.7969 24.875C44.3875 24.875 48.125 28.5211 48.125 33C48.125 37.4789 44.3875 41.125 39.7969 41.125C33.0023 41.125 28.5133 32.3906 28.4523 32.2688C28.2289 31.8117 23.0188 21.625 14.2031 21.625C7.81484 21.625 2.625 26.7234 2.625 33C2.625 39.2766 7.81484 44.375 14.2031 44.375C16.2547 44.375 18.3164 43.8164 20.3172 42.7094C21.9727 41.7953 23.5773 40.4953 24.9586 38.9617C25.5578 38.2914 25.507 37.2656 24.8367 36.6664C24.1664 36.0672 23.1406 36.118 22.5414 36.7883C20.0633 39.5406 17.0266 41.125 14.2031 41.125C9.6125 41.125 5.875 37.4789 5.875 33C5.875 28.5211 9.6125 24.875 14.2031 24.875C20.9977 24.875 25.4867 33.6094 25.5477 33.7313C25.7711 34.1781 30.9812 44.375 39.7969 44.375C46.1852 44.375 51.375 39.2766 51.375 33C51.375 26.7234 46.1852 21.625 39.7969 21.625Z"
              fill="#0095E0"
              stroke="#0095E0"
              stroke-width="1.25"
            />
          </g>
          <defs>
            <clipPath id="clip0_7077_98253">
              <rect
                width="52"
                height="64"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>

        <Typography
          category="Default"
          variant="BodyBold"
          className={clsx(classes.unlimitedText, {
            fontSmall:
              i18n.language === "id" ||
              i18n.language === "pl" ||
              i18n.language === "vi" ||
              i18n.language === "ru",
          })}
        >
          {t("Unlimited")}
        </Typography>
      </div>
    );
  }, [classes.unlimitedText, t, i18n.language]);

  return (
    <div className={classes.root}>
      <div className={classes.boxGroupDiv}>
        <div className={classes.boxDiv}>
          <div className={classes.MgL24}>
            <div className={classes.textDiv}>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="BodyBold"
              >
                {t("Live view")}
              </Typography>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["2"]}
              >
                {t(`Limit resets daily`)}
              </Typography>
            </div>
            {freePlan || litePlan ? (
              <>
                <div className={classes.usageDiv} style={{ marginBottom: 8 }}>
                  {/* mantis - 12483,  freePlan || litePlan 계정인 Live view H(시) 제거 (Leehj) */}
                  <Typography
                    style={{
                      fontSize: mobile ? "46px" : "56px",
                      lineHeight: mobile ? "53.91px" : "66px",
                    }}
                    category="Default"
                    variant="BodyBold"
                    htmlColor={
                      getLiveViewLimitColor
                        ? LightColors.secondary["11"]
                        : LightColors.primary["1"]
                    }
                  >
                    {/* mantis - 12289,  FREE 계정으로 APP 라이브뷰 10분 이상 시청한 경우 max 값으로 출력(Leehj)  */}
                    {freePlan && userUsage && userUsage.liveViewDayUsage >= 600
                      ? 10
                      : date.minutes}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: mobile ? "30px" : "40px",
                      lineHeight: mobile ? "35.16px" : "46.88px",
                    }}
                    category="Default"
                    variant="BodyBold"
                    htmlColor={
                      getLiveViewLimitColor
                        ? LightColors.secondary["11"]
                        : LightColors.primary["1"]
                    }
                  >
                    m
                  </Typography>
                  <Typography
                    style={{
                      fontSize: mobile ? "46px" : "56px",
                      lineHeight: mobile ? "35.16px" : "46.88px",
                    }}
                    category="Default"
                    variant="BodyBold"
                    htmlColor={
                      getLiveViewLimitColor
                        ? LightColors.secondary["11"]
                        : LightColors.primary["1"]
                    }
                  >
                    :
                  </Typography>
                  <Typography
                    style={{
                      fontSize: mobile ? "46px" : "56px",
                      lineHeight: mobile ? "53.91px" : "66px",
                    }}
                    category="Default"
                    variant="BodyBold"
                    htmlColor={
                      getLiveViewLimitColor
                        ? LightColors.secondary["11"]
                        : LightColors.primary["1"]
                    }
                  >
                    {/* mantis - 12289,  FREE 계정으로 APP 라이브뷰 10분 이상 시청한 경우 max 값으로 출력(Leehj)  */}
                    {freePlan && userUsage && userUsage.liveViewDayUsage >= 600
                      ? 0
                      : date.seconds}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: mobile ? "30px" : "40px",
                      lineHeight: mobile ? "35.16px" : "46.88px",
                    }}
                    category="Default"
                    variant="BodyBold"
                    htmlColor={
                      getLiveViewLimitColor
                        ? LightColors.secondary["11"]
                        : LightColors.primary["1"]
                    }
                  >
                    s
                  </Typography>
                </div>{" "}
                <div className={classes.textDiv}>
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {/* mantis - 12312,  lite 30분으로 출력 (Leehj) */}
                    {getLiveViewLimitTime}
                  </Typography>
                </div>{" "}
              </>
            ) : (
              unlimitedMarkup
            )}
            <div className={classes.graphDiv}></div>
          </div>
        </div>
        <div className={classes.boxDiv}>
          <div className={classes.MgL24}>
            <div className={classes.textDiv}>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="BodyBold"
              >
                {mobile
                  ? t("Video plays and downloads")
                  : t("Video playbacks and downloads")}
              </Typography>

              <Typography
                className={classes.marginText}
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["2"]}
              >
                {t(`Limit resets monthly`)}
              </Typography>
            </div>

            {freePlan ? (
              <div
                className={classes.usageDiv}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  style={{
                    fontSize: mobile ? "46px" : "56px",
                    lineHeight: mobile ? "53.91px" : "65.63px",
                  }}
                  category="Default"
                  variant="BodyBold"
                  //  mantis - 12458, 사용량이 제한용량보다 같거나 큰경우 빨간색으로 적용 (Leehj)
                  htmlColor={
                    userUsage?.LimitVOD === getVideoPlaybacksAndDownload
                      ? LightColors.secondary["11"]
                      : LightColors.primary["1"]
                  }
                >
                  {/* mantis - 12243, 실 사용 용량이 제한 용량보다 더 큰 경우 제한 용량으로 표시 (Leehj) */}
                  {getVideoPlaybacksAndDownload}
                </Typography>
                <div className={classes.textDiv} style={{ marginTop: 8 }}>
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {t("of 100 times")}
                    {/* {freePlan ? t("of 100 times") : t("of Unlimited")} */}
                  </Typography>
                </div>
              </div>
            ) : (
              unlimitedMarkup
            )}
            <div className={classes.graphDiv}></div>
          </div>
        </div>
        <div className={classes.boxDiv}>
          <div className={classes.MgL24}>
            <div className={clsx(classes.textDiv, classes.textDivMargin)}>
              {/* mantis - 12438,12437, 변경된 피그마 디자인 적용, 화살표 제거 (Leehj) */}
              <Typography
                className={classes.marginText}
                category="Default"
                variant="BodyBold"
              >
                {t(`Cloud Storage`)}
              </Typography>
              <Typography
                className={classes.marginText}
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["2"]}
              >
                {t(`Space used`)}
              </Typography>
            </div>
            <div className={classes.spaceDiv}>
              <Typography
                style={{
                  fontSize: mobile ? "46px" : "56px",
                  lineHeight: mobile ? "53.91px" : "65.63px",
                  color: getLimitColor
                    ? LightColors.secondary["11"]
                    : LightColors.primary["1"],
                }}
                category="Default"
                variant="BodyBold"
              >
                {/* mantis - 12192, totalStorage가 0이면 0으로 출력 / -0.00GB로 출력되는 이슈 수정하기 위함 (Leehj) */}
                {/* {(totalStorage - storageUsed).toFixed(2)} */}
                {/* mantis - 12211 , 사용량출력으로 수정 (Leehj) */}
                {/* mantis - 12243, 실 사용 용량이 제한 용량보다 더 큰 경우 제한 용량으로 표시 (Leehj) */}
                {/* mantis - 12403, 무조건 사용량으로 출력 (Leehj)/ mantis - 12445, 카메라가 없는 경우 즉 제한량이 0이면 0으로 (Leehj) */}
                {noCamera ? totalStorage.toFixed(2) : storageUsed.toFixed(2)}
              </Typography>
              <Typography
                style={{
                  fontSize: mobile ? "30px" : "40px",
                  lineHeight: mobile ? "35.63px" : "46.88px",
                  color: getLimitColor
                    ? LightColors.secondary["11"]
                    : LightColors.primary["1"],
                }}
                category="Default"
                variant="BodyBold"
              >
                {"GB"}
              </Typography>

              {/* mantis - 12438,12437, 변경된 피그마 디자인 적용, 화살표 제거 (Leehj) */}
              {/* <div className={classes.arrowIcon}>
                <ArrowDownwardIcon
                  style={{ color: LightColors.secondary[11] }}
                />
              </div> */}
            </div>
          </div>
          <div className={classes.graphDiv}>
            <div
              // mantis - 12458, 사용량이 제한량보다 같거나 높을 경우 피그마 디자인 적용(Leehj)
              className={clsx(classes.usageGradient, {
                [classes.usageLimitGradient]: getLimitColor,
              })}
              style={{
                width: getLimitColor ? `100%` : `${storageUsedRatio}%`,
              }}
            ></div>
            <div className={classes.usage}>
              <Typography category="Default" variant="BodyBold">
                {getStorageUsedPercent}
              </Typography>
              <Typography category="Default" variant="Small">
                %
              </Typography>
            </div>
            <div className={classes.totalStorage}>
              <Typography category="Default" variant="BodyBold">
                {totalStorage}
              </Typography>
              <Typography category="Default" variant="Small">
                {"GB"}
              </Typography>
            </div>
            <div
              // mantis - 12458, 사용량이 제한량보다 같거나 높을 경우 피그마 디자인 적용(Leehj)
              className={clsx(classes.gradientUnderline, {
                [classes.gradientLimitUnderline]: getLimitColor,
              })}
              style={{
                width: getLimitColor ? `100%` : `${storageUsedRatio}%`,
              }}
            ></div>
          </div>
        </div>
      </div>
      {/* 피그마,시나리오에서 삭제된 것 같아 주석 처리  */}
      {/* <div className={classes.noteDiv}>
        <Typography
          className={classes.explainTypo}
          category="Default"
          variant="Small"
        >
          {t(
            `Please note, that we don’t provide usage stats for Cloud Lite service.`
          )}
        </Typography>
      </div> */}
    </div>
  );
};
