import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Google,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";

import AppleIcon from "@material-ui/icons/Apple";
import { useDispatch, useSelector } from "react-redux";
import {
  Divider,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import SignupForm from "../forms/SignupForm";
import { MinimalFooter } from "../components/MinimalFooter";
import { LoginHeader } from "../components/LoginHeader";
import crypto from "crypto";

import {
  create,
  confirm,
  USER,
  clearNeedToConfirmEmail,
  clearUser,
} from "../features/User/slice";
import EmailConfirmForm from "../forms/EmailConfirmForm";
import { isBrowserCheck } from "../utils/isBrowserCheck";
import { isMobileOSCheck } from "../utils/isMobileOSCheck";
import { RootState } from "../features/store";
import _ from "lodash";
import { FirebaseAuth } from "../components/FirebaseAuth";
import { useHistory, useLocation } from "react-router-dom";
import { clearError, ERROR } from "../features/Error/slice";
import clsx from "clsx";

import { SubmissionError } from "redux-form";
import withSimpleBar from "../hoc/withSimpleBar";
import {
  LightColors,
  Webviewer,
  SignupMode,
  ISignupForm,
  MobileLang,
  IConfirmForm,
  config,
  API_GATEWAY_URI,
  FLEETA_PROMO_CODE,
} from "@thingsw/pitta-modules";
import axios from "axios";
import { checkFleetaPromo, getPromoList, getSubscription } from "../apis";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 57,
    backgroundColor: LightColors.primary["0"],
    minHeight: "calc(var(--vh, 1vh) * 100 - 58px)",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "calc(var(--vh, 1vh) * 100 - 58px)",
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.25, 2, 0, 2),
    },
  },

  errorBody: {
    paddingTop: 69,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: theme.spacing(14.25),
    },
  },
  title: {
    display: "flex",
    width: "50%",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      width: "100%",
    },
  },
  formWrap: {
    display: "flex",
    [theme.breakpoints.down(Webviewer.mobile)]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  button: {
    padding: "7px 38px 7px 16px",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 437,
    flexGrow: 1,
  },
  dividerDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
    },
  },
  divider: {
    backgroundColor: LightColors.primary["6"],
    width: "100%",
    top: "50%",
    transform: "translate(0, -50%);",
  },
  orDiv: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    backgroundColor: LightColors.primary["0"],
    color: LightColors.primary["2"],
    zIndex: 2,
  },
  marginB1: {
    marginBottom: theme.spacing(1),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
  marginR2: {
    marginRight: theme.spacing(2),
  },
  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    padding: theme.spacing(2),
    backgroundColor: `${LightColors.primary["6"]}73`,
    borderRadius: 4,
    maxWidth: 437,
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      margin: 0,
      marginLeft: theme.spacing(4),
      maxWidth: 330,
    },
  },
  errorDiv: {
    width: "100%",
    marginBottom: theme.spacing(4),
    position: "fixed",
    zIndex: 3,
  },
  googleBtn: {
    color: LightColors.primary["1"],
    "&:hover": {
      color: LightColors.primary["7"],
    },
    "&:active": {
      color: LightColors.primary["7"],
    },
  },
  appleBtn: {
    marginBottom: theme.spacing(2),
    padding: "7px 38px 7px 16px",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: theme.spacing(3),
    },
  },
  headerTextDiv: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  activeContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2, 2, 0, 2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      padding: theme.spacing(14.25, 2, 0, 2),
    },
  },
  activateDiv: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 438,
  },
  btnFull: {
    width: "100%",
  },
}));

export const SignupScreen = withSimpleBar(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  interface IState {
    mode?: SignupMode;
    cameraCnt?: number;
    fleetaPromo?: boolean;
  }
  const location = useLocation();

  const locationCameraCnt = (location.state as IState)?.cameraCnt;

  const { needToConfirmEmail, activated, loginInfo } = useSelector(
    (state: RootState) => state[USER]
  );

  // const mode = "success";
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { i18n } = useTranslation();
  const { email, mobile_uuid } = useSelector((state: RootState) => state[USER]);
  const { error } = useSelector((state: RootState) => state[ERROR]);

  const [socialType, setSocialType] = useState<string>("");
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  const [fleetaPromoErrorModal, setFleetaPromoErrorModal] = useState(false);
  const [fleetaPromoFreeErrorModal, setFleetaPromoFreeErrorModal] = useState(
    false
  );

  const locationMode = useMemo(() => {
    const promoFlag = query.get("fleeta_promo");

    if (promoFlag === "true") {
      return "fleet";
    }
    return (location.state as IState)?.mode;
  }, [location.state, query]);

  const fleetaPromoFlag = useMemo(() => {
    const promoFlag = query.get("fleeta_promo");

    if (promoFlag !== null) {
      return promoFlag === "true";
    }
    return (location.state as IState)?.fleetaPromo;
  }, [location.state, query]);

  const { data: fleetaPromo } = useQuery({
    queryKey: ["check-feeta-promo"],
    queryFn: async () => {
      if (fleetaPromoFlag) {
        const resp = await getPromoList();
        return !!_.chain(resp.data.response)
          .filter((r) => r.promotionCode === FLEETA_PROMO_CODE)
          .find((r) => {
            const endDate = moment(r.endDate);

            const now = moment();
            return now.isSameOrBefore(endDate);
          })
          .value();
      }

      return false;
    },
    refetchIntervalInBackground: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(clearNeedToConfirmEmail());
    dispatch(clearError());
    return () => {
      dispatch(clearError());
      dispatch(clearNeedToConfirmEmail());
    };
  }, [dispatch]);

  useEffect(() => {
    const checkLoginInfo = async (email: string, user_token: string) => {
      const [resp, resp2, resp3] = await Promise.all([
        axios.get(
          `${API_GATEWAY_URI}/Account/GetProfile?email=${email}&userToken=${user_token}&tokenType=web`
        ),
        checkFleetaPromo(email),
        getSubscription(email, user_token, "web"),
      ]);
      if (resp.data.resultcode === "BC_ERR_OK") {
        if (resp2.data.response) {
          history.push("/billing", {
            mode: "fleet",
            fleetaPromo: true,
          });
        } else {
          const servicePlanName: string | undefined =
            resp3.data?.response?.servicePlanName;
          if (servicePlanName?.startsWith("fleet")) {
            setFleetaPromoErrorModal(true);
          } else {
            setFleetaPromoFreeErrorModal(true);
          }
        }
      }
    };

    if (fleetaPromo && loginInfo && email) {
      checkLoginInfo(email, loginInfo.user_token);
    }
  }, [email, fleetaPromo, history, loginInfo]);

  const handleSubmit = useCallback(
    async (data: ISignupForm) => {
      try {
        console.log("data", data);
        const lang =
          i18n.language ?? navigator.language.toLowerCase().substring(0, 2);
        console.log("lang", lang);
        dispatch(
          create({
            email: data.email,
            passwd: crypto
              .createHash("sha256")
              .update(data.passwd)
              .digest("hex"),
            mobileLang: MobileLang[lang],
            firstName: data.firstName,
            lastName: data.lastName,
            tokenType: "web",
            newsRecv: data.subscribe ? 1 : 0,
            inviteToken: query.get("inviteToken") ?? undefined,
          })
        );
      } catch (err) {
        console.log("validate", err.errors, err.path, err.inner);
        throw new SubmissionError(
          _.reduce(
            err.inner,
            (result, error) => {
              return { ...result, [error.path]: error.errors };
            },
            {}
          )
        );
      }
    },
    [dispatch, i18n.language, query]
  );
  const handleSubmitConfirm = useCallback(
    (data: IConfirmForm) => {
      const mobileName = isBrowserCheck();
      const mobileOSType = isMobileOSCheck();

      const osLang = navigator.language.toLowerCase().substring(0, 2);
      if (email) {
        dispatch(
          confirm({
            email,
            confirmNumber: _.join(data.inputs, ""),
            mobileUUID: mobile_uuid,
            mobileName,
            mobileOSType,
            mobileVersion: 1.0,
            mobileLang: MobileLang[osLang],
            mobileTimeInterval: "540",
            tokenType: "web",
            signupMode: locationMode,
            cameraCnt: locationCameraCnt,
            fleetaPromo,
          })
        );
      }
    },
    [dispatch, email, fleetaPromo, locationCameraCnt, locationMode, mobile_uuid]
  );

  const planExplainMarkup = useMemo(() => {
    if (locationMode === "free") {
      return (
        <>
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["1"]}
            className={classes.marginB1}
          >
            {t("Free Plan")}{" "}
          </Typography>

          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("Get started for_")}
          </Typography>
        </>
      );
    }

    if (query.get("inviteToken")) {
      return (
        <>
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["1"]}
            className={classes.marginB1}
          >
            {t("Fleet Plan")} · {t("Admin")}
          </Typography>

          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("It is the account that_Admin")}
          </Typography>
          <Typography
            category="Default"
            variant="H6"
            htmlColor={LightColors.primary["1"]}
            className={classes.marginB1}
            style={{ marginTop: 16 }}
          >
            {t("Fleet Plan")} · {t("Driver")}
          </Typography>

          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("It is the account that_Driver")}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          category="Default"
          variant="H6"
          htmlColor={LightColors.primary["1"]}
          className={classes.marginB1}
        >
          {t("Fleet Plan")} · {t("1 month free trial_")}
        </Typography>

        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
        >
          {t("BlackVue Fleet Tracking_")}.{" "}
          <Typography category="Default" variant="SmallBold">
            {t("Web subscription only")}
          </Typography>
          .
        </Typography>
      </>
    );
  }, [classes.marginB1, locationMode, query, t]);

  const contentMarkup = useMemo(() => {
    if (needToConfirmEmail) {
      return (
        <EmailConfirmForm
          onSubmit={handleSubmitConfirm}
          initialValues={{ inputs: ["", "", "", "", "", ""] }}
        />
      );
    }
    if (activated && location.search !== "") {
      return (
        <div className={classes.activeContainer}>
          <div className={classes.activateDiv}>
            <Typography
              category="Default"
              variant="H1"
              style={{ marginBottom: 32 }}
            >
              {t("Your account is_")}
            </Typography>
            <Typography
              category="Default"
              variant="Body"
              style={{ marginBottom: 24 }}
            >
              {t("You can now_")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.replace("/login");
                dispatch(clearUser());
              }}
            >
              {t("Back to login")}
            </Button>
          </div>
        </div>
      );
    }

    const fleetaPromoMarkup = fleetaPromo ? (
      <div className={classes.fleetPlan}>
        <Typography
          category="Default"
          variant="H6"
          htmlColor={LightColors.primary["1"]}
          className={classes.marginB1}
        >
          {t("Fleet Subscription Promotion (50%)")}
        </Typography>
      </div>
    ) : (
      <></>
    );

    return (
      <Container className={clsx(classes.body, error && classes.errorBody)}>
        <div>
          <div className={classes.title}>
            <Typography
              category="Default"
              variant={mobile ? "H2" : "H1"}
              className={mobile ? "" : classes.marginB4}
            >
              {t("Sign up")}
            </Typography>
          </div>
          <div className={classes.formWrap}>
            <div className={classes.formDiv}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Google fontSize="small" />}
                className={`${classes.marginB2} ${classes.button}`}
                fullWidth
                onClick={() => {
                  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                  firebase.auth().signInWithPopup(googleAuthProvider);
                  setSocialType("google");
                }}
              >
                {t("Continue with Google")}
              </Button>

              <Button
                variant="outlined"
                color="primary"
                startIcon={<AppleIcon fontSize="small" />}
                className={classes.appleBtn}
                fullWidth
                onClick={() => {
                  const appleAuthProvider = new firebase.auth.OAuthProvider(
                    "apple.com"
                  );
                  firebase.auth().signInWithPopup(appleAuthProvider);
                  setSocialType("apple");
                }}
              >
                {t("Continue with Apple")}
              </Button>
              <div className={classes.dividerDiv}>
                <div className={classes.orDiv}>
                  <Typography category="Default" variant="Small">
                    {t("OR")}
                  </Typography>
                </div>
                <Divider absolute className={classes.divider} />
              </div>
              <div>
                <SignupForm
                  onSubmit={handleSubmit}
                  initialValues={{
                    tos: false,
                    age: false,
                    subscribe: false,
                    email: query.get("email") ?? undefined,
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {fleetaPromoMarkup}
              <div className={classes.fleetPlan}>{planExplainMarkup}</div>
            </div>
          </div>
        </div>
        <MinimalFooter />
      </Container>
    );
  }, [
    activated,
    classes.activateDiv,
    classes.activeContainer,
    classes.appleBtn,
    classes.body,
    classes.button,
    classes.divider,
    classes.dividerDiv,
    classes.errorBody,
    classes.fleetPlan,
    classes.formDiv,
    classes.formWrap,
    classes.marginB1,
    classes.marginB2,
    classes.marginB4,
    classes.orDiv,
    classes.title,
    dispatch,
    error,
    fleetaPromo,
    handleSubmit,
    handleSubmitConfirm,
    history,
    location.search,
    mobile,
    needToConfirmEmail,
    planExplainMarkup,
    query,
    t,
  ]);

  return (
    <React.Fragment>
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <LoginHeader />
        <div className={classes.root}>
          {mobile && locationMode === "fleet" && (
            <div className={classes.headerTextDiv}>
              <Typography
                category="Default"
                variant="CaptionBold"
                htmlColor={LightColors.primary["1"]}
                className={classes.marginR2}
              >
                1. {t("Sign up")}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["1"]}
              >
                2. {t("Billing information")}
              </Typography>
            </div>
          )}
          {contentMarkup}
        </div>
        <FirebaseAuth socialType={socialType} />
      </FirebaseAuthProvider>

      <Modal
        open={fleetaPromoErrorModal}
        content={
          <Typography variant="BodyBold">
            {t("Not eligible for the promotion.")}
          </Typography>
        }
        RButton={t("OK")}
        fullWidthBtn
        RButtonClassName={classes.btnFull}
        onClickPositive={() => {
          history.push("/cameras");
        }}
      />
      <Modal
        open={fleetaPromoFreeErrorModal}
        content={
          <Typography variant="BodyBold">
            {t("You have already used_")}
          </Typography>
        }
        RButton={t("OK")}
        fullWidthBtn
        RButtonClassName={classes.btnFull}
        onClickPositive={() => {
          history.push("/cameras");
        }}
      />
    </React.Fragment>
  );
});
