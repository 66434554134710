import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import {
  ISelectorItem,
  Selector,
} from "@thingsw/pitta-design-system/dist/components/Selector";

import {
  Field,
  InjectedFormProps,
  WrappedFieldProps,
  reduxForm,
  submit,
  getFormValues,
  change,
} from "redux-form";
import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  FormHelperText,
  useMediaQuery,
} from "@material-ui/core";
import { Button, CheckBox, Typography } from "@thingsw/pitta-design-system";
import { useDispatch, useSelector } from "react-redux";
import { USER } from "../features/User/slice";
import { TFunction } from "i18next";
import { RootState } from "../features/store";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import LockIcon from "@material-ui/icons/Lock";
import clsx from "clsx";

import _ from "lodash";
import {
  CreateTokenCardData,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";
import { getFreetrialState, PAYMENT } from "../features/Payment/slice";
import {
  Webviewer,
  FLEET_PRICE,
  FLEET_EXTRA,
  MAX_CAM_COUNT,
  Countries,
  LightColors,
} from "@thingsw/pitta-modules";
import { THEME } from "../features/Theme/slice";

interface FieldProps {
  label?: string;
  helperText?: string;
  value?: string;
  t: TFunction;
  referralChecked?: boolean;
  items?: ISelectorItem[];
  type?: string;
  inputClassName?: string;
}

interface SelectFieldProps {
  onChange?: (item: ISelectorItem) => void;
}

const renderEmailField = ({
  label,
  input,
  helperText,
  meta: { touched, invalid, error, ...restMeta },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  console.log("renderEmailField", custom);
  return (
    <Input
      label={label}
      error={touched && invalid}
      helperText={(touched && t(error)) || helperText}
      {...input}
      {...custom}
    />
  );
};
const CheckboxFormControlLabel = withStyles({
  root: {
    marginLeft: -6,
    marginRight: 0,
    display: "flex",
    alignItems: "flex-start",
  },
  label: {
    padding: "3px 0 0 2px",
  },
})(FormControlLabel);

const renderCheckField = ({
  label,
  input,
  referralChecked,
  meta: { touched, invalid, error },
  t,
}: WrappedFieldProps & FieldProps) => {
  console.log("renderCheckField", input, touched, error);
  return (
    <div>
      <CheckboxFormControlLabel
        control={
          <CheckBox
            name={input.name}
            color="primary"
            value={input.value}
            checked={referralChecked}
            onChange={input.onChange}
          />
        }
        label={label}
      />
      {touched && error && (
        <FormHelperText
          style={{ color: LightColors.secondary["11"], marginLeft: 28 }}
        >
          {touched && t(error)}
        </FormHelperText>
      )}
    </div>
  );
};

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error, invalid },
  helperText,
  t,
  children,
  items,
  ...custom
}: any & SelectFieldProps) => {
  return (
    <Selector
      menuScrollTime
      keyValue={input.value.key ?? "country"}
      helperText={(touched && t(error)) || helperText}
      onChange={input.onChange}
      error={touched && invalid}
      items={items}
      {...custom}
    />
  );
};

// 카메라수 입력 필드
// mantis- 12434, camCnt값이 아닌 e.target.value값으로 화면에 보여지는 이슈 수정 (Leehj)
const renderCameraField = ({
  label,
  input: { value, ...restInput },
  helperText,
  meta: { touched, invalid, error, ...restMeta },
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  let cnt = 1;
  if (value !== "") {
    const valueCnt = Number.parseInt(value);
    cnt = Math.min(Math.max(1, valueCnt), MAX_CAM_COUNT);
  }

  return (
    <Input
      label={label}
      error={touched && invalid}
      value={cnt}
      helperText={(touched && t(error)) || helperText}
      {...restInput}
      {...custom}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  marginB1: {
    marginBottom: theme.spacing(1),
  },
  marginB12: {
    marginBottom: theme.spacing(1.5),
  },
  marginB2: {
    marginBottom: theme.spacing(2),
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginT3: {
    marginTop: theme.spacing(3),
  },
  paddingT1: {
    paddingTop: theme.spacing(1),
  },
  paddingT2: {
    paddingTop: theme.spacing(2),
  },
  paddingB1: {
    paddingBottom: theme.spacing(1),
  },
  paddingB2: {
    paddingBottom: theme.spacing(2),
  },
  formControlLabel: {
    padding: "3px 0 0 2px",
  },
  cardNumDiv: {
    border: (props: any) => `1px solid ${props.colors.primary["4"]}`,
    padding: "9px 13px 9px 16px",
    borderRadius: 4,
  },
  cardNumDivError: {
    borderColor: (props: any) => props.colors.secondary["11"],
  },
  addressDetailDiv: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  regionDiv: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  referralDiv: {
    marginBottom: theme.spacing(1) + 1,
  },
  input50per: {
    [theme.breakpoints.up(Webviewer.mobile)]: {
      maxWidth: 211,
    },
  },
  closeInput: {
    marginTop: 21,
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    // marginLeft: theme.spacing(4) - 1,
    padding: theme.spacing(2),
    backgroundColor: (props: any) => `${props.colors.primary["6"]}73`,
    borderRadius: 4,
    // [theme.breakpoints.down(Webviewer.mobile)]: {
    //   margin: theme.spacing(3, 0),
    // },
    flex: 1,
    // [theme.breakpoints.up(Webviewer.mobile)]: {
    //   maxWidth: 320,
    // },
  },
  helperTextDiv: {
    margin: "3px 16px 0px",
  },
  input: {
    color: (props: any) => props.colors.primary["1"],
  },
}));

interface BillInfoProps {
  loading?: boolean;
  onUpdateCardElement: (
    strip: Stripe,
    cardElement: StripeCardElement,
    referralCode: string,
    cameras: string
  ) => void;
  mode?: "billing" | "change";
  fleetaPromo?: boolean;
}

const BillInfo = (
  props: InjectedFormProps<CreateTokenCardData, BillInfoProps> & BillInfoProps
) => {
  const {
    loading,
    mode,
    onUpdateCardElement,
    handleSubmit,
    fleetaPromo,
  } = props;
  const { colors } = useSelector((state: RootState) => state[THEME]);
  const classes = useStyles({ colors });
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { cameraCnt } = useSelector((state: RootState) => state[USER]);
  const [camCnt, setCamCnt] = useState<number>(cameraCnt ? cameraCnt : 1);

  const [, /*disabledChange*/ setDisabledChange] = useState(true);
  const [referralChecked, setReferralChecked] = useState(false);
  const [fleetaPromoAgree, setFleetaPromoAgree] = useState(false);

  const totalPrice = useMemo(() => FLEET_PRICE + (camCnt - 1) * FLEET_EXTRA, [
    camCnt,
  ]);

  const { freetrial } = useSelector((state: RootState) => state[PAYMENT]);

  const stripe = useStripe();
  const elements = useElements();
  const state = useSelector((state: RootState) => state);
  const { cameraNum } = (getFormValues("BillInfoForm")(state) as
    | { cameraNum: string }
    | undefined) ?? { cameraNum: "1" };
  const { referralCode } = (getFormValues("BillInfoForm")(state) as
    | { referralCode: string }
    | undefined) ?? { referralCode: "" };

  useEffect(() => {
    dispatch(change("BillInfoForm", "cameraNum", cameraCnt ? cameraCnt : 1));
  }, [cameraCnt, dispatch]);

  useEffect(() => {
    dispatch(getFreetrialState());
  }, [dispatch]);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      onUpdateCardElement(stripe, cardElement, cameraNum, referralCode);
    }
  }, [stripe, elements, cameraNum, referralCode, onUpdateCardElement]);

  const handleEnterkey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(submit("BillInfoForm"));
    }
  };

  const handleReferralInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setReferralChecked(true);
      } else if (!e.target.checked) {
        setReferralChecked(false);
      }
    },
    []
  );

  const handleFleetaPromoAgreeInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setFleetaPromoAgree(true);
      } else if (!e.target.checked) {
        setFleetaPromoAgree(false);
      }
    },
    []
  );

  const handleCamNumInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let cnt = 1;
      if (e.target.value !== "") {
        const value = Number.parseInt(e.target.value);
        cnt = Math.min(Math.max(1, value), MAX_CAM_COUNT);
      }
      setCamCnt(cnt);
      dispatch(change("BillInfoForm", "cameraNum", cnt));
      setDisabledChange(false);
    },
    [dispatch]
  );

  const renderCardField = useCallback(
    ({ meta: { touched, invalid, error } }: WrappedFieldProps) => {
      return (
        <div>
          <div
            className={clsx(classes.cardNumDiv, {
              [classes.cardNumDivError]: error,
            })}
          >
            <CardElement
              options={{
                style: {
                  base: {
                    color: colors.primary["1"],
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    fontFamily: "Roboto",
                    "::placeholder": {
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 400,
                      fontFamily: "Roboto",
                    },
                  },
                },
              }}
            />
          </div>
          {error && (
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={colors.secondary["11"]}
              className={classes.helperTextDiv}
            >
              {t(error)}
            </Typography>
          )}
        </div>
      );
    },
    [
      classes.cardNumDiv,
      classes.cardNumDivError,
      classes.helperTextDiv,
      colors.primary,
      colors.secondary,
      t,
    ]
  );

  const totalPriceMarkup = useMemo(() => {
    if (!fleetaPromo && freetrial?.trialUsed) {
      return (
        <div
          className={clsx(
            classes.flexBetween,
            mobile ? classes.paddingT1 : classes.paddingT2
          )}
        >
          <Typography category="Default" variant="SmallBold">
            {t("Total")}
          </Typography>
          <Typography
            category="Default"
            variant="SmallBold"
            style={{ textAlign: "right" }}
          >
            {totalPrice.toFixed(2)} USD/mo
          </Typography>
        </div>
      );
    }
    const discount = fleetaPromo ? _.round(FLEET_PRICE / 2, 2) : 0;
    const extraPrice = (camCnt - 1) * FLEET_EXTRA;
    const extraDiscount = fleetaPromo ? _.round(extraPrice / 2, 0) : 0;
    return (
      <>
        <div
          className={clsx(
            classes.flexBetween,
            mobile ? classes.paddingT1 : classes.paddingT2
          )}
        >
          <div>
            <Typography category="Default" variant="Small">
              {t("Total after 1 month trial")}
            </Typography>
            <br />
            {fleetaPromo && (
              <Typography category="Default" variant="Caption">
                {t("*Promotion applied for 12 months")}
              </Typography>
            )}
          </div>

          <Typography
            category="Default"
            variant="Small"
            style={{ textAlign: "right" }}
          >
            {(totalPrice - (discount + extraDiscount)).toFixed(2)} USD/mo
          </Typography>
        </div>
        <div
          className={clsx(
            classes.flexBetween,
            mobile ? classes.paddingT1 : classes.paddingT2
          )}
        >
          <Typography category="Default" variant="SmallBold">
            {t("Due today")}
          </Typography>
          <Typography
            category="Default"
            variant="SmallBold"
            style={{ textAlign: "right" }}
          >
            0.00 USD
          </Typography>
        </div>
        {fleetaPromo && (
          <div
            className={clsx(
              "flex",
              "justify-center",
              mobile ? classes.paddingT1 : classes.paddingT2
            )}
          >
            <Typography category="Default" variant="SmallBold">
              {t("*Payment will be made starting next month.")}
            </Typography>
          </div>
        )}
      </>
    );
  }, [
    camCnt,
    classes.flexBetween,
    classes.paddingT1,
    classes.paddingT2,
    fleetaPromo,
    freetrial?.trialUsed,
    mobile,
    t,
    totalPrice,
  ]);

  const priceMarkup = useMemo(() => {
    const discount = fleetaPromo ? _.round(FLEET_PRICE / 2, 2) : 0;
    const extraPrice = (camCnt - 1) * FLEET_EXTRA;
    const extraDiscount = fleetaPromo ? _.round(extraPrice / 2, 0) : 0;
    return (
      <div>
        <div
          className={clsx(
            classes.flexBetween,
            mobile ? classes.paddingB1 : classes.paddingB2
          )}
        >
          <Typography category="Default" variant="Small">
            {t("Subscription")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            style={{ textAlign: "right" }}
          >
            {FLEET_PRICE} USD/mo
          </Typography>
        </div>
        <div
          className={classes.flexBetween}
          style={{ paddingBottom: mobile ? 7 : 15 }}
        >
          <Typography category="Default" variant="Small">
            {t("Extra cameras")}
          </Typography>
          <Typography
            category="Default"
            variant="Small"
            style={{ textAlign: "right" }}
          >
            {/* {((camCnt - 1) * FLEET_EXTRA).toFixed(2)} USD/mo */}
            {camCnt - 1} × {FLEET_EXTRA.toFixed(2)} USD/mo
          </Typography>
        </div>
        {fleetaPromo && (
          <div
            className={classes.flexBetween}
            style={{ paddingBottom: mobile ? 7 : 15 }}
          >
            <Typography category="Default" variant="Small">
              {t("Special Discount (50% off)")}
            </Typography>
            <Typography
              category="Default"
              variant="Small"
              style={{ textAlign: "right" }}
            >
              - {(discount + extraDiscount).toFixed(2)} USD/mo
            </Typography>
          </div>
        )}

        <div
          style={{
            display: "flex",
            borderBottom: `1px solid ${colors.primary["5"]}`,
          }}
        ></div>
        {totalPriceMarkup}
      </div>
    );
  }, [
    camCnt,
    classes.flexBetween,
    classes.paddingB1,
    classes.paddingB2,
    colors.primary,
    fleetaPromo,
    mobile,
    t,
    totalPriceMarkup,
  ]);

  const submitBtnMarkup = useMemo(() => {
    let buttonText = freetrial?.trialUsed ? "subscribe" : "Try 1 Month Free";
    if (fleetaPromo) {
      buttonText = "Upgrade to Fleet Plan immediately";
    }
    let startIcon = freetrial?.trialUsed ? "" : <LockIcon />;
    if (fleetaPromo) {
      startIcon = "";
    }
    return (
      <>
        <Button
          fullWidth
          color="primary"
          type="submit"
          loading={loading}
          startIcon={startIcon}
          className={clsx(
            classes.marginT3,
            !referralChecked && classes.closeInput
          )}
        >
          {t(buttonText)}
        </Button>
      </>
    );
  }, [
    classes.closeInput,
    classes.marginT3,
    fleetaPromo,
    freetrial?.trialUsed,
    loading,
    referralChecked,
    t,
  ]);

  const fleetaPromoMarkup = useMemo(() => {
    if (fleetaPromo) {
      return (
        <>
          <div className="px-6 py-4 rounded-lg bg-primary-6/50 flex flex-col">
            <Typography variant="SmallBold">
              {t("[About using promotions]")}
            </Typography>

            <ul className="list-disc ml-4">
              <li>
                <Typography variant="Small">
                  {t("The promotion is free for the first month_")}
                </Typography>
              </li>

              <li>
                <Typography
                  variant="SmallBold"
                  htmlColor={colors.secondary["11"]}
                >
                  {t("For users with an existing Lite/Smart subscription_")}
                </Typography>
                <ul className="list-[circle] ml-6">
                  <li>
                    <Typography variant="Small">
                      {t("Please be sure to cancel your app subscription_")}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="Small">
                      {t("You will be changed to Fleet Plan immediately_")}
                    </Typography>
                  </li>
                </ul>
              </li>

              <li>
                <Typography variant="Small">
                  {t("You can cancel Fleet Plan any time.")}
                </Typography>
                <ul className="list-[circle] ml-6">
                  <li>
                    <Typography variant="Small">
                      {t("Once the promotion is canceled_")}
                    </Typography>
                  </li>
                </ul>
              </li>

              <li>
                <Typography variant="Small">
                  {t("After promotion expires_")}
                </Typography>
              </li>
            </ul>
          </div>

          <div className={clsx()}>
            <Field
              name="fleetaPromoAgree"
              value="fleetaPromoAgree"
              checked={fleetaPromoAgree}
              onChange={handleFleetaPromoAgreeInput}
              label={
                <Typography category="Default" variant="Body">
                  {t("(Required) I have read the promotion instructions_")}
                </Typography>
              }
              component={renderCheckField}
              t={t}
            />
          </div>
        </>
      );
    }
  }, [
    colors.secondary,
    fleetaPromo,
    fleetaPromoAgree,
    handleFleetaPromoAgreeInput,
    t,
  ]);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={handleEnterkey}
        className="max-w-[800px]"
      >
        <div className="flex flex-col lg:flex-row">
          <div>
            <div className={classes.marginB3}>
              <Field
                name="name"
                label={t("Cardholder name")}
                component={renderEmailField}
                t={t}
                autoFocus
                inputClassName={classes.input}
              />
            </div>
            <div className={clsx(classes.marginB3)}>
              <Field name="cardNumber" component={renderCardField} />
            </div>
            <div className={classes.marginB3}>
              <Field
                name="address_country"
                label={t("Country")}
                component={renderSelectField}
                items={_.map(Countries, (val, key) => ({
                  key: key,
                  value: val,
                }))}
                t={t}
                outlinedInputClassName={classes.input}
                menuItemClassName={classes.input}
              />
            </div>
            <div className={classes.marginB3}>
              <Field
                name="address_line1"
                label={t("Address")}
                component={renderEmailField}
                t={t}
                inputClassName={classes.input}
              />
            </div>
            <div className={classes.marginB3}>
              <Field
                name="address_city"
                label={t("City")}
                component={renderEmailField}
                t={t}
                inputClassName={classes.input}
              />
            </div>

            <div className={clsx(classes.marginB3, classes.addressDetailDiv)}>
              <div className={classes.regionDiv}>
                <Field
                  name="address_state"
                  label={t("State/Region")}
                  component={renderEmailField}
                  t={t}
                  inputClassName={classes.input}
                />
              </div>
              <div>
                <Field
                  name="address_zip"
                  label={t("ZIP/Postal")}
                  component={renderEmailField}
                  t={t}
                  inputClassName={classes.input}
                />
              </div>
            </div>
            {mode !== "change" && (
              <div className={clsx(classes.marginB12, classes.input50per)}>
                <Field
                  type="numeric"
                  count
                  helperTextNoWrap
                  name="cameraNum"
                  label={t("Cameras")}
                  component={renderCameraField}
                  value={camCnt}
                  PlusButtonDis={camCnt === MAX_CAM_COUNT}
                  MinusButtonDis={camCnt === 1}
                  onPlus={() => {
                    dispatch(change("BillInfoForm", "cameraNum", camCnt + 1));
                    setCamCnt((c) => Math.min(c + 1, MAX_CAM_COUNT));
                    setDisabledChange(false);
                  }}
                  onMinus={() => {
                    console.log("minus", camCnt);
                    dispatch(change("BillInfoForm", "cameraNum", camCnt - 1));
                    setCamCnt((c) => Math.max(c - 1, 1));
                    setDisabledChange(false);
                  }}
                  onChange={handleCamNumInput}
                  // 12434, 네자리수 입력되면 999로 바뀌는 이슈수정 (Leehj)
                  onInput={(e: ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.slice(0, 3);
                  }}
                  t={t}
                  helperText={t("available on your_", { a: MAX_CAM_COUNT })}
                />
              </div>
            )}
            {!fleetaPromo && (
              <>
                <div className={clsx(referralChecked && classes.referralDiv)}>
                  <Field
                    name="ReferralCheck"
                    value="ReferralCheck"
                    checked={referralChecked}
                    onChange={handleReferralInput}
                    label={
                      <Typography category="Default" variant="Body">
                        {t("Referral code")}
                      </Typography>
                    }
                    component={renderCheckField}
                    t={t}
                  />
                </div>
                {referralChecked && (
                  <div className={classes.input50per}>
                    <Field
                      name="referralCode"
                      component={renderEmailField}
                      t={t}
                    />
                  </div>
                )}
              </>
            )}
          </div>

          {/* fleetPlan 설명 */}
          {mode !== "change" && (
            <div className={clsx(classes.fleetPlan, "my-6 lg:my-0 lg:ms-8")}>
              {fleetaPromo && (
                <>
                  <Typography
                    category="Default"
                    variant="H6"
                    htmlColor={colors.primary["1"]}
                    className={clsx(
                      classes.marginB1,
                      mobile && classes.marginB2
                    )}
                  >
                    {t("Fleet Subscription Promotion (50%)")}
                  </Typography>
                  <div className="h-4" />
                </>
              )}
              <Typography
                category="Default"
                variant="BodyBold"
                htmlColor={colors.primary["1"]}
                className={clsx(classes.marginB1, mobile && classes.marginB2)}
              >
                {t("Fleet Plan")}{" "}
                {fleetaPromo || !freetrial?.trialUsed
                  ? ` · ${t("1 month free trial_")}`
                  : ""}
              </Typography>
              {!mobile && (
                <div className={classes.marginB3}>
                  <Typography
                    category="Default"
                    variant="Small"
                    htmlColor={colors.primary["2"]}
                  >
                    {t("BlackVue Fleet Tracking exclusive features_")}
                    <br />
                    <Typography category="Default" variant="SmallBold">
                      * {t("Web subscription only")}
                    </Typography>
                    .
                  </Typography>
                </div>
              )}

              {priceMarkup}
            </div>
          )}
        </div>
        {fleetaPromoMarkup}
        {mode !== "change" && submitBtnMarkup}
      </form>
    </>
  );
};

const BillingForm = reduxForm<CreateTokenCardData, BillInfoProps>({
  form: "BillInfoForm",
  // asyncValidate: validator(schema),
  touchOnBlur: false,
})(BillInfo);

export default BillingForm;
