import React, { useMemo, useState } from "react";
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment";
import {
  ThumbnailBroken,
  Typography,
  IconButton,
} from "@thingsw/pitta-design-system";
import { IUXUILatestEvent } from "../../features/Event/slice";
import {
  LightColors,
  MsgType,
  THUMBNAIL_EVENTS,
  Webviewer,
} from "@thingsw/pitta-modules";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

export interface ILatestEvent {
  email?: string;
  psn: string;
  alarm_type: MsgType;
  msg: string;
  img_url?: string;
  event_file?: string;
  cdate?: moment.Moment;
}

export interface UXUINotificationListItemProps {
  event: IUXUILatestEvent;
  mobile?: boolean;
  utcOffset: number;
  disableLiveview?: boolean;
  disablePlaback?: boolean;
  onPlay?: (event: IUXUILatestEvent) => void;
  onLiveView?: (event: IUXUILatestEvent) => void;
  onImage?: (event: IUXUILatestEvent) => void;
  darkMode?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2.25, 0, 2),
      backgroundColor: "rgba(52, 52, 52, 0.0)",
      borderRadius: 0,
      margin: "0px 16px 0px 24px !important",
      color: (props: any) => (props.darkMode ? "#EEEEF3" : "#13131C"),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        padding: theme.spacing(2, 0, 2.25),
      },
      // height: 82,
    },
    thumNotiRoot: {
      padding: theme.spacing(2, 0, 1.625),
      cursor: "pointer",
      // height: 112,
    },
    contentWrap: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    date: {
      // mantis - 10526, LightColors.primary["1"] -> LightColors.primary["3"] 로 색상변경(Leehj)
      color: LightColors.primary["3"],
    },
    textWrap: {
      maxWidth: (props: UXUINotificationListItemProps) =>
        props.mobile ? 208 : "unset",
      marginTop: 7,
    },
    textThumWrap: {
      marginTop: 5,
      marginBottom: 2,
    },
    img: {
      cursor: "pointer",
      //11093 비율 다른 문제 수정
      aspectRatio: "16/9",
      display: "block",
    },
    view: {
      fill: "none",
      borderRadius: 12,
      width: (props: UXUINotificationListItemProps) =>
        props.mobile ? theme.spacing(8) : theme.spacing(16),
      height: (props: UXUINotificationListItemProps) =>
        props.mobile ? theme.spacing(8) : theme.spacing(9),
      marginTop: -2,
    },
    // live: {
    //   ...(theme.direction === "rtl" ? { paddingRight: 3 } : { paddingLeft: 3 }),
    // },
    iconWrap: {
      display: "flex",
      marginTop: 8,
      ...(theme.direction === "rtl" ? { marginRight: -4 } : { marginLeft: -4 }),
      [theme.breakpoints.up(Webviewer.mobile)]: {
        ...(theme.direction === "rtl"
          ? { paddingRight: 3, marginRight: 0 }
          : { paddingLeft: 3, marginLeft: 0 }),
      },
    },
    liveWrap: {
      display: "flex",
      width: "max-content",
      ...(theme.direction === "rtl" ? { marginLeft: 8 } : { marginRight: 8 }),
    },
    play: {
      padding: 0,
      color: LightColors.primary["7"],
    },
    playWrap: {
      display: "flex",
      // color: LightColors.primary["7"],
    },
    btnStyle: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    notiLiveViewButton: {
      "&:active": {
        color: LightColors.primary["7"],
      },
      // 10576 - mobile인 경우 hover 상태 이펙트 제거 (mckim)
      [theme.breakpoints.up(Webviewer.mobile)]: {
        "&:hover": {
          color: LightColors.primary["7"],
        },
        "&:hover svg svg path": {
          fill: LightColors.primary["7"],
        },
        "&:hover svg svg circle": {
          stroke: LightColors.primary["7"],
        },
      },
    },
    notiPlayBackButton: {
      "&:active": {
        color: LightColors.primary["7"],
      },
      // 10576 - mobile인 경우 hover 상태 이펙트 제거 (mckim)
      [theme.breakpoints.up(Webviewer.mobile)]: {
        "&:hover": {
          color: LightColors.primary["7"],
        },
        "&:hover svg svg path:nth-of-type(3)": {
          fill: LightColors.primary["7"],
        },
      },
    },
    buttonDis: {
      cursor: "pointer",
      opacity: 0.35,
    },
    mobileSize: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    notiLiveViewIcon: {
      fontSize: 24,
      marginTop: 5,
    },
    divider: {
      margin: "0px 19px",
    },
    eventDateDiv: {
      display: "flex",
      alignItems: "center",
    },
    newNotificationCircle: {
      width: 4,
      height: 4,
      borderRadius: 3,
      backgroundColor: LightColors.secondary["11"],
      marginLeft: 18,
      marginTop: -2,
    },
  })
);

export const UXUINotificationListItem = (
  props: UXUINotificationListItemProps
) => {
  const {
    event,
    // disableLiveview,
    // disablePlaback,
    utcOffset,
    // onPlay,
    // onLiveView,
    onImage,
  } = props;
  const classes = useStyle(props);
  const theme = useTheme();
  const [imgError, setImgError] = useState(false);

  const isThumbnailEvent = useMemo(() => {
    return _.includes(THUMBNAIL_EVENTS, event.alarm_type) ||
      (event.alarm_type === "ALARM_GEOFENCE" && event.img_url)
      ? true
      : false;
  }, [event.alarm_type, event.img_url]);

  const thumbnailMarkup = useMemo(() => {
    if (imgError) {
      return <ThumbnailBroken className={classes.view} />;
    } else if (isThumbnailEvent) {
      return (
        <div style={{ position: "relative", marginInlineStart: 8 }}>
          <img
            alt="thumbnail"
            className={clsx(classes.view, classes.img)}
            src={event.img_url}
            onError={() => setImgError(true)}
            onClick={() => {
              onImage?.(event);
            }}
          />
          <div style={{ position: "absolute", bottom: 8, right: 8 }}>
            <IconButton
              onClick={() => {
                console.log("play");
              }}
            >
              <PlayCircleFilledIcon htmlColor="white" />
            </IconButton>
          </div>
        </div>
      );
    }
  }, [classes.img, classes.view, event, imgError, isThumbnailEvent, onImage]);

  return (
    //  썸네일모달 출력 선택 범위 시나리오에 맞게 확대
    <Paper
      dir={theme.direction}
      elevation={0}
      className={clsx(classes.root, {
        [classes.thumNotiRoot]: isThumbnailEvent,
      })}
      onClick={() => isThumbnailEvent && onImage?.(event)}
    >
      <div className={classes.contentWrap}>
        <div>
          <div className={classes.eventDateDiv}>
            <Typography
              category="Default"
              variant="CaptionBold"
              className={classes.date}
            >
              {event.cdate &&
                moment(event.cdate).utcOffset(utcOffset).format("h:mm:ss A")}
            </Typography>
            {/* 새로운 알림에 빨간 점 미출력 이슈 수정(Leehj) 23.01.11 */}
            {!event.readDT && <div className={classes.newNotificationCircle} />}
          </div>

          <div
            className={clsx(classes.textWrap, {
              [classes.textThumWrap]: isThumbnailEvent,
            })}
          >
            <Typography category="Default" variant="Small">
              {event.msg}
            </Typography>
          </div>
        </div>

        {thumbnailMarkup}
      </div>
    </Paper>
  );
};
