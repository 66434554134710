import {
  Button,
  CheckBox,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";
import {
  FormControlLabel,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Webviewer } from "@thingsw/pitta-modules";
import { useMutation } from "@tanstack/react-query";
import { USER } from "../../features/User/slice";
import { updatePromoDontShow } from "../../apis";

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    "&>a": {
      color: (props: any) => props.colors.primary["7"],
      textDecoration: "underline",
    },
  },
}));

interface FleetaIntroModalProps {
  open: boolean;
  onClose: () => void;
  onOK: () => void;
}

export const FleetaIntroModal = ({
  open,
  onClose,
  onOK,
}: FleetaIntroModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { colors } = useSelector((state: RootState) => state[THEME]);
  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const classes = useStyles({ colors });
  const [dontshow, setDontshow] = useState(false);

  const { mutateAsync, isLoading } = useMutation<any>({
    mutationFn: async () => {
      if (!email) return;
      if (!loginInfo) return;

      return updatePromoDontShow(email, loginInfo?.user_token, tokenType);
    },
  });

  return (
    <Modal
      open={open}
      fullScreen={mobile}
      content={
        <div className="flex flex-col gap-3 h-full">
          <div className="flex flex-col gap-3 h-full">
            <div className="flex flex-col pe-4">
              <Typography variant="Small" htmlColor={colors.primary["7"]}>
                {t("Important Notice:")}
              </Typography>
              <Typography variant="H6" htmlColor={colors.primary["7"]}>
                {t("Switch of BlackVue Web Viewer to Fleeta Web Viewer")}
              </Typography>
            </div>

            <Typography
              variant="BodyBold"
              htmlColor={colors.primary["1"]}
              dangerouslySetInnerHTML={{
                __html: t("To provide better features and quality_"),
              }}
              className={classes.body}
            ></Typography>
            <br />
            <Typography variant="BodyBold" htmlColor={colors.primary["1"]}>
              {t("We kindly ask for your understanding_")}
            </Typography>
          </div>

          <div className="flex flex-col gap-3">
            <div>
              <FormControlLabel
                checked
                style={{ margin: 0 }}
                control={
                  <CheckBox
                    color="primary"
                    checked={dontshow}
                    onChange={(e, checked) => {
                      setDontshow(checked);
                    }}
                  />
                }
                label={
                  <Typography variant="Body" htmlColor={colors.primary["2"]}>
                    {t("Don’t show this again for this month")}
                  </Typography>
                }
              />
            </div>
            <Button
              color="primary"
              style={{ paddingBlock: 16 }}
              onClick={async () => {
                if (dontshow) {
                  await mutateAsync();
                }

                onOK();
              }}
              loading={isLoading}
            >
              {t("OK")}
            </Button>
          </div>
        </div>
      }
      close
      onClose={async () => {
        if (dontshow) {
          await mutateAsync();
        }

        onClose();
      }}
    />
  );
};
