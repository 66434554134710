import {
  Button,
  CheckBox,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { THEME } from "../../features/Theme/slice";
import { FormControlLabel, makeStyles, Theme } from "@material-ui/core";
import { useMutation } from "@tanstack/react-query";
import { updatePromoDontShow } from "../../apis";
import { USER } from "../../features/User/slice";

const useStyles = makeStyles((theme: Theme) => ({
  modalBody: {
    backgroundColor: (props: any) => props.colors.primary["0"],
    border: "unset",
    borderRadius: 12,
  },
  modalContent: {
    display: "flex",
    border: "unset",
    padding: "16px 24px",
  },
}));

interface FleetaPromoModalProps {
  open: boolean;
  onClose: () => void;
  onSeeMore: () => void;
  hideNoDisplay?: boolean;
}

export const FleetaPromoModal = ({
  open,
  onClose,
  onSeeMore,
  hideNoDisplay,
}: FleetaPromoModalProps) => {
  const { t } = useTranslation();
  const { colors } = useSelector((state: RootState) => state[THEME]);
  const { email, loginInfo, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const classes = useStyles({ colors });

  const [dontshow, setDontshow] = useState(false);

  const { mutateAsync, isLoading } = useMutation<any>({
    mutationFn: async () => {
      if (!email) return;
      if (!loginInfo) return;

      return updatePromoDontShow(email, loginInfo?.user_token, tokenType);
    },
  });

  return (
    <Modal
      className={classes.modalBody}
      contentClassName={classes.modalContent}
      open={open}
      content={
        <div className="flex flex-col gap-3 flex-1">
          <div className="flex flex-col pe-4">
            <Typography
              category="Default"
              variant="Small"
              htmlColor={colors.primary["7"]}
            >
              {t("Important Notice:")}
            </Typography>
            <Typography
              category="Default"
              variant="H6"
              htmlColor={colors.primary["7"]}
            >
              {t("Switch of BlackVue Web Viewer to Fleeta Web Viewer")}
            </Typography>
          </div>
          <div className="flex flex-col gap-3 flex-1 overflow-y-auto">
            <Typography
              category="Default"
              variant="Body"
              htmlColor={colors.primary["7"]}
              style={{ fontWeight: 700 }}
            >
              {t("To continue improve quality and provide better service_")}
            </Typography>
            <Typography
              category="Default"
              variant="BodyBold"
              htmlColor={colors.primary["1"]}
            >
              {t("We have prepared special discount offers for_")}
            </Typography>

            <div className="flex flex-col py-1 gap-2">
              <Typography
                category="Default"
                variant="BodyBold"
                htmlColor={colors.primary["7"]}
              >
                {t("Fleet Plan Subscription Promotion")}
              </Typography>
              <div
                className={`py-4 px-3 border-[1px] rounded-lg border-primary-9 bg-primary-10`}
              >
                <Typography
                  category="Default"
                  variant="H6"
                  htmlColor={colors.primary["7"]}
                >
                  {t("Period :  December 3, 2024 ~ May 31, 2025")}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            {!hideNoDisplay && (
              <div>
                <FormControlLabel
                  checked
                  style={{ margin: 0 }}
                  control={
                    <CheckBox
                      color="primary"
                      checked={dontshow}
                      onChange={(e, checked) => {
                        setDontshow(checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={colors.primary["2"]}
                    >
                      {t("Don’t show this again for this month")}
                    </Typography>
                  }
                />
              </div>
            )}
            <Button
              color="primary"
              style={{ paddingBlock: 16 }}
              onClick={async () => {
                if (dontshow) {
                  await mutateAsync();
                }

                window.open(
                  "https://blackvue.com/blackvue-cloud-upgrade-to-fleet-plan-for-the-price-of-smart-unlock-fleeta-access/",
                  "_blank"
                );

                onSeeMore();
              }}
              loading={isLoading}
            >
              {t("See more details")}
            </Button>
          </div>
        </div>
      }
      close
      onClose={async () => {
        if (dontshow) {
          await mutateAsync();
        }

        onClose();
      }}
    />
  );
};
