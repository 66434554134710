import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import smoothscroll from "smoothscroll-polyfill";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "./index.css";
import "./output.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import createStore from "./features/store";

// 8935 - ios 12 safari에 string matchAll 함수가 없는 문제로 패키지 추가
import matchAll from "string.prototype.matchall";
// 9104 - safari 13.0.4에서 string replaceall 함수가 없는 문제로 패키지 추가
//@ts-ignore
import replaceAll from "string.prototype.replaceall";
import { resources } from "@thingsw/pitta-modules";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a client
const queryClient = new QueryClient();

matchAll.shim();
replaceAll.shim();

const { store, persistor } = createStore();

smoothscroll.polyfill();

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_ENV === "production"
) {
  console.log = () => {};
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
  });

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
