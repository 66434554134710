import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography } from "@thingsw/pitta-design-system";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { MinimalFooter } from "../components/MinimalFooter";
import { LoginHeader } from "../components/LoginHeader";

import { create } from "../features/User/slice";
import { useHistory, useLocation } from "react-router-dom";
import { loadGoogleUserInfo, SOCIAL } from "../features/Social/slice";
import { RootState } from "../features/store";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebase from "firebase/app";
import "firebase/auth";

import SocialSigninForm from "../forms/SocialSigninForm";
import {
  LightColors,
  Webviewer,
  AuthType,
  SignupMode,
  ISignupForm,
  MobileLang,
} from "@thingsw/pitta-modules";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 58,
    backgroundColor: LightColors.primary["0"],
  },
  body: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 76px)",
    padding: theme.spacing(3, 2, 3, 2),
  },
  title: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "50%",
    },
  },
  formWrap: {
    display: "flex",
    alignItems: "center",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    maxWidth: 437,
    flexGrow: 1,
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  marginB5: {
    marginBottom: theme.spacing(5),
  },
  googleBtn: {
    color: LightColors.primary["1"],
    "&:hover, &:active": {
      color: LightColors.primary["7"],
    },
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 76px)",
  },
}));

export const SocialSigninScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const history = useHistory();
  const { googleUser, socialLoading } = useSelector(
    (state: RootState) => state[SOCIAL]
  );

  const location = useLocation<{
    email: string;
    provider: AuthType;
    name: string;
    photoURL: string;
    mode: SignupMode;
    fleetaPromo?: boolean;
    cameraCnt?: number;
  }>();

  //google login
  const params = new URLSearchParams(location.hash);
  const accessToken = params.get("access_token");

  //apple  login
  const { email, provider, name, photoURL, mode } = location.state ?? {};
  const [idToken, setIdToken] = useState<string>();
  const appleUserName = name.split(" ");

  // goole accessToken
  useEffect(() => {
    if (accessToken) {
      dispatch(loadGoogleUserInfo({ accessToken }));
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    const getIdToken = async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        console.log(currentUser.toJSON());
        const token = await currentUser.getIdToken();
        setIdToken(token);
        firebase.auth().signOut();
      } else {
        history.goBack();
      }
    };
    getIdToken();
  }, [history]);

  const handleSubmit = (data: ISignupForm) => {
    const osLang = navigator.language.toLowerCase().substring(0, 2);
    dispatch(
      create({
        email,
        mobileLang: MobileLang[osLang],
        newsRecv: data.subscribe ? 1 : 0,
        firstName: data.firstName,
        lastName: data.lastName,
        tokenType: "web",
        signupType: provider ?? "blackvue",
        oauthToken: idToken,
        signupMode: mode,
        //Apple은 사진 URL을 제공하지 않는다
        profileImg: photoURL,
      })
    );
  };

  return (
    <React.Fragment>
      <LoginHeader />
      <div className={classes.root}>
        <Container className={classes.body}>
          {socialLoading && (
            <div className={classes.loadingDiv}>
              <CircularProgress size={48} thickness={6} color="primary" />
            </div>
          )}
          {!socialLoading && (
            <>
              <div>
                <div className={classes.title}>
                  <Typography
                    category="Default"
                    variant="H1"
                    className={mobile ? classes.marginB3 : classes.marginB5}
                  >
                    {t("Sign up")}
                  </Typography>
                </div>
                <div className={classes.formWrap}>
                  <div className={classes.formDiv}>
                    <div>
                      <SocialSigninForm
                        onSubmit={handleSubmit}
                        initialValues={{
                          firstName: googleUser?.given_name || appleUserName[0],
                          lastName: googleUser?.family_name || appleUserName[1],
                          email: googleUser?.email || email,
                          tos: false,
                          age: false,
                          subscribe: false,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
        <MinimalFooter />
      </div>
    </React.Fragment>
  );
};
