import React from "react";

const FleetaPromoSuccessIcon = () => {
  return (
    <svg
      width="96"
      height="97"
      viewBox="0 0 96 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.33"
        d="M6.30908 74.6627L15.3195 68.7375L33.3176 78.5286L27.3886 82.8773H21.0259L6.30908 74.6627Z"
        fill="url(#paint0_linear_71_35067)"
      />
      <path
        opacity="0.33"
        d="M14.7798 69.0159L23.7902 63.0907L41.7883 72.8818L35.8593 77.2305H29.4966L14.7798 69.0159Z"
        fill="url(#paint1_linear_71_35067)"
      />
      <path
        opacity="0.33"
        d="M33.9795 80.3097L42.9899 74.3845L60.988 84.1756L55.059 88.5243H48.6963L33.9795 80.3097Z"
        fill="url(#paint2_linear_71_35067)"
      />
      <path
        opacity="0.33"
        d="M25.5093 85.9568L34.5196 80.0316L52.5178 89.8227L46.5888 94.1714H40.2261L25.5093 85.9568Z"
        fill="url(#paint3_linear_71_35067)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        opacity="0.33"
        d="M51.4742 59.2885L47.0608 79.2487L67.6397 79.2865L91.6231 65.5072L63.6046 49.34L51.4742 59.2885Z"
        fill="url(#paint4_linear_71_35067)"
      />
      <path
        d="M47.105 63.8561L65.7861 53.5925L49.7467 45.2443L31.0466 55.2243L47.105 63.8561Z"
        fill="#BEBEBE"
      />
      <path
        d="M62.9609 53.58L49.3173 46.5612L33.0698 55.0921L46.726 62.4322L62.9609 53.58Z"
        fill="#999999"
      />
      <path
        d="M31.0845 55.1683L47.1428 63.8001L47.0608 79.249L31.1349 70.9512L31.0845 55.1683Z"
        fill="#F2F5FF"
      />
      <path
        d="M47.0229 79.3049L65.7104 69.0414L65.7861 53.5924L47.1049 63.856L47.0229 79.3049Z"
        fill="#BCC5DC"
      />
      <path
        d="M39.4571 62.722L41.8781 63.9696L41.8466 65.9353L39.4255 64.6752L39.4571 62.722Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 64.6628L45.3205 65.9167L45.2953 67.8761L42.8743 66.616L42.9058 64.6628Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 60.7817L38.3599 62.0292L38.3283 63.995L35.8442 62.7349L35.8695 60.7817Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 58.9169L35.1378 60.2526L35.1 62.2184L32.6033 60.87L32.6348 58.9169Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 66.3325L41.8781 67.5863L41.8466 69.5458L39.4255 68.2857L39.4571 66.3325Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 68.2792L45.3205 69.5267L45.2953 71.4925L42.8743 70.226L42.9058 68.2792Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 64.3979L38.3599 65.6455L38.3283 67.6049L35.8442 66.3448L35.8695 64.3979Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 62.533L35.1378 63.8624L35.1 65.8281L32.6033 64.4798L32.6348 62.533Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 70.1189L41.8781 71.3727L41.8466 73.3322L39.4255 72.0721L39.4571 70.1189Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 72.0663L45.3205 73.3138L45.2953 75.2733L42.8743 74.0132L42.9058 72.0663Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 68.1849L38.3599 69.4324L38.3283 71.3919L35.8442 70.1381L35.8695 68.1849Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 66.3135L35.1378 67.6492L35.1 69.615L32.6033 68.2666L32.6348 66.3135Z"
        fill="#8FBBD8"
      />
      <path
        d="M49.8533 67.0635L52.4824 65.6963V63.3966L49.8533 64.7512V67.0635Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 65.1794L56.1771 63.8185V61.5188L53.5417 62.8734V65.1794Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 63.0439L59.8534 61.683V59.377L57.218 60.7316V63.0439Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 61.0084L63.5924 59.6474V57.3414L60.957 58.7023V61.0084Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 70.8436L52.4824 69.489V67.183L49.8533 68.5376V70.8436Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 68.966L56.1771 67.6051V65.2991L53.5417 66.66V68.966Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 66.8239L59.8534 65.4693V63.1633L57.218 64.5242V66.8239Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 64.7951L63.5924 63.4342V61.1345L60.957 62.4891V64.7951Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 74.7188L52.4824 73.3642V71.0582L49.8533 72.4191V74.7188Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 72.8407L56.1771 71.4798V69.1738L53.5417 70.5347V72.8407Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 70.7048L59.8534 69.3438V67.0378L57.218 68.3988V70.7048Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 68.67L63.5924 67.3091V65.0031L60.957 66.364V68.67Z"
        fill="#6F94A5"
      />
      <path
        d="M47.1055 49.3965L65.7866 39.1266L49.7472 30.7847L31.0471 40.7647L47.1055 49.3965Z"
        fill="#BEBEBE"
      />
      <path
        d="M62.9609 39.1141L49.3173 32.1016L33.0698 40.6325L46.726 47.9726L62.9609 39.1141Z"
        fill="#999999"
      />
      <path
        d="M31.0845 40.7081L47.1428 49.3399L47.0608 64.7888L31.1349 56.491L31.0845 40.7081Z"
        fill="#F2F5FF"
      />
      <path
        d="M47.0229 64.8458L65.7104 54.5822L65.7861 39.127L47.1049 49.3968L47.0229 64.8458Z"
        fill="#BCC5DC"
      />
      <path
        d="M39.4571 48.256L41.8781 49.5098L41.8466 51.4756L39.4255 50.2092L39.4571 48.256Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 50.2032L45.3205 51.4571L45.2953 53.4165L42.8743 52.1564L42.9058 50.2032Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 46.3279L38.3599 47.5754L38.3283 49.5349L35.8442 48.2811L35.8695 46.3279Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 44.4569L35.1378 45.7926L35.1 47.7584L32.6033 46.4101L32.6348 44.4569Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 51.8724L41.8781 53.1199L41.8466 55.0857L39.4255 53.8256L39.4571 51.8724Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 53.8196L45.3205 55.0671L45.2953 57.0329L42.8743 55.7665L42.9058 53.8196Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 49.9385L38.3599 51.186L38.3283 53.1455L35.8442 51.8916L35.8695 49.9385Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 48.0675L35.1378 49.4032L35.1 51.3627L32.6033 50.0207L32.6348 48.0675Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 55.6594L41.8781 56.9069L41.8466 58.8727L39.4255 57.6126L39.4571 55.6594Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 57.6L45.3205 58.8601L45.2953 60.8133L42.8743 59.5594L42.9058 57.6Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 53.7247L38.3599 54.9722L38.3283 56.9317L35.8442 55.6779L35.8695 53.7247Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 51.8604L35.1378 53.1898L35.1 55.1555L32.6033 53.8135L32.6348 51.8604Z"
        fill="#8FBBD8"
      />
      <path
        d="M49.8533 52.5972L52.4824 51.2426V48.9303L49.8533 50.2975V52.5972Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 50.7195L56.1771 49.3649V47.0526L53.5417 48.4135V50.7195Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 48.5774L59.8534 47.2227V44.9167L57.218 46.2714V48.5774Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 46.5425L63.5924 45.1878V42.8818L60.957 44.2428V46.5425Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 56.384L52.4824 55.0294V52.7234L49.8533 54.0843V56.384Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 54.5064L56.1771 53.1455V50.8395L53.5417 52.2004V54.5064Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 52.3644L59.8534 51.0097V48.7037L57.218 50.0647V52.3644Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 50.335L63.5924 48.9741V46.6681L60.957 48.029V50.335Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 60.2589L52.4824 58.9043V56.5983L49.8533 57.9592V60.2589Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 58.3813L56.1771 57.0267V54.7207L53.5417 56.0753V58.3813Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 56.2455L59.8534 54.8846V52.5786L57.218 53.9332V56.2455Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 54.2104L63.5924 52.8558V50.5435L60.957 51.9044V54.2104Z"
        fill="#6F94A5"
      />
      <path
        d="M47.105 34.7605L65.7861 24.4906L49.7467 16.1487L31.0466 26.1287L47.105 34.7605Z"
        fill="#BEBEBE"
      />
      <path
        d="M62.9609 24.4782L49.3173 17.4657L33.0698 25.9903L46.726 33.3305L62.9609 24.4782Z"
        fill="#999999"
      />
      <path
        d="M31.0845 26.072L47.1428 34.7038L47.0608 50.1464L31.1349 41.8486L31.0845 26.072Z"
        fill="#F2F5FF"
      />
      <path
        d="M47.0229 50.2092L65.7104 39.9456L65.7861 24.4904L47.1049 34.7602L47.0229 50.2092Z"
        fill="#BCC5DC"
      />
      <path
        d="M39.4571 33.6199L41.8781 34.8737L41.8466 36.8331L39.4255 35.573L39.4571 33.6199Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 35.5671L45.3205 36.8209L45.2953 38.7804L42.8743 37.5203L42.9058 35.5671Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 31.6859L38.3599 32.9334L38.3283 34.8929L35.8442 33.6391L35.8695 31.6859Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 29.8142L35.1378 31.1562L35.1 33.1157L32.6033 31.7674L32.6348 29.8142Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 37.2305L41.8781 38.4843L41.8466 40.4437L39.4255 39.1836L39.4571 37.2305Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 39.1771L45.3205 40.4309L45.2953 42.3904L42.8743 41.1303L42.9058 39.1771Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 35.2959L38.3599 36.5434L38.3283 38.5029L35.8442 37.2491L35.8695 35.2959Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 33.4309L35.1378 34.7666L35.1 36.7261L32.6033 35.3841L32.6348 33.4309Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 41.0237L41.8781 42.2712L41.8466 44.237L39.4255 42.9705L39.4571 41.0237Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 42.9634L45.3205 44.2172L45.2953 46.1767L42.8743 44.9165L42.9058 42.9634Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 39.0828L38.3599 40.3303L38.3283 42.296L35.8442 41.0359L35.8695 39.0828Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 37.2178L35.1378 38.5535L35.1 40.513L32.6033 39.1709L32.6348 37.2178Z"
        fill="#8FBBD8"
      />
      <path
        d="M49.8533 37.9612L52.4824 36.6003V34.2943L49.8533 35.6552V37.9612Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 36.0771L56.1771 34.7225V32.4165L53.5417 33.7711V36.0771Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 33.9416L59.8534 32.5807V30.2747L57.218 31.6356V33.9416Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 31.9063L63.5924 30.5517V28.2457L60.957 29.6003V31.9063Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 41.7476L52.4824 40.393V38.0807L49.8533 39.4416V41.7476Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 39.8635L56.1771 38.5089V36.2029L53.5417 37.5575V39.8635Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 37.728L59.8534 36.367V34.061L57.218 35.422V37.728Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 35.6928L63.5924 34.3319V32.0322L60.957 33.3868V35.6928Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 45.6228L52.4824 44.2619V41.9622L49.8533 43.3168V45.6228Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 43.7389L56.1771 42.3842V40.0782L53.5417 41.4392V43.7389Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 41.6031L59.8534 40.2485V37.9425L57.218 39.2971V41.6031Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 39.5738L63.5924 38.2129V35.9069L60.957 37.2678V39.5738Z"
        fill="#6F94A5"
      />
      <path
        d="M47.1055 20.1178L65.7866 9.8479L49.7472 1.50598L31.0471 11.4923L47.1055 20.1178Z"
        fill="#C4D0D9"
      />
      <path
        d="M63.466 9.93605L49.8161 2.91724L33.575 11.4482L47.2375 18.7883L63.466 9.93605Z"
        fill="white"
      />
      <path
        d="M31.0845 11.4292L47.1428 20.061L47.0608 35.5099L31.1349 27.2058L31.0845 11.4292Z"
        fill="#F2F5FF"
      />
      <path
        d="M47.0229 35.5664L65.7104 25.3028L65.7861 9.84753L47.1049 20.1174L47.0229 35.5664Z"
        fill="#BCC5DC"
      />
      <path
        d="M39.4575 18.9839L41.8786 20.2314L41.8471 22.1972L39.426 20.9371L39.4575 18.9839Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 20.9244L45.3205 22.1782L45.2953 24.1377L42.8743 22.8776L42.9058 20.9244Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 17.0493L38.3599 18.2905L38.3283 20.2563L35.8442 18.9962L35.8695 17.0493Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 15.1781L35.1378 16.5138L35.1 18.4733L32.6033 17.1313L32.6348 15.1781Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 22.5939L41.8781 23.8414L41.8466 25.8072L39.4255 24.547L39.4571 22.5939Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 24.5344L45.3205 25.7882L45.2953 27.754L42.8743 26.4876L42.9058 24.5344Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 20.6598L38.3599 21.9073L38.3283 23.8668L35.8442 22.6067L35.8695 20.6598Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 18.7886L35.1378 20.1243L35.1 22.0901L32.6033 20.748L32.6348 18.7886Z"
        fill="#8FBBD8"
      />
      <path
        d="M39.4571 26.3807L41.8781 27.6345L41.8466 29.594L39.4255 28.3339L39.4571 26.3807Z"
        fill="#8FBBD8"
      />
      <path
        d="M42.9058 28.3215L45.3205 29.5753L45.2953 31.5411L42.8743 30.2747L42.9058 28.3215Z"
        fill="#8FBBD8"
      />
      <path
        d="M35.8695 24.4463L38.3599 25.6938L38.3283 27.6533L35.8442 26.3995L35.8695 24.4463Z"
        fill="#8FBBD8"
      />
      <path
        d="M32.6348 22.5751L35.1378 23.9171L35.1 25.8766L32.6033 24.5282L32.6348 22.5751Z"
        fill="#8FBBD8"
      />
      <path
        d="M49.8533 23.3183L52.4824 21.9637V19.6577L49.8533 21.0123V23.3183Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 21.4411L56.1771 20.0802V17.7742L53.5417 19.1351V21.4411Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 19.305L59.8534 17.9441V15.6381L57.218 16.999V19.305Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 17.2702L63.5924 15.9093V13.6033L60.957 14.9642V17.2702Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 27.1052L52.4824 25.7506V23.4446L49.8533 24.7992V27.1052Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 25.2278L56.1771 23.8669V21.5609L53.5417 22.9218V25.2278Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 23.092L59.8534 21.731V19.425L57.218 20.786V23.092Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 21.0566L63.5924 19.6956V17.3896L60.957 18.7506V21.0566Z"
        fill="#6F94A5"
      />
      <path
        d="M49.8533 30.98L52.4824 29.6253V27.3193L49.8533 28.6803V30.98Z"
        fill="#6F94A5"
      />
      <path
        d="M53.5417 29.1028L56.1771 27.7418V25.4421L53.5417 26.7968V29.1028Z"
        fill="#6F94A5"
      />
      <path
        d="M57.218 26.9603L59.8534 25.6057V23.2997L57.218 24.6606V26.9603Z"
        fill="#6F94A5"
      />
      <path
        d="M60.957 24.9316L63.5924 23.5706V21.2646L60.957 22.6256V24.9316Z"
        fill="#6F94A5"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        opacity="0.33"
        d="M43.9777 9.19906L43.3535 11.776L44.873 11.7382L50.5662 8.62571L47.4832 6.84265L43.9777 9.19906Z"
        fill="url(#paint5_linear_71_35067)"
      />
      <path
        d="M43.3466 10.8184L39.8916 8.97861L45.837 5.75903L49.2921 7.60509L43.3466 10.8184Z"
        fill="white"
      />
      <path
        d="M49.2931 8.51893L43.354 11.7385L43.3477 10.8186L49.2931 7.60535V8.51893Z"
        fill="#BCC5DC"
      />
      <path
        d="M43.3529 11.7382L39.8979 9.8921L39.8916 8.97852L43.3466 10.8183L43.3529 11.7382Z"
        fill="#F2F5FF"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        opacity="0.33"
        d="M50.8056 12.8597L50.1814 15.4366L51.6946 15.3988L57.3941 12.2864L54.311 10.5033L50.8056 12.8597Z"
        fill="url(#paint6_linear_71_35067)"
      />
      <path
        d="M50.1748 14.4791L46.7197 12.6331L52.6589 9.4198L56.1139 11.2659L50.1748 14.4791Z"
        fill="white"
      />
      <path
        d="M56.1212 12.1731L50.1821 15.399L50.1758 14.4791L56.1149 11.2659L56.1212 12.1731Z"
        fill="#BCC5DC"
      />
      <path
        d="M50.1811 15.3991L46.7197 13.5531V12.6332L50.1748 14.4792L50.1811 15.3991Z"
        fill="#F2F5FF"
      />
      <path
        d="M52.496 12.0281C52.4393 12.0281 52.395 12.0281 52.395 12.0974C52.395 12.1478 52.5653 12.1352 52.5842 12.0974C52.5905 12.0533 52.5464 12.0281 52.496 12.0281Z"
        fill="url(#paint7_radial_71_35067)"
      />
      <path
        d="M53.0063 12.0095C52.9496 12.0095 52.9055 12.0095 52.9055 12.0788C52.9055 12.1229 53.0821 12.1166 53.101 12.0788C53.101 12.041 53.0568 12.0095 53.0063 12.0095Z"
        fill="url(#paint8_radial_71_35067)"
      />
      <path
        d="M53.3974 11.902C53.347 11.902 53.3027 11.902 53.3027 11.9713C53.3027 12.0217 53.473 12.0091 53.4919 11.9713C53.4919 11.9272 53.4541 11.902 53.3974 11.902Z"
        fill="url(#paint9_radial_71_35067)"
      />
      <path
        d="M53.7313 11.694C53.6746 11.694 53.6304 11.694 53.6304 11.7633C53.6304 11.8137 53.8006 11.8074 53.8195 11.7633C53.8511 11.7255 53.7818 11.694 53.7313 11.694Z"
        fill="url(#paint10_radial_71_35067)"
      />
      <path
        d="M53.9329 11.4292C53.8761 11.4292 53.832 11.4607 53.832 11.4985C53.8636 11.5426 54.0086 11.5363 54.0275 11.4985C54.0275 11.4607 53.9833 11.4292 53.9329 11.4292Z"
        fill="url(#paint11_radial_71_35067)"
      />
      <path
        d="M53.9274 11.1646C53.8706 11.1646 53.8264 11.1961 53.8264 11.2339C53.8537 11.257 53.8884 11.2696 53.9242 11.2696C53.9599 11.2696 53.9946 11.257 54.0219 11.2339C54.0219 11.215 53.9778 11.1646 53.9274 11.1646Z"
        fill="url(#paint12_radial_71_35067)"
      />
      <path
        d="M53.7747 10.957C53.7116 10.957 53.6738 10.9885 53.6738 11.0263C53.6738 11.0767 53.8441 11.0704 53.863 11.0263C53.8599 11.0057 53.8489 10.987 53.8325 10.9742C53.8161 10.9613 53.7954 10.9551 53.7747 10.957Z"
        fill="url(#paint13_radial_71_35067)"
      />
      <path
        d="M53.5166 10.7618C53.4598 10.7618 53.4158 10.7996 53.4158 10.8311C53.4158 10.8311 53.4158 10.8626 53.4158 10.8311C53.4158 10.8311 53.5923 10.8753 53.6049 10.8311C53.6112 10.7996 53.567 10.7618 53.5166 10.7618Z"
        fill="url(#paint14_radial_71_35067)"
      />
      <path
        d="M53.2207 10.6611C53.164 10.6611 53.1262 10.6926 53.1262 10.7304C53.1262 10.7808 53.2963 10.7682 53.3216 10.7304C53.3279 10.6926 53.2838 10.6611 53.2207 10.6611Z"
        fill="url(#paint15_radial_71_35067)"
      />
      <path
        d="M52.8545 10.5917C52.7978 10.5917 52.76 10.5917 52.76 10.661C52.76 10.7114 52.9303 10.7051 52.9492 10.661C52.9492 10.6232 52.905 10.5917 52.8545 10.5917Z"
        fill="url(#paint16_radial_71_35067)"
      />
      <path
        d="M52.4446 10.5665C52.3878 10.5665 52.3438 10.5665 52.3438 10.6358C52.3438 10.6799 52.5202 10.6736 52.5391 10.6358C52.5391 10.598 52.495 10.5665 52.4446 10.5665Z"
        fill="url(#paint17_radial_71_35067)"
      />
      <path
        d="M52.0672 10.5974C52.0104 10.5974 51.9727 10.6289 51.9727 10.6667C51.9727 10.7108 52.1492 10.7045 52.1618 10.6667C52.1618 10.6289 52.1239 10.5974 52.0672 10.5974Z"
        fill="url(#paint18_radial_71_35067)"
      />
      <path
        d="M51.7392 10.6859C51.6825 10.6859 51.6384 10.6859 51.6384 10.7552C51.67 10.8056 51.815 10.7993 51.8339 10.7552C51.8339 10.7174 51.7897 10.6859 51.7392 10.6859Z"
        fill="url(#paint19_radial_71_35067)"
      />
      <path
        d="M51.4366 10.8433C51.3861 10.8433 51.342 10.8433 51.342 10.9126C51.342 10.9567 51.5186 10.9504 51.5375 10.9126C51.5375 10.8748 51.4933 10.8433 51.4366 10.8433Z"
        fill="url(#paint20_radial_71_35067)"
      />
      <path
        d="M51.2101 11.0514C51.1534 11.0514 51.1091 11.0829 51.1091 11.1207C51.1091 11.1711 51.2794 11.1648 51.3046 11.1207C51.3298 11.0829 51.2605 11.0514 51.2101 11.0514Z"
        fill="url(#paint21_radial_71_35067)"
      />
      <path
        d="M51.1962 11.3596C51.1331 11.3596 51.0952 11.3911 51.0952 11.4289C51.0952 11.473 51.2718 11.4667 51.2844 11.4289C51.2907 11.3911 51.2466 11.3596 51.1962 11.3596Z"
        fill="url(#paint22_radial_71_35067)"
      />
      <path
        d="M51.3286 11.631C51.2782 11.631 51.2341 11.631 51.2341 11.694C51.2341 11.7444 51.4042 11.7381 51.4231 11.694C51.4042 11.6562 51.3665 11.631 51.3286 11.631Z"
        fill="url(#paint23_radial_71_35067)"
      />
      <path
        d="M51.6065 11.8584C51.556 11.8584 51.512 11.8899 51.512 11.9277C51.512 11.9718 51.6885 11.9655 51.7011 11.9277C51.7011 11.8899 51.6569 11.8584 51.6065 11.8584Z"
        fill="url(#paint24_radial_71_35067)"
      />
      <path
        d="M52.0109 11.99C51.9541 11.99 51.9099 11.99 51.9099 12.053C51.9099 12.0971 52.0865 12.0908 52.1054 12.053C52.1054 12.0152 52.0613 11.99 52.0109 11.99Z"
        fill="url(#paint25_radial_71_35067)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        opacity="0.33"
        d="M50.1504 12.6074L49.8667 13.8045L50.5665 13.7793L51.6825 13.1745L50.2513 12.3491L50.1504 12.6074Z"
        fill="url(#paint26_linear_71_35067)"
      />
      <path
        d="M49.8789 12.7589L50.5346 12.406L48.845 11.5114L48.1956 11.8642L49.8789 12.7589Z"
        fill="white"
      />
      <path
        d="M48.1956 11.8638L49.8789 12.7584V13.8169L48.1956 12.916V11.8638Z"
        fill="#F2F5FF"
      />
      <path
        d="M49.8792 13.8173L50.5349 13.4645V12.406L49.8792 12.7588V13.8173Z"
        fill="#BCC5DC"
      />
      <path
        d="M45.6555 8.36743C45.5988 8.36743 45.5547 8.39893 45.5547 8.43674C45.5547 8.48714 45.7248 8.48084 45.7437 8.43674C45.7626 8.39893 45.7186 8.36743 45.6555 8.36743Z"
        fill="url(#paint27_radial_71_35067)"
      />
      <path
        d="M46.1782 8.34875C46.1214 8.34875 46.0835 8.38026 46.0835 8.41806C46.0835 8.46847 46.2538 8.46217 46.2727 8.41806C46.2853 8.38026 46.2349 8.34875 46.1782 8.34875Z"
        fill="url(#paint28_radial_71_35067)"
      />
      <path
        d="M46.5693 8.24158C46.5189 8.24158 46.4685 8.24158 46.4685 8.30459C46.4685 8.34869 46.6388 8.34869 46.6577 8.30459C46.6703 8.27308 46.6261 8.24158 46.5693 8.24158Z"
        fill="url(#paint29_radial_71_35067)"
      />
      <path
        d="M46.9163 8.01453C46.8658 8.01453 46.8218 8.04603 46.8218 8.08383C46.8218 8.13424 46.9982 8.12794 47.0171 8.08383C46.9982 8.06493 46.9541 8.01453 46.9163 8.01453Z"
        fill="url(#paint30_radial_71_35067)"
      />
      <path
        d="M47.1054 7.76855C47.0487 7.76855 47.0107 7.76856 47.0107 7.83786C47.0107 7.88197 47.181 7.87566 47.2062 7.83786C47.2062 7.80006 47.1621 7.76855 47.1054 7.76855Z"
        fill="url(#paint31_radial_71_35067)"
      />
      <path
        d="M47.0988 7.52917C47.042 7.52917 46.9978 7.52918 46.9978 7.59218C46.9978 7.63629 47.1681 7.62999 47.187 7.59218C47.1933 7.55438 47.1492 7.52917 47.0988 7.52917Z"
        fill="url(#paint32_radial_71_35067)"
      />
      <path
        d="M46.9163 7.29651C46.8658 7.29651 46.8218 7.29651 46.8218 7.35952C46.8218 7.40992 46.9982 7.40362 47.0171 7.35952C47.0423 7.32802 46.9982 7.29651 46.9163 7.29651Z"
        fill="url(#paint33_radial_71_35067)"
      />
      <path
        d="M46.6887 7.1073C46.6383 7.1073 46.5942 7.1073 46.5942 7.17031C46.5942 7.22071 46.7645 7.21441 46.7834 7.17031C46.7897 7.13251 46.7455 7.1073 46.6887 7.1073Z"
        fill="url(#paint34_radial_71_35067)"
      />
      <path
        d="M46.3982 6.99976C46.3414 6.99976 46.2974 7.03126 46.2974 7.06906C46.3266 7.09056 46.3619 7.10215 46.3982 7.10215C46.4345 7.10215 46.4699 7.09056 46.4992 7.06906C46.4992 7.03126 46.4486 6.99976 46.3982 6.99976Z"
        fill="url(#paint35_radial_71_35067)"
      />
      <path
        d="M46.0272 6.93079C45.9768 6.93079 45.9263 6.96229 45.9263 7.00009C45.9263 7.0505 46.1028 7.0442 46.1218 7.00009C46.1218 6.96229 46.0777 6.93079 46.0272 6.93079Z"
        fill="url(#paint36_radial_71_35067)"
      />
      <path
        d="M45.617 6.90552C45.5665 6.90552 45.5225 6.90551 45.5225 6.96852C45.5225 7.01892 45.6927 7.00632 45.7116 6.96852C45.7116 6.93701 45.6548 6.90552 45.617 6.90552Z"
        fill="url(#paint37_radial_71_35067)"
      />
      <path
        d="M45.2453 6.93701C45.1885 6.93701 45.1443 6.96851 45.1443 7.00632C45.1443 7.05042 45.3209 7.04412 45.3398 7.00632C45.3398 6.96851 45.2957 6.93701 45.2453 6.93701Z"
        fill="url(#paint38_radial_71_35067)"
      />
      <path
        d="M44.9108 7.02515C44.8604 7.02515 44.8098 7.02515 44.8098 7.09445C44.8391 7.11595 44.8745 7.12754 44.9108 7.12754C44.9471 7.12754 44.9824 7.11595 45.0116 7.09445C45.0242 7.05665 44.9675 7.02515 44.9108 7.02515Z"
        fill="url(#paint39_radial_71_35067)"
      />
      <path
        d="M44.6081 7.18298C44.5577 7.18298 44.5134 7.18298 44.5134 7.25229C44.5134 7.25229 44.5134 7.3216 44.5134 7.25229C44.5134 7.25229 44.6837 7.29009 44.7026 7.25229C44.7089 7.21449 44.6648 7.18298 44.6081 7.18298Z"
        fill="url(#paint40_radial_71_35067)"
      />
      <path
        d="M44.394 7.38477C44.3373 7.38477 44.2932 7.41627 44.2932 7.45407C44.3215 7.47504 44.3558 7.48636 44.391 7.48636C44.4262 7.48636 44.4603 7.47504 44.4885 7.45407C44.4822 7.42257 44.4319 7.38477 44.394 7.38477Z"
        fill="url(#paint41_radial_71_35067)"
      />
      <path
        d="M44.3933 7.69971C44.3429 7.69971 44.2988 7.73121 44.2988 7.76901C44.2988 7.81942 44.4752 7.81312 44.4942 7.76901C44.4626 7.73121 44.3933 7.69971 44.3933 7.69971Z"
        fill="url(#paint42_radial_71_35067)"
      />
      <path
        d="M44.482 7.97046C44.4315 7.97046 44.3875 7.97046 44.3875 8.03977C44.3875 8.09017 44.564 8.08387 44.5829 8.03977C44.5829 8.01456 44.5387 7.97046 44.482 7.97046Z"
        fill="url(#paint43_radial_71_35067)"
      />
      <path
        d="M44.7853 8.19739C44.7286 8.19739 44.6843 8.22889 44.6843 8.26669C44.7159 8.3108 44.8609 8.3045 44.8798 8.26669C44.8798 8.22889 44.8357 8.19739 44.7853 8.19739Z"
        fill="url(#paint44_radial_71_35067)"
      />
      <path
        d="M45.1824 8.32324C45.1257 8.32324 45.0879 8.32324 45.0879 8.39255C45.0879 8.43665 45.258 8.43035 45.2769 8.39255C45.2769 8.35475 45.2391 8.32324 45.1824 8.32324Z"
        fill="url(#paint45_radial_71_35067)"
      />
      <path
        style={{ mixBlendMode: "multiply" }}
        opacity="0.33"
        d="M43.3286 8.9468L43.0386 10.1439L43.7447 10.125L44.8544 9.51385L43.4295 8.68848L43.3286 8.9468Z"
        fill="url(#paint46_linear_71_35067)"
      />
      <path
        d="M43.0506 9.09834L43.7063 8.74551L42.0229 7.85083L41.3672 8.20366L43.0506 9.09834Z"
        fill="white"
      />
      <path
        d="M41.3672 8.20374L43.0506 9.09841L43.0569 10.1506L41.3672 9.25593V8.20374Z"
        fill="#F2F5FF"
      />
      <path
        d="M43.0571 10.1508L43.7065 9.79792V8.74573L43.0508 9.09856L43.0571 10.1508Z"
        fill="#BCC5DC"
      />
      <path
        d="M32.0801 62.6906L24.6387 66.7639L24.6462 75.0312L32.0951 70.9579L32.0801 62.6906Z"
        fill="#006ABA"
      />
      <path
        d="M34.5159 67.7072L35.5644 71.3505L29.6581 74.5866L28.9717 70.7395L34.5159 67.7072Z"
        fill="#006ABA"
      />
      <path
        d="M24.6385 66.7638L24.6461 75.0311L12.988 68.6458L12.9994 60.3822L24.6385 66.7638Z"
        fill="#004787"
      />
      <path
        d="M32.0788 62.6907L24.6375 66.764L12.9983 60.3825L20.3982 56.2827L32.0788 62.6907Z"
        fill="#0095E0"
      />
      <path
        d="M34.5162 67.7068L28.972 70.7391L25.7661 68.9816L31.3066 65.9417L34.5162 67.7068Z"
        fill="#0095E0"
      />
      <path
        d="M28.972 70.7389L29.6584 74.5859V77.7843L25.7661 75.642V68.9813L28.972 70.7389Z"
        fill="#004787"
      />
      <path
        d="M34.4148 68.1899L35.3049 71.1507L29.8662 74.1302L29.2778 70.9998L34.4148 68.1899Z"
        fill="#F8F1F0"
      />
      <path
        d="M29.6438 78.1732L29.6589 74.5864L35.5652 71.3503L35.5426 74.9183L29.6438 78.1732Z"
        fill="#006ABA"
      />
      <path
        d="M29.643 77.3624V78.1733L28.6436 77.634V76.8156L29.643 77.3624Z"
        fill="#333333"
      />
      <path
        d="M28.2973 76.5397C28.2407 75.0801 27.4185 73.7072 26.4379 73.4696C25.6081 73.2659 24.9292 73.9485 24.7935 75.0423L28.1879 77.4976C28.2695 77.1851 28.3063 76.8626 28.2973 76.5397Z"
        fill="#333333"
      />
      <path
        d="M28.5198 76.2869C28.4632 74.9215 27.6976 73.6468 26.6868 73.4318C26.4615 73.3867 26.2295 73.3867 26.0042 73.4318C26.1497 73.436 26.2946 73.4512 26.4379 73.477C27.4185 73.7147 28.2219 75.0309 28.2671 76.4943C28.2833 76.8374 28.2541 77.181 28.1804 77.5164L28.4783 77.309C28.5303 76.9709 28.5442 76.6281 28.5198 76.2869Z"
        fill="#333333"
      />
      <path
        d="M27.9122 76.4459C27.9462 77.5773 27.354 78.3656 26.5884 78.1732C25.8228 77.9809 25.1778 76.9098 25.1363 75.7707C25.0948 74.6317 25.6908 73.8548 26.4639 74.0434C27.2371 74.2319 27.8708 75.3068 27.9122 76.4459Z"
        fill="white"
      />
      <path
        d="M27.3129 76.2987C27.3129 76.9474 26.9923 77.3887 26.5586 77.2869C26.1249 77.185 25.7515 76.5665 25.7289 75.914C25.7062 75.2615 26.0457 74.8278 26.4832 74.9296C26.9207 75.0314 27.2828 75.65 27.3129 76.2987Z"
        fill="#101012"
      />
      <path
        d="M27.2188 78.0976L28.1805 77.4979L27.8335 77.2301C27.6874 77.5567 27.4785 77.8515 27.2188 78.0976Z"
        fill="#333333"
      />
      <path
        d="M28.4929 70.8826L29.0775 73.8772L26.3845 72.4365V69.8228L28.4929 70.8826Z"
        fill="#F8F1F0"
      />
      <path
        d="M18.2757 70.8408C18.2267 69.3812 17.4007 68.0045 16.4125 67.7632C15.5903 67.5708 14.8699 68.4948 14.7078 69.5924L18.1512 71.7988C18.2354 71.4864 18.2773 71.1643 18.2757 70.8408Z"
        fill="#333333"
      />
      <path
        d="M18.4987 70.5886C18.4383 69.2158 17.6727 67.9485 16.6619 67.7298C16.4344 67.6807 16.1991 67.6807 15.9717 67.7298C16.1196 67.7237 16.2677 67.7351 16.413 67.7637C17.4011 68.0051 18.1969 69.3176 18.246 70.781C18.2602 71.1269 18.2285 71.4731 18.1517 71.8106L18.4572 71.5994C18.505 71.2647 18.5189 70.9261 18.4987 70.5886Z"
        fill="#333333"
      />
      <path
        d="M17.8879 70.7434C17.9219 71.8749 17.3297 72.6631 16.5641 72.4708C15.7985 72.2784 15.1498 71.2148 15.112 70.0682C15.0743 68.9217 15.6702 68.1523 16.4321 68.3371C17.194 68.5219 17.8502 69.6006 17.8879 70.7434Z"
        fill="white"
      />
      <path
        d="M17.2728 70.5998C17.2954 71.2485 16.9598 71.686 16.5185 71.5766C16.0772 71.4672 15.7076 70.8676 15.6887 70.2151C15.6699 69.5626 16.0093 69.1213 16.4431 69.2269C16.8768 69.3325 17.2728 69.9511 17.2728 70.5998Z"
        fill="#101012"
      />
      <path
        d="M17.1938 72.3987L18.1519 71.799L17.8011 71.535C17.656 71.8593 17.4499 72.1525 17.1938 72.3987Z"
        fill="#333333"
      />
      <path
        d="M23.6086 68.3376L16.1672 72.411L16.1748 80.6783L23.6237 76.605L23.6086 68.3376Z"
        fill="#006ABA"
      />
      <path
        d="M26.045 73.3538L27.0935 76.9971L21.1872 80.2332L20.5007 76.3861L26.045 73.3538Z"
        fill="#006ABA"
      />
      <path
        d="M16.1671 72.4107L16.1746 80.6781L4.5166 74.2928L4.52792 66.0292L16.1671 72.4107Z"
        fill="#004787"
      />
      <path
        d="M23.6081 68.3379L16.1668 72.4112L4.52759 66.0297L11.9275 61.9299L23.6081 68.3379Z"
        fill="#0095E0"
      />
      <path
        d="M26.0453 73.3539L20.501 76.3862L17.2952 74.6287L22.8357 71.5887L26.0453 73.3539Z"
        fill="#0095E0"
      />
      <path
        d="M20.501 76.3859L21.1875 80.2329V83.4312L17.2952 81.289V74.6283L20.501 76.3859Z"
        fill="#004787"
      />
      <path
        d="M25.9438 73.8372L26.8339 76.7979L21.3953 79.7774L20.8069 76.647L25.9438 73.8372Z"
        fill="#F8F1F0"
      />
      <path
        d="M21.1726 83.8203L21.1877 80.2335L27.094 76.9974L27.0714 80.5654L21.1726 83.8203Z"
        fill="#006ABA"
      />
      <path
        d="M21.1721 83.0095V83.8204L20.1726 83.2811V82.4626L21.1721 83.0095Z"
        fill="#333333"
      />
      <path
        d="M19.8263 82.1869C19.7698 80.7273 18.9475 79.3544 17.9669 79.1168C17.1372 78.9131 16.4583 79.5958 16.3225 80.6895L19.717 83.1448C19.7986 82.8323 19.8354 82.5098 19.8263 82.1869Z"
        fill="#333333"
      />
      <path
        d="M20.0484 81.934C19.9918 80.5686 19.2262 79.2939 18.2154 79.0789C17.9901 79.0338 17.758 79.0338 17.5327 79.0789C17.6782 79.0831 17.8232 79.0983 17.9664 79.1241C18.9471 79.3617 19.7504 80.678 19.7957 82.1414C19.8119 82.4845 19.7827 82.8281 19.7089 83.1635L20.0069 82.9561C20.0589 82.618 20.0728 82.2751 20.0484 81.934Z"
        fill="#333333"
      />
      <path
        d="M19.441 82.093C19.475 83.2244 18.8828 84.0127 18.1172 83.8203C17.3516 83.628 16.7066 82.5569 16.6651 81.4178C16.6236 80.2788 17.2196 79.5019 17.9927 79.6904C18.7659 79.879 19.3996 80.9539 19.441 82.093Z"
        fill="white"
      />
      <path
        d="M18.842 81.9454C18.842 82.5941 18.5214 83.0354 18.0877 82.9336C17.6539 82.8318 17.2805 82.2132 17.2579 81.5607C17.2353 80.9082 17.5747 80.4745 18.0122 80.5763C18.4497 80.6782 18.8118 81.2967 18.842 81.9454Z"
        fill="#101012"
      />
      <path
        d="M18.7478 83.7447L19.7096 83.145L19.3626 82.8772C19.2165 83.2038 19.0076 83.4986 18.7478 83.7447Z"
        fill="#333333"
      />
      <path
        d="M20.0219 76.5298L20.6065 79.5244L17.9136 78.0837V75.47L20.0219 76.5298Z"
        fill="#F8F1F0"
      />
      <path
        d="M9.80425 76.4879C9.75522 75.0283 8.92924 73.6516 7.94108 73.4103C7.11887 73.2179 6.39851 74.1419 6.23633 75.2395L9.67981 77.4459C9.76399 77.1335 9.80584 76.8114 9.80425 76.4879Z"
        fill="#333333"
      />
      <path
        d="M10.0275 76.2356C9.96713 74.8628 9.20149 73.5955 8.1907 73.3768C7.96325 73.3277 7.72794 73.3277 7.50049 73.3768C7.6484 73.3707 7.79651 73.3821 7.94176 73.4107C8.92992 73.6521 9.72575 74.9646 9.77478 76.428C9.78897 76.7738 9.75726 77.1201 9.68049 77.4576L9.98599 77.2464C10.0338 76.9117 10.0477 76.5731 10.0275 76.2356Z"
        fill="#333333"
      />
      <path
        d="M9.41724 76.3903C9.45119 77.5218 8.85904 78.3101 8.09341 78.1177C7.32777 77.9254 6.67906 76.8618 6.64134 75.7152C6.60363 74.5686 7.19954 73.7992 7.96141 73.9841C8.72327 74.1689 9.37953 75.2475 9.41724 76.3903Z"
        fill="white"
      />
      <path
        d="M8.80187 76.2469C8.8245 76.8956 8.48883 77.3331 8.04755 77.2237C7.60627 77.1143 7.23665 76.5147 7.21779 75.8622C7.19893 75.2097 7.53838 74.7684 7.97211 74.874C8.40585 74.9796 8.80187 75.5982 8.80187 76.2469Z"
        fill="#101012"
      />
      <path
        d="M8.72241 78.0458L9.68042 77.4461L9.32966 77.1821C9.18461 77.5064 8.97843 77.7996 8.72241 78.0458Z"
        fill="#333333"
      />
      <path
        d="M51.28 73.9847L43.8386 78.0581L43.8462 86.3254L51.2951 82.2521L51.28 73.9847Z"
        fill="#006ABA"
      />
      <path
        d="M53.7161 79.0011L54.7646 82.6445L48.8583 85.8805L48.1719 82.0335L53.7161 79.0011Z"
        fill="#006ABA"
      />
      <path
        d="M43.8385 78.058L43.846 86.3253L32.188 79.94L32.1993 71.6764L43.8385 78.058Z"
        fill="#004787"
      />
      <path
        d="M51.2788 73.9846L43.8374 78.058L32.1982 71.6764L39.5981 67.5767L51.2788 73.9846Z"
        fill="#0095E0"
      />
      <path
        d="M53.7167 79.0011L48.1724 82.0334L44.9666 80.2759L50.507 77.236L53.7167 79.0011Z"
        fill="#0095E0"
      />
      <path
        d="M48.1724 82.0331L48.8589 85.8801V89.0785L44.9666 86.9362V80.2755L48.1724 82.0331Z"
        fill="#004787"
      />
      <path
        d="M53.615 79.4843L54.5051 82.445L49.0664 85.4245L48.478 82.2941L53.615 79.4843Z"
        fill="#F8F1F0"
      />
      <path
        d="M48.8435 89.4674L48.8586 85.8806L54.7649 82.6445L54.7423 86.2125L48.8435 89.4674Z"
        fill="#006ABA"
      />
      <path
        d="M48.8432 88.6569V89.4678L47.8438 88.9284V88.11L48.8432 88.6569Z"
        fill="#333333"
      />
      <path
        d="M47.4972 87.8341C47.4407 86.3745 46.6184 85.0016 45.6378 84.764C44.8081 84.5603 44.1292 85.243 43.9934 86.3367L47.3879 88.7921C47.4695 88.4795 47.5063 88.157 47.4972 87.8341Z"
        fill="#333333"
      />
      <path
        d="M47.7195 87.5813C47.6629 86.216 46.8973 84.9412 45.8865 84.7262C45.6612 84.6811 45.4292 84.6811 45.2039 84.7262C45.3494 84.7305 45.4943 84.7456 45.6376 84.7715C46.6182 85.0091 47.4216 86.3254 47.4668 87.7888C47.483 88.1318 47.4539 88.4755 47.3801 88.8109L47.678 88.6034C47.73 88.2653 47.7439 87.9225 47.7195 87.5813Z"
        fill="#333333"
      />
      <path
        d="M47.1124 87.7403C47.1464 88.8718 46.5542 89.66 45.7886 89.4677C45.023 89.2753 44.378 88.2042 44.3365 87.0652C44.295 85.9261 44.8909 85.1492 45.6641 85.3378C46.4373 85.5264 47.0709 86.6013 47.1124 87.7403Z"
        fill="white"
      />
      <path
        d="M46.5129 87.5926C46.5129 88.2414 46.1923 88.6826 45.7586 88.5808C45.3248 88.479 44.9514 87.8604 44.9288 87.2079C44.9062 86.5554 45.2456 86.1217 45.6831 86.2235C46.1206 86.3254 46.4827 86.9439 46.5129 87.5926Z"
        fill="#101012"
      />
      <path
        d="M46.4189 89.3919L47.3807 88.7922L47.0337 88.5244C46.8876 88.8511 46.6787 89.1458 46.4189 89.3919Z"
        fill="#333333"
      />
      <path
        d="M47.6928 82.177L48.2774 85.1717L45.5845 83.7309V81.1172L47.6928 82.177Z"
        fill="#F8F1F0"
      />
      <path
        d="M37.4754 82.1351C37.4264 80.6755 36.6004 79.2989 35.6122 79.0575C34.79 78.8651 34.0696 79.7892 33.9075 80.8867L37.351 83.0931C37.4351 82.7808 37.477 82.4586 37.4754 82.1351Z"
        fill="#333333"
      />
      <path
        d="M37.6989 81.883C37.6385 80.5101 36.8729 79.2429 35.8621 79.0241C35.6346 78.975 35.3993 78.975 35.1719 79.0241C35.3198 79.0181 35.4679 79.0294 35.6131 79.058C36.6013 79.2994 37.3971 80.6119 37.4462 82.0753C37.4604 82.4212 37.4286 82.7674 37.3519 83.105L37.6574 82.8937C37.7052 82.559 37.719 82.2204 37.6989 81.883Z"
        fill="#333333"
      />
      <path
        d="M37.0879 82.0376C37.1218 83.169 36.5297 83.9573 35.7641 83.7649C34.9984 83.5726 34.3497 82.509 34.312 81.3624C34.2743 80.2159 34.8702 79.4465 35.6321 79.6313C36.3939 79.8161 37.0502 80.8948 37.0879 82.0376Z"
        fill="white"
      />
      <path
        d="M36.4733 81.8941C36.4959 82.5428 36.1602 82.9803 35.7189 82.8709C35.2777 82.7616 34.908 82.1619 34.8892 81.5094C34.8703 80.8569 35.2098 80.4156 35.6435 80.5212C36.0772 80.6268 36.4733 81.2454 36.4733 81.8941Z"
        fill="#101012"
      />
      <path
        d="M36.3938 83.6931L37.3518 83.0935L37.001 82.8295C36.856 83.1537 36.6498 83.4469 36.3938 83.6931Z"
        fill="#333333"
      />
      <path
        d="M42.8086 79.632L35.3672 83.7053L35.3747 91.9727L42.8237 87.8993L42.8086 79.632Z"
        fill="#006ABA"
      />
      <path
        d="M45.2452 84.6481L46.2937 88.2914L40.3874 91.5275L39.7009 87.6804L45.2452 84.6481Z"
        fill="#006ABA"
      />
      <path
        d="M35.3675 83.705L35.3751 91.9724L23.717 85.5871L23.7284 77.3235L35.3675 83.705Z"
        fill="#004787"
      />
      <path
        d="M42.8081 79.6317L35.3667 83.7051L23.7275 77.3235L31.1274 73.2238L42.8081 79.6317Z"
        fill="#0095E0"
      />
      <path
        d="M45.245 84.6482L39.7007 87.6805L36.4949 85.923L42.0354 82.8831L45.245 84.6482Z"
        fill="#0095E0"
      />
      <path
        d="M39.7007 87.6801L40.3872 91.5271V94.7254L36.4949 92.5831V85.9225L39.7007 87.6801Z"
        fill="#004787"
      />
      <path
        d="M45.1438 85.1311L46.0339 88.0918L40.5952 91.0714L40.0068 87.941L45.1438 85.1311Z"
        fill="#F8F1F0"
      />
      <path
        d="M40.373 95.1143L40.3881 91.5275L46.2945 88.2915L46.2718 91.8594L40.373 95.1143Z"
        fill="#006ABA"
      />
      <path
        d="M40.3723 94.3036V95.1145L39.3728 94.5752V93.7567L40.3723 94.3036Z"
        fill="#333333"
      />
      <path
        d="M39.0268 93.4811C38.9702 92.0215 38.148 90.6486 37.1674 90.411C36.3376 90.2073 35.6587 90.8899 35.5229 91.9837L38.9174 94.439C38.999 94.1265 39.0358 93.804 39.0268 93.4811Z"
        fill="#333333"
      />
      <path
        d="M39.2486 93.2282C39.192 91.8628 38.4264 90.588 37.4156 90.3731C37.1903 90.328 36.9582 90.328 36.7329 90.3731C36.8784 90.3773 37.0234 90.3925 37.1666 90.4183C38.1472 90.6559 38.9506 91.9722 38.9959 93.4356C39.0121 93.7786 38.9829 94.1223 38.9091 94.4577L39.2071 94.2502C39.2591 93.9122 39.273 93.5693 39.2486 93.2282Z"
        fill="#333333"
      />
      <path
        d="M38.641 93.3869C38.6749 94.5184 38.0828 95.3066 37.3172 95.1143C36.5515 94.9219 35.9066 93.8508 35.8651 92.7118C35.8236 91.5727 36.4195 90.7958 37.1927 90.9844C37.9659 91.173 38.5995 92.2479 38.641 93.3869Z"
        fill="white"
      />
      <path
        d="M38.0427 93.2394C38.0427 93.8881 37.7221 94.3294 37.2883 94.2275C36.8546 94.1257 36.4812 93.5071 36.4586 92.8547C36.436 92.2022 36.7754 91.7684 37.2129 91.8703C37.6504 91.9721 38.0125 92.5907 38.0427 93.2394Z"
        fill="#101012"
      />
      <path
        d="M37.9475 95.0389L38.9093 94.4392L38.5623 94.1714C38.4162 94.498 38.2073 94.7928 37.9475 95.0389Z"
        fill="#333333"
      />
      <path
        d="M39.2223 87.8239L39.8069 90.8185L37.114 89.3778V86.764L39.2223 87.8239Z"
        fill="#F8F1F0"
      />
      <path
        d="M29.0045 87.7821C28.9554 86.3225 28.1294 84.9458 27.1413 84.7044C26.3191 84.5121 25.5987 85.4361 25.4365 86.5337L28.88 88.7401C28.9642 88.4277 29.006 88.1056 29.0045 87.7821Z"
        fill="#333333"
      />
      <path
        d="M29.2274 87.5299C29.1671 86.1571 28.4014 84.8898 27.3907 84.6711C27.1632 84.622 26.9279 84.622 26.7004 84.6711C26.8484 84.665 26.9965 84.6764 27.1417 84.705C28.1299 84.9464 28.9257 86.2589 28.9747 87.7223C28.9889 88.0682 28.9572 88.4144 28.8804 88.7519L29.1859 88.5407C29.2337 88.206 29.2476 87.8674 29.2274 87.5299Z"
        fill="#333333"
      />
      <path
        d="M28.6177 87.6845C28.6516 88.816 28.0595 89.6043 27.2938 89.4119C26.5282 89.2196 25.8795 88.156 25.8418 87.0094C25.8041 85.8628 26.4 85.0934 27.1618 85.2782C27.9237 85.4631 28.58 86.5417 28.6177 87.6845Z"
        fill="white"
      />
      <path
        d="M28.0016 87.5412C28.0242 88.1899 27.6885 88.6274 27.2473 88.518C26.806 88.4086 26.4364 87.809 26.4175 87.1565C26.3986 86.504 26.7381 86.0627 27.1718 86.1683C27.6056 86.2739 28.0016 86.8925 28.0016 87.5412Z"
        fill="#101012"
      />
      <path
        d="M27.9224 89.3401L28.8804 88.7404L28.5296 88.4764C28.3846 88.8007 28.1784 89.0939 27.9224 89.3401Z"
        fill="#333333"
      />
      <defs>
        <linearGradient
          id="paint0_linear_71_35067"
          x1="16.3762"
          y1="82.8238"
          x2="29.3644"
          y2="72.3767"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168009" stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_71_35067"
          x1="24.8469"
          y1="77.1769"
          x2="37.8351"
          y2="66.7299"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168009" stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_71_35067"
          x1="44.0466"
          y1="88.4707"
          x2="57.0348"
          y2="78.0237"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168009" stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_71_35067"
          x1="35.5764"
          y1="94.1178"
          x2="48.5646"
          y2="83.6708"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168009" stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_71_35067"
          x1="44.728"
          y1="74.4477"
          x2="94.2962"
          y2="50.3659"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_71_35067"
          x1="442.288"
          y1="80.2012"
          x2="521.732"
          y2="23.7289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_71_35067"
          x1="527.229"
          y1="112.475"
          x2="606.708"
          y2="56.0646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(556.97 -98.0019) scale(0.0607293 0.0328163)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(572.807 -95.3595) scale(0.0645095 0.0330222)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(556.547 -98.1589) scale(0.0605363 0.0328166)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(578.077 -100.814) scale(0.0630744 0.0335315)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(574.033 -96.0719) scale(0.064511 0.0330222)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(574.023 -101.77) scale(0.064511 0.0346732)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(557.041 -102.072) scale(0.0605377 0.0336339)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(558.287 -84.8857) scale(0.0626257 0.0293395)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(574.288 -99.6888) scale(0.0646574 0.0338418)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(555.83 -102.18) scale(0.0605349 0.0335315)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(571.669 -97.1315) scale(0.0625064 0.0320211)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(554.818 -97.0938) scale(0.0605363 0.0320215)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(571.145 -102.064) scale(0.0645095 0.0345797)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(570.757 -96.792) scale(0.0625547 0.0320215)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(584.67 -101.6) scale(0.0662753 0.0345761)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(555.268 -96.1587) scale(0.0607279 0.0320218)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(553.428 -94.1202) scale(0.0604853 0.0315134)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(554.207 -95.5459) scale(0.0605349 0.0320218)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(571.501 -88.6264) scale(0.0625547 0.0300062)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <linearGradient
          id="paint26_linear_71_35067"
          x1="169.762"
          y1="39.8713"
          x2="175.115"
          y2="36.6346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <radialGradient
          id="paint27_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.6114 5.00732) scale(0.201628 0.141134)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(552.331 -104.961) scale(0.0611609 0.0335318)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(553.382 -95.6845) scale(0.0631403 0.0317088)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(564.432 -105.37) scale(0.0625064 0.0335317)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(565.083 -100.569) scale(0.0645095 0.0330222)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(549.948 -94.0428) scale(0.0607293 0.0300062)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(583.213 -99.4402) scale(0.0667971 0.0325014)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(549.412 -99.6717) scale(0.0626257 0.0325014)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(580.871 -104.041) scale(0.0665924 0.0337912)"
        >
          <stop stopColor="#E9E9E9" />
          <stop offset="0.02" stopColor="#E2E1E0" />
          <stop offset="0.1" stopColor="#CCC7C2" />
          <stop offset="0.18" stopColor="#BBB3AB" />
          <stop offset="0.27" stopColor="#AFA49B" />
          <stop offset="0.36" stopColor="#A79C91" />
          <stop offset="0.49" stopColor="#A5998E" />
          <stop offset="0.67" stopColor="#A3978B" />
          <stop offset="0.82" stopColor="#9C8F81" />
          <stop offset="0.94" stopColor="#928271" />
          <stop offset="1" stopColor="#8B7A67" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(45.9704 3.56062) scale(0.201789 0.141132)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(546.432 -97.4833) scale(0.0605363 0.0307983)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(562.649 -101.59) scale(0.0625561 0.0320213)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(583.617 -104.009) scale(0.0671796 0.033791)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(546.716 -101.335) scale(0.0626271 0.0330362)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint41_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(561.122 -102.7) scale(0.0644597 0.0335278)"
        >
          <stop stopColor="#F8F8F8" />
          <stop offset="0.02" stopColor="#F2F1F0" />
          <stop offset="0.1" stopColor="#DBD7D2" />
          <stop offset="0.18" stopColor="#CAC3BB" />
          <stop offset="0.26" stopColor="#BEB4AB" />
          <stop offset="0.36" stopColor="#B6ACA1" />
          <stop offset="0.49" stopColor="#B4A99E" />
          <stop offset="0.68" stopColor="#B2A69B" />
          <stop offset="0.83" stopColor="#AB9E91" />
          <stop offset="0.96" stopColor="#9F9181" />
          <stop offset="1" stopColor="#9A8B7A" />
        </radialGradient>
        <radialGradient
          id="paint42_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(44.3051 4.33709) scale(0.207935 0.145543)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(561.646 -105.429) scale(0.0645095 0.0345795)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(562.047 -100.042) scale(0.0625561 0.0320215)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_71_35067"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(545.456 -99.8872) scale(0.0604867 0.0320211)"
        >
          <stop stopColor="#FCFCFC" />
          <stop offset="0.02" stopColor="#F6F6F5" />
          <stop offset="0.09" stopColor="#E0DBD7" />
          <stop offset="0.18" stopColor="#CEC7C0" />
          <stop offset="0.26" stopColor="#C2B8B0" />
          <stop offset="0.36" stopColor="#BAB0A6" />
          <stop offset="0.49" stopColor="#B8ADA3" />
          <stop offset="0.68" stopColor="#B6ABA0" />
          <stop offset="0.82" stopColor="#AFA396" />
          <stop offset="0.95" stopColor="#A49586" />
          <stop offset="1" stopColor="#9E8F7E" />
        </radialGradient>
        <linearGradient
          id="paint46_linear_71_35067"
          x1="143.288"
          y1="27.7546"
          x2="148.622"
          y2="24.5195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#333333" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FleetaPromoSuccessIcon;
