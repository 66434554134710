import { API_GATEWAY_URI, STRIPE_OS_TYPE } from "@thingsw/pitta-modules";
import axios from "axios";

export const getSubscription = (
  email: string,
  userToken: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetSubscrInfo?email=${email}&user_token=${userToken}&tokenType=${tokenType}`
  );
};

export const getWebSubscription = (
  email: string,
  user_token: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetInWebSubscrCustomer?email=${email}&user_token=${user_token}&tokenType=${tokenType}&ostype=${STRIPE_OS_TYPE}`
  );
};

export const getTransactionHistory = (
  email: string,
  user_token: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetInWebSubscrTransactions?email=${email}&user_token=${user_token}&tokenType=${tokenType}&ostype=${STRIPE_OS_TYPE}`
  );
};

export const getFreetrialState = (
  email: string,
  user_token: string,
  tokeType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetCheckSubscrTrial?email=${email}&user_token=${user_token}&tokenType=${tokeType}`
  );
};

export const requestCancelSubscription = (
  email: string,
  user_token: string,
  ostype: string,
  tokenType: string,
  reason?: string
) => {
  return axios.post(`${API_GATEWAY_URI}/Payment/WebSubscribeCancelUpdate`, {
    email,
    user_token,
    ostype,
    reason,
    tokenType,
  });
};

// export const doWebSubscribe = (
//   email: string,
//   user_token: string,
//   body: Payment
// ) => {
//   return axios.post(`${API_GATEWAY_URI}//Payment/WebSubscribe`, {
//     email,
//     user_token,
//     ...body,
//     osType: STRIPE_OS_TYPE,
//   });
// };
