import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alerts, Container, Typography } from "@thingsw/pitta-design-system";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { MinimalFooter } from "../components/MinimalFooter";
import { LoginHeader } from "../components/LoginHeader";

import {
  setJwtInterceptorId,
  setLoginInfo,
  USER,
} from "../features/User/slice";
import { RootState } from "../features/store";
import {
  CreateTokenCardData,
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeElementLocale,
} from "@stripe/stripe-js";
import BillingForm from "../forms/BillingForm";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ERROR, setError } from "../features/Error/slice";
import * as yup from "yup";
import { SubmissionError } from "redux-form";
import _ from "lodash";
import { PAYMENT } from "../features/Payment/slice";
import {
  Webviewer,
  STRIPE_API_KEY,
  API_GATEWAY_URI,
  STRIPE_OS_TYPE,
  RESULT_CODE,
  cardLanguages,
  jwtAxiosInst,
  createOnFulfilled,
  LightColors,
  FLEETA_PROMO_CODE,
} from "@thingsw/pitta-modules";

const schema = yup.object().shape({
  name: yup.string().trim().required("Enter card name"),
  address_line1: yup.string().trim().required("Enter address"),
  address_city: yup.string().trim().required("Enter the city"),
  address_country: yup
    .object()
    .shape({
      key: yup.string(),
      value: yup.string(),
    })
    .required("Select country")
    .test(
      "notSelect",
      "Select country",
      (item: { key?: string; value?: string }) => {
        return item.key && item.key !== "country";
      }
    ),
  fleetaPromoAgree: yup.boolean().when("$fleetaPromo", {
    is: true,
    then: yup
      .boolean()
      .required("You must check agreement to proceed")
      .oneOf([true], "You must check agreement to proceed"),
    otherwise: yup.boolean(),
  }),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    marginTop: 58,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginTop: 58,
    },
    minHeight: "calc(100vh - 58px)",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 0,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      paddingTop: 58,
    },
    flex: 1,
  },
  billingDiv: {
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.up(Webviewer.mobile)]: {
      width: "auto",
      padding: 0,
      marginTop: theme.spacing(7),
    },
  },
  title: {
    display: "flex",
    marginBottom: 16,
    [theme.breakpoints.up(Webviewer.mobile)]: {
      marginBottom: 0,
    },
  },
  formDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up(Webviewer.mobile)]: {
      flexDirection: "row",
    },
  },
  marginB5: {
    marginBottom: theme.spacing(5),
  },
  errorDiv: {
    width: "100%",
  },
  marginR25: {
    marginRight: theme.spacing(3) + 1,
  },
  marginR4: {
    marginRight: theme.spacing(4),
  },
  headerTextDiv: {
    display: "flex",
    padding: theme.spacing(1, 1, 0, 6.375),
    paddingLeft: 51,
  },
}));

export const BillingScreen = () => {
  interface IState {
    mode?: string;
    fleetaPromo?: boolean;
    cameraCnt?: number;
  }

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{ origin?: string }>();
  const { origin } = location.state ?? {};

  const mobile = useMediaQuery(theme.breakpoints.down(Webviewer.mobile));
  const { path } = useRouteMatch();
  const { email, loginInfo, jwtInterceptorId, tokenType } = useSelector(
    (state: RootState) => state[USER]
  );
  const { error } = useSelector((state: RootState) => state[ERROR]);
  const { freetrial } = useSelector((state: RootState) => state[PAYMENT]);

  const mode = (location.state as IState)?.mode;
  const fleetaPromo = (location.state as IState)?.fleetaPromo;

  //billInfo
  const stripePromise = loadStripe(STRIPE_API_KEY);
  const [stripe, setStripe] = useState<Stripe>();
  const [cardElement, setCardElement] = useState<StripeCardElement>();
  const [cameraNum, setCameraNum] = useState<string>("1");
  const [referralCode, setReferralCode] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { i18n: i18next } = useTranslation();

  useEffect(() => {
    const body = document.getElementById("body-container");
    body?.setAttribute("style", "");
  });

  const lang = useMemo(() => {
    // let prefLang = localStorage.getItem("pitta-webviewer-pref-lang");
    // prefLang = prefLang || navigator.language.toLowerCase().substring(0, 2);
    // mantis - 8444, 이전에 저장되어있던 언어X, 랜딩페이지 언어로 적용
    // dev/stage/production에서도 내부 언어는 한글 지원하므로 수정
    return i18next.language;
  }, [i18next.language]);

  const handleError = useCallback(
    (error: string) => {
      dispatch(setError(error));
    },
    [dispatch]
  );

  const handleSubmit = async (
    data: CreateTokenCardData & { fleetaPromoAgree?: boolean }
  ) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
        context: { fleetaPromo },
      });
      if (!stripe || !cardElement) {
        return;
      }

      //@ts-ignore
      if (!cardElement["_complete"]) {
        throw new SubmissionError({
          cardNumber: "incomplete_number",
        });
      }

      if (cardElement && cameraNum) {
        setLoading(true);
        console.log(data);
        const { token, error } = await stripe.createToken(cardElement, {
          name: data.name,
          //country
          address_line1: data.address_line1,
          address_city: data.address_city,
          address_state: data.address_state,
          address_zip: data.address_zip,
          //@ts-ignore
          address_country: data.address_country.value,
        });
        if (error) {
          console.log("[error]", error);
          error.message && handleError(error.message);
        } else if (token) {
          // setToken(token.id);
          console.log("[Token]", token);
          const itemID = `fleet${cameraNum}`;
          const resp = await axios.post(
            `${API_GATEWAY_URI}/Payment/WebSubscribe`,
            {
              email,
              user_token: loginInfo?.user_token,
              step: "payload",
              itemID,
              referralCode: referralCode || "PITTA",
              cameraCount: cameraNum,
              stripeToken: token.id,
              osType: STRIPE_OS_TYPE,
              tokenType,
              couponCode: fleetaPromo ? FLEETA_PROMO_CODE : undefined,
            }
          );
          console.log("[data]", resp.data);
          const data = resp.data as {
            resultcode: RESULT_CODE;
            message: string;
            response: {
              payload: string;
            };
          };
          if (data.resultcode === "BC_ERR_OK") {
            const respVerify = await axios.post(
              `${API_GATEWAY_URI}/Payment/WebSubscribe`,
              {
                email,
                user_token: loginInfo?.user_token,
                osType: STRIPE_OS_TYPE,
                step: "verify",
                payload: data.response.payload,
                stripeToken: token.id,
                cameraCount: parseInt(cameraNum),
                tokenType,
                couponCode: fleetaPromo ? FLEETA_PROMO_CODE : undefined,
              }
            );
            const data2 = respVerify.data as {
              resultcode: RESULT_CODE;
              message: string;
              response: {
                email: string;
                startOfMonthDT: string;
                endOfMonthDT: string;
              };
            };
            if (data2.resultcode === "BC_ERR_OK") {
              console.log("BillingScreen", "fleetaPromo", fleetaPromo);
              const authToken = await axios.get(
                `${API_GATEWAY_URI}/BCS/jwtNewKey?email=${email}&user_token=${loginInfo?.user_token}&tokenType=${tokenType}`
              );
              const authTokenData = authToken.data as {
                resultcode: RESULT_CODE;
                message: string;
                response: {
                  jwtNewKey: string;
                };
              };
              if (jwtInterceptorId !== undefined) {
                jwtAxiosInst.interceptors.request.eject(jwtInterceptorId);
              }

              const interceptorId = jwtAxiosInst.interceptors.request.use(
                createOnFulfilled(authTokenData.response.jwtNewKey),
                undefined
              );
              dispatch(setJwtInterceptorId(interceptorId));

              dispatch(
                setLoginInfo({
                  email: email as string,
                  user_token: loginInfo?.user_token as string,
                  token_type: tokenType,
                  jsonwebtoken: authTokenData.response.jwtNewKey,
                })
              );

              if (fleetaPromo) {
                sessionStorage.removeItem("webviewer-promotion-dontshow");
                history.replace("/cameras", { fleetaPromo });
              } else if (freetrial?.trialUsed) {
                history.replace("/subscription-success", { cnt: cameraNum });
              } else if (origin) {
                history.replace(origin);
              } else {
                sessionStorage.removeItem("webviewer-promotion-dontshow");
                history.replace("/cameras", { fleetaPromo });
              }
            } else if (data2.resultcode === "BC_ERR_CARD_DECLINED") {
              history.push("/subscription-fail");
            } else {
              handleError(data2.message);
            }
          } else {
            handleError(data.message);
          }
        }
        setLoading(false);
      }
    } catch (err: any) {
      let submssionError = _.reduce(
        err.inner,
        (result, error) => {
          return { ...result, [error.path]: error.errors };
        },
        {}
      );

      //@ts-ignore
      if (!cardElement["_complete"]) {
        submssionError = {
          ...submssionError,
          cardNumber: "incomplete_number",
        };
      }

      throw new SubmissionError({
        ...submssionError,
      });
    }
  };

  return (
    <React.Fragment>
      <LoginHeader />
      <div className={classes.root}>
        {mobile && mode === "fleet" && (
          <div className={classes.headerTextDiv}>
            <Typography
              category="Default"
              variant={path === "/signup" ? "CaptionBold" : "Caption"}
              htmlColor={LightColors.primary["1"]}
              className={classes.marginR25}
            >
              1. {t("Sign up")}
            </Typography>
            <Typography
              category="Default"
              variant={path === "/billing" ? "CaptionBold" : "Caption"}
              htmlColor={LightColors.primary["1"]}
            >
              2. {t("Billing information")}
            </Typography>
          </div>
        )}
        {error && (
          <div className={classes.errorDiv}>
            <Alerts mode="web" severity="error">
              {t(error)}
            </Alerts>
          </div>
        )}
        <Container className={classes.body}>
          <div className={classes.billingDiv}>
            <div className={classes.title}>
              <Typography
                category="Default"
                variant={mobile ? "H2" : "H1"}
                className={mobile ? "" : classes.marginB5}
              >
                {t("Billing information")}
              </Typography>
            </div>
            <div className={classes.formDiv}>
              <Elements
                stripe={stripePromise}
                options={{
                  locale: cardLanguages[lang] as StripeElementLocale,
                }}
              >
                <BillingForm
                  onSubmit={handleSubmit}
                  loading={loading}
                  onUpdateCardElement={(
                    stripe,
                    cardElement,
                    cameraNum,
                    referralCode
                  ) => {
                    setStripe(stripe);
                    setCardElement(cardElement);
                    setCameraNum(cameraNum);
                    setReferralCode(referralCode);
                  }}
                  fleetaPromo={fleetaPromo}
                />
              </Elements>
            </div>
          </div>
        </Container>
        <MinimalFooter />
      </div>
    </React.Fragment>
  );
};
