import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/auth";

import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { theme } from "@thingsw/pitta-design-system";
import { FirebaseAuthProvider } from "@react-firebase/auth";

import { LandingScreen } from "../screens/LandingScreen";
import { PricingScreen } from "../screens/PricingScreen";
import { LoginScreen } from "../screens/LoginScreen";
import { DownloadsScreen } from "../screens/DownloadsScreen";
import { CompatibleDashcamsScreen } from "../screens/CompatibleDashcamsScreen";
import { PasswordResetScreen } from "../screens/PasswordResetScreen";
import { PasswordChangeScreen } from "../screens/PasswordChangeScreen";
import { SignupScreen } from "../screens/SignupScreen";
import AuthorizedRoute from "../components/AuthorizedRoute";
import { history, RootState } from "../features/store";
import { DeleteAccountConfirmScreen } from "../screens/DeleteAccountConfirmScreen";
import { DeleteAccountCheckScreen } from "../screens/DeleteAccountCheckScreen";
import { connect } from "react-redux";
import { changeColor, THEME } from "../features/Theme/slice";
import { VerifyAccountScreen } from "../screens/VerifyAccountScreen";
import { BillingScreen } from "../screens/BillingScreen";
import { DeleteAccountScreen } from "../screens/DeleteAccountScreen";
import { SocialSigninScreen } from "../screens/SocialSigninScreen";
import _ from "lodash";
import { E500Screen } from "../screens/E500Screen";
import { E404Screen } from "../screens/E404Screen";
import axios from "axios";
import { setError } from "../features/Error/slice";
import DefaultRoute from "../components/DefaultRoute";
import WebviewerRouter from "../routers/WebviewerRouter";
import AppRouter from "../routers/AppRouter";
import {
  logout,
  setJwtInterceptorId,
  setLoginInfo,
  USER,
  setEventmapJwtInterceptorId,
  setEventmapInterceptorId,
} from "../features/User/slice";
import { MaintenanceRouter } from "../routers/MaintenanceRouter";
import { CameraSimAddScreen } from "../screens/CameraSimAddScreen";
import { CameraChooseConnectScreen } from "../screens/CameraChooseConnectScreen";
import { ConnectToCloudScreen } from "../screens/ConnectToCloudScreen";
import { SubscriptionSuccessScreen } from "../screens/SubscriptionSuccessScreen";
import { SubscriptionFailScreen } from "../screens/SubscriptionFailScreen";
import { DeleteLinkExpiredScreen } from "../screens/DeleteLinkExpiredScreen";
import { detect } from "detect-browser";
import AppWitoutBottomNavRouter from "../routers/AppWitoutBottomNavRouter";
import { finishLiveview } from "../features/LiveView/slice";
import { eventmapAxiosInst, eventmapJwtAxiosInst } from "../utils";
import { FONT_FAMILY } from "html2canvas/dist/types/css/property-descriptors/font-family";
import { generateAuthToken } from "../utils/Auth";
import {
  AppBackReason,
  Color,
  config,
  createOnFulfilled,
  DarkColors,
  IUserLoginInfo,
  jwtAxiosInst,
  LightColors,
  Webviewer,
} from "@thingsw/pitta-modules";
import { RenewalMapviewScreen } from "../screens/app/renewal2/RenewalMapviewScreen";
import { DashboardMapviewScreen } from "../screens/DashboardMapviewScreen";

const IGNORE_REQUESTS = [
  "/proc/vod_file",
  "/BCS/queue_list",
  "/BCS/gps_zone.php",
  "/DashCam/GetDeviceList",
  "https://fota.blackvuecloud.com",
  "/BCS/vodPlayReq.php",
  "/Account/UserUsageInfo",
  "/Account/PostUserInfo",
  "/fw_folder/firmware_info.json",
  "/BCS/userS3PresignedUrl.php",
  "/BCS/evtLrGetList.php",
];

const generateClassName = createGenerateClassName({
  productionPrefix: "webviewer-css-", // 원하는 프리픽스를 설정
});

type AppState = {
  jwtInterceptorId?: number;
};
type AppProps = {
  direction: "rtl" | "ltr";
  fontFamily: FONT_FAMILY;
  loginInfo?: IUserLoginInfo;
  email?: string;
  tokenType: string;
  colors: Color;
  color: "light" | "dark";
  finishLiveview: (payload: AppBackReason | undefined) => void;
  setError: (payload: string) => void;
  setJwtInterceptorId: (payload: number) => void;
  changeColor: (payload: "dark" | "light") => void;
  logout: VoidFunction;
  setLoginInfo: (payload: {
    email: string;
    user_token: string;
    token_type: "web" | "app";
    gps_server?: string;
    gps_port?: string;
    jsonwebtoken?: string;
  }) => void;
  setEventmapJwtInterceptorId: (payload: number) => void;
  setEventmapInterceptorId: (payload: number) => void;
};

let colorInitWithParam = true;

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    console.log("App", "constructor", props, history.location.search);
    const {
      email,
      tokenType,
      loginInfo,
      finishLiveview,
      setError,
      setJwtInterceptorId,
      setLoginInfo,
      logout,
      setEventmapJwtInterceptorId,
      setEventmapInterceptorId,
    } = props;
    const query = new URLSearchParams(history.location.search);
    console.log(
      "App",
      "querystring",
      query.has("email"),
      query.has("user_token"),
      query.has("token_type"),
      query.has("jwt_token")
    );
    console.log("App", "setLoginInfo", {
      //@ts-ignore
      email: query.get("email"),
      //@ts-ignore
      user_token: query.get("user_token"),
      //@ts-ignore
      token_type: query.get("token_type"),
      //@ts-ignore
      gps_server: query.get("gps_server"),
      //@ts-ignore
      gps_port: query.get("gps_port"),
      //@ts-ignore
      jsonwebtoken: query.get("jwt_token"),
    });
    if (
      query.has("email") &&
      query.has("user_token") &&
      query.has("token_type") &&
      query.has("jwt_token")
    ) {
      setLoginInfo({
        //@ts-ignore
        email: query.get("email"),
        //@ts-ignore
        user_token: query.get("user_token"),
        //@ts-ignore
        token_type: query.get("token_type"),
        //@ts-ignore
        gps_server: query.get("gps_server"),
        //@ts-ignore
        gps_port: query.get("gps_port"),
        //@ts-ignore
        jsonwebtoken: query.get("jwt_token"),
      });
    }
    const browserName = detect()?.name;
    axios.defaults.timeout = 30 * 1000;

    if (loginInfo?.jsonwebtoken || query.has("jwt_token")) {
      const interceptorId = jwtAxiosInst.interceptors.request.use(
        createOnFulfilled(query.get("jwt_token") ?? loginInfo?.jsonwebtoken),
        undefined
      );
      setJwtInterceptorId(interceptorId);
      jwtAxiosInst.interceptors.response.use(undefined, (error: any) => {
        const axiosConfig = error.config;
        console.log(
          "CloudNative",
          "jwtAxiosInst",
          "onReject",
          error.code,
          error.message,
          error.response?.status
        );

        if (
          (browserName === "ios-webview" ||
            browserName === "chromium-webview") &&
          (error.code === "ECONNABORTED" ||
            error.message === "Network Error" ||
            error.response?.status === 408)
        ) {
          finishLiveview("ERR_NO_INTERNET");
        }
        if (axiosConfig) {
          const len = _.chain(IGNORE_REQUESTS)
            .map((req) => axiosConfig.url.indexOf(req) > -1)
            .compact()
            .value().length;
          if (len > 0) {
            return Promise.reject(error);
          }
        }
        if (
          error.code === "ECONNABORTED" ||
          error.message === "Network Error" ||
          error.response?.status === 408
        ) {
          setError("An error occurred_again");
        }
        if (error.response?.status === 401) {
          logout();
        }
        if (error.response?.status === 403) {
          logout();
        }
        return Promise.reject(error);
      });

      const eventmapJwtInterceptorId = eventmapJwtAxiosInst.interceptors.request.use(
        createOnFulfilled(query.get("jwt_token") ?? loginInfo?.jsonwebtoken),
        undefined
      );
      eventmapJwtAxiosInst.interceptors.response.use(
        undefined,
        (error: any) => {
          if (error.response?.status === 401) {
            return logout();
          }
          if (error.response?.status === 403) {
            return logout();
          }
          return Promise.reject(error);
        }
      );
      setEventmapJwtInterceptorId(eventmapJwtInterceptorId);

      let authToken = "";

      if (query.get("email") && query.get("user_token")) {
        authToken = generateAuthToken(
          //@ts-ignore
          query.get("email"),
          //@ts-ignore
          query.get("user_token")
        );
      } else if (email && loginInfo?.user_token) {
        authToken = generateAuthToken(email, loginInfo.user_token);
      }

      const eventmapInterceptorId = eventmapAxiosInst.interceptors.request.use(
        createOnFulfilled(authToken, query.get("token_type") ?? tokenType),
        undefined
      );
      eventmapAxiosInst.interceptors.response.use(undefined, (error: any) => {
        if (error.response?.status === 401) {
          logout();
        }
        if (error.response?.status === 403) {
          logout();
        }
      });
      setEventmapInterceptorId(eventmapInterceptorId);
    }

    history.listen((location, action) => {
      console.log("App", "location", location, "action", action);

      if (!_.includes(["#cloud", "#fleet"], location.hash)) {
        window.scrollTo(0, 0);
      }
      this.checkDarkMode();
    });

    const mediaQueryList = window.matchMedia(
      `(max-width:${Webviewer.mobile}px)`
    );
    if (typeof mediaQueryList.addListener === "function") {
      mediaQueryList.addListener((query) => {
        this.checkDarkMode();
      });
    } else if (typeof mediaQueryList.addEventListener === "function") {
      mediaQueryList.addEventListener("change", (query) => {
        this.checkDarkMode();
      });
    }

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        console.log("App", "prefers-color-scheme is changed", this.state);
        if (colorInitWithParam) {
          const newColorScheme = event.matches ? "dark" : "light";
          console.log("App", "newColorScheme", newColorScheme);
          this.props.changeColor(newColorScheme);
        }
      });

    this.checkDarkMode();

    colorInitWithParam = true;
  }

  checkDarkMode() {
    const { search } = history.location;
    const query = new URLSearchParams(search);
    const mobile = window.matchMedia(`(max-width:${Webviewer.mobile}px)`)
      .matches;
    if (history.location.pathname.indexOf("/app/live-view") !== -1) {
      if (
        query.has("dark") &&
        query.get("dark") === "true" &&
        this.props.color !== "dark"
      ) {
        return this.props.changeColor("dark");
      }
    }
    if (mobile) {
      if (history.location.pathname.indexOf("/app") === -1) {
        return this.props.changeColor("light");
      }
      if (query.has("dark")) {
        if (query.get("dark") === "true" && this.props.color !== "dark") {
          return this.props.changeColor("dark");
        }
        if (query.get("dark") === "false" && this.props.color === "dark") {
          return this.props.changeColor("light");
        }
      } else {
        const match = window.matchMedia("(prefers-color-scheme: dark)").matches;
        const color = match ? "dark" : "light";
        console.log("App", "Color schema:", color);
        if (this.props.color !== color) {
          return this.props.changeColor(color);
        }
      }
    }
    if (this.props.color !== "light") {
      this.props.changeColor("light");
    }
  }

  render() {
    const { fontFamily, direction, loginInfo, color, colors } = this.props;
    console.log("App", "render", colors);
    return (
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider
          theme={theme({
            fontFamily,
            dir: direction,
            Colors: color === "dark" ? DarkColors : LightColors,
            breakpoints: {
              values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
            },
          })}
        >
          <FirebaseAuthProvider {...config} firebase={firebase}>
            <CssBaseline />
            <ConnectedRouter history={history}>
              <Switch>
                <DefaultRoute path="/" exact component={LandingScreen} />
                <DefaultRoute path="/Err500" component={E500Screen} />
                <DefaultRoute path="/pricing" component={PricingScreen} />
                <DefaultRoute path="/downloads" component={DownloadsScreen} />
                <DefaultRoute
                  path="/compatible-dashcams"
                  component={CompatibleDashcamsScreen}
                />
                <DefaultRoute path="/login" component={LoginScreen} />
                <DefaultRoute path="/reset" component={PasswordResetScreen} />
                <DefaultRoute path="/change" component={PasswordChangeScreen} />
                <DefaultRoute
                  path="/socialSignin"
                  component={SocialSigninScreen}
                />
                <DefaultRoute path="/signup" component={SignupScreen} />
                <DefaultRoute
                  path="/verify-account"
                  component={VerifyAccountScreen}
                />
                <DefaultRoute path="/billing" component={BillingScreen} />
                <DefaultRoute
                  path="/subscription-success"
                  component={SubscriptionSuccessScreen}
                />
                <DefaultRoute
                  path="/subscription-fail"
                  component={SubscriptionFailScreen}
                />
                <DefaultRoute
                  path="/delete-link-expired"
                  component={DeleteLinkExpiredScreen}
                />

                <DefaultRoute path="/app/maintenance">
                  <MaintenanceRouter />
                </DefaultRoute>

                <AuthorizedRoute
                  path="/cameras/add-camera-sim"
                  component={CameraSimAddScreen}
                />
                <AuthorizedRoute
                  path="/cameras/choose-connection"
                  component={CameraChooseConnectScreen}
                />
                <AuthorizedRoute
                  path="/cameras/connect-to-cloud"
                  component={ConnectToCloudScreen}
                />
                <AuthorizedRoute
                  exact
                  path={`/account/delete`}
                  component={DeleteAccountScreen}
                />

                <DefaultRoute
                  path="/delete-check"
                  component={DeleteAccountCheckScreen}
                />
                <DefaultRoute
                  path="/delete-confirm"
                  component={DeleteAccountConfirmScreen}
                />

                <DefaultRoute path="/app/live-view">
                  <AppRouter />
                </DefaultRoute>
                <Route path="/app/renewal-map-view">
                  <RenewalMapviewScreen />
                </Route>
                <Route path="/app">
                  <AppWitoutBottomNavRouter />
                </Route>
                <DefaultRoute path="/dashboard/map-view">
                  <DashboardMapviewScreen />
                </DefaultRoute>

                {loginInfo && <WebviewerRouter />}
                {loginInfo && <DefaultRoute component={E404Screen} />}
                {!loginInfo && (
                  <DefaultRoute>
                    <Redirect to="/" />
                  </DefaultRoute>
                )}
              </Switch>
            </ConnectedRouter>
          </FirebaseAuthProvider>
        </ThemeProvider>
      </StylesProvider>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  finishLiveview: (payload: AppBackReason | undefined) =>
    dispatch(finishLiveview(payload)),
  setError: (payload: string) => dispatch(setError(payload)),
  setJwtInterceptorId: (payload: number) =>
    dispatch(setJwtInterceptorId(payload)),
  changeColor: (payload: "dark" | "light") => dispatch(changeColor(payload)),
  logout: () => dispatch(logout(true)),
  setLoginInfo: (payload: {
    email: string;
    user_token: string;
    token_type: "web" | "app";
    gps_server?: string;
    gps_port?: string;
    jsonwebtoken?: string;
  }) => dispatch(setLoginInfo(payload)),
  setEventmapJwtInterceptorId: (payload: number) =>
    dispatch(setEventmapJwtInterceptorId(payload)),
  setEventmapInterceptorId: (payload: number) =>
    dispatch(setEventmapInterceptorId(payload)),
});

const mapStateToProps = (state: RootState) => ({
  fontFamily: state[THEME].fontFamily,
  direction: state[THEME].direction,
  loginInfo: state[USER].loginInfo,
  email: state[USER].email,
  tokenType: state[USER].tokenType,
  colors: state[THEME].colors,
  color: state[THEME].color,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
