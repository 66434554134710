import axios from "axios";
import crypto from "crypto";
import qs from "qs";
import {
  jwtAxiosInst,
  ILogin,
  ISignup,
  IUserConfirm,
  IUploadImage,
  IChangeName,
  ISetNotification,
  API_GATEWAY_URI,
  IPermissions,
  FLEETA_PROMO_CODE,
} from "@thingsw/pitta-modules";

export const logout = (email: string, userToken: string) => {
  return jwtAxiosInst.get(
    `/BCS/userLogout.php?email=${email}&user_token=${userToken}&tokenType=web`
  );
};

export const userLogin = (body: ILogin) => {
  const data = qs.stringify(body);

  return jwtAxiosInst.post(`/BCS/userLogin.php`, data, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const getProfile = (
  email: string,
  userToken: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/GetProfile?email=${email}&userToken=${userToken}&tokenType=${
      tokenType ?? "web"
    }`
  );
};

export const getMailAddress = (email: string, lang: string) => {
  return jwtAxiosInst.get(
    `/BCS/initPasswd.php?email=${email}&mobile_lang=${lang}`
  );
};

export const createUser = (user: ISignup) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserAdd`, user);
};

export const userConfirm = (user: IUserConfirm) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserConfirm`, user);
};

export const uploadImage = (
  email: string,
  userToken: string,
  tokenType: string,
  payload: IUploadImage
) => {
  const params = new FormData();
  params.append("email", email);
  params.append("userToken", userToken);
  params.append("tokenType", tokenType);
  params.append("file", payload.file);
  params.append(
    "fileName",
    crypto.createHash("md5").update(payload.fileName).digest("hex")
  );
  return axios.post(`${API_GATEWAY_URI}/Account/UploadProfile`, params, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

export const changeName = (
  email: string,
  user_token: string,
  body: IChangeName
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/ChangeUserName`, {
    ...body,
    email,
    user_token,
    tokenType: "web",
  });
};

export const changePasswd = (
  email: string,
  old_passwd: string,
  new_passwd: string
) => {
  const data = qs.stringify({
    email,
    old_passwd,
    new_passwd,
    tokenType: "web",
  });
  return jwtAxiosInst.post(`/BCS/userChangePasswd.php`, data, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
    },
  });
};

// export const deleteAccountMail = (
//   email: string,
//   user_token: string,
//   passwd: string,
//   tokenType: string
// ) => {
//   const data = qs.stringify({ email, user_token, passwd, tokenType });

//   return jwtAxiosInst.post(`/BCS/accountDelete.php`, data, {
//     headers: {
//       "Content-type": "application/x-www-form-urlencoded",
//     },
//   });
// };

export const userUsage = (
  email: string,
  userToken: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserUsageInfo?email=${email}&user_token=${userToken}&tokenType=${tokenType}`
  );
};

export const getUserSetting = (
  email: string,
  userToken: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/GetUserSettings?email=${email}&user_token=${userToken}&tokenType=${tokenType}`
  );
};

export const updateUserSetting = (
  email: string,
  userToken: string,
  mapType: string,
  velocityUnit: string
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/PostUserSettings`, {
    email,
    user_token: userToken,
    tokenType: "web",
    mapType,
    velocityUnit,
  });
};

export const setUserNotification = (
  email: string,
  userToken: string,
  mobile_uuid: string,
  body: ISetNotification
) => {
  return jwtAxiosInst.get(
    `/BCS/alarmDetailSet.php?email=${email}&user_token=${userToken}&mobile_uuid=${mobile_uuid}&device_alarm=${body.device_alarm}&notice_alarm=${body.notice_alarm}&system_alarm=${body.system_alarm}&tokenType=web`
  );
};

export const getUserNotification = (
  email: string,
  userToken: string,
  mobile_uuid: string
) => {
  return jwtAxiosInst.get(
    `/BCS/alarmDetailGet.php?email=${email}&user_token=${userToken}&mobile_uuid=${mobile_uuid}&tokenType=web`
  );
};

export const getUserPermissions = (
  email: string,
  userToken: string,
  targetEmail: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserRoleTargetInfo?email=${email}&user_token=${userToken}&tokenType=web&targetEmail=${targetEmail}`
  );
};

export const updateUserPermissions = (
  email: string,
  user_token: string,
  roleSetEmail: string,
  permissions: IPermissions
) => {
  return axios.post(`${API_GATEWAY_URI}/Account/UserRoleSet`, {
    email,
    user_token,
    roleSetEmail,
    permissions,
    tokenType: "web",
  });
};

export const linkSocialAccount = (
  email: string,
  passwd: string,
  oauthToken: string,
  signupType: string
) => {
  return axios.put(`${API_GATEWAY_URI}/Account/PutUserLinkage`, {
    email,
    passwd,
    oauthToken,
    signupType,
  });
};

export const doDeleteUser = (email: string, user_token: string) => {
  return axios.post(`${API_GATEWAY_URI}/Account/PostUserDeleteWithEmail`, {
    email,
    user_token,
    tokenType: "web",
  });
};

export const loadUserPermissions = (
  email: string,
  user_token: string,
  tokenType: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/UserRole?email=${email}&user_token=${user_token}&tokenType=${tokenType}`
  );
};

// export const getAccountSubscription = (email: string, userToken: string) => {
//   return axios.get(
//     `${API_GATEWAY_URI}/Payment/GetSubscrInfo?email=${email}&user_token=${userToken}&tokenType=web`
//   );
// };

export const getPromoList = () => {
  return axios.get(
    `${API_GATEWAY_URI}/subscribe/promotion/code/FleetPromotion`
  );
};

export const checkFleetaPromo = (email: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/subscribe/promotion/check?code=FleetPromotion&email=${email}`
  );
};

export const checkPromoDontShow = (
  email: string,
  user_token: string,
  tokenType: string
) => {
  return jwtAxiosInst.get(
    `/subscribe/promotion/dontshow?email=${email}&user_token=${user_token}&tokenType=${tokenType}`
  );
};

export const updatePromoDontShow = (
  email: string,
  user_token: string,
  tokenType: string
) => {
  return jwtAxiosInst.post(
    `/subscribe/promotion/dontshow?user_token=${user_token}&tokenType=${tokenType}`,
    {
      email,
      dontshow: 1,
      promotionCode: FLEETA_PROMO_CODE,
    }
  );
};

export const checkPromoApplied = (
  email: string,
  user_token: string,
  tokenType: string
) => {
  return jwtAxiosInst.get(
    `/subscribe/promotion/history?email=${email}&user_token=${user_token}&tokenType=${tokenType}`
  );
};
